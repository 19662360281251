import styled from 'styled-components';
import { ActionButton } from '../../components/ActionButton';

export const StyledSection = styled.section`
  display: block;
  max-width: 720px;
  margin: 40px auto;
  padding: 0 16px 32px;
`;

export const StyledList = styled.ul`
  display: grid;
  gap: 16px;
`;

export const StyledListItem = styled.li<{ isOpen: boolean }>`
  list-style: none;
  padding: 24px;
  border: 1px solid;
  border-radius: 16px;
  cursor: pointer;

  strong {
    position: relative;
    display: block;
    font-size: 18px;
    color: var(--color-primary-dark);
    line-height: 1.3;
    padding-right: 40px;

    svg {
      position: absolute;
      top: 50%;
      right: 0;
      width: 18px;
      height: 18px;
      transition: transform 0.3s;

      ${({ isOpen }) => `
        transform: translateY(-50%) rotate(${isOpen ? '180deg' : '0deg'});
      `}
    }
  }

  p {
    line-height: 1.5;
    margin-top: 16px;
  }

  ${({ isOpen }) => `
    border-color: ${
      isOpen ? 'var(--color-primary)' : 'var(--color-primary-dark)'
    };

    strong {
      color: ${isOpen ? 'var(--color-primary)' : 'var(--color-primary-dark)'};
    }

    p {
      display: ${isOpen ? 'block' : 'none'};
    }
  `}
`;

export const StyledActionButton = styled(ActionButton)`
  display: flex;
  margin: 56px auto 0;
`;
