import React from 'react';
import {
  StyledCard,
  StyledCardContent,
  StyledCardFigure,
  StyledCardStep,
  StyledCardText,
  StyledCardTitle,
} from './styles';

const Card = ({ children }: React.PropsWithChildren): React.ReactElement => {
  return <StyledCard>{children}</StyledCard>;
};

Card.Figure = StyledCardFigure;
Card.Content = StyledCardContent;
Card.Step = StyledCardStep;
Card.Title = StyledCardTitle;
Card.Text = StyledCardText;

export default Card;
