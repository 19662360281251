import * as React from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import SubmitButton from '../../components/Button';
import SimpleInput from './Input';

import {
  Div,
  Div2,
  Div3,
  Div4,
  Div5,
  Div6,
  Div7,
  Div9,
  BemVindo,
  MilhasConta,
  FormLoginStyled,
  PlaneContainer,
} from './styles';
import Plane from '../../images/Plane';
import { singin } from '../../api/authenticate';
import useAuthenticate from '../../recoil/hooks/authenticate';
import { cpfMask, removeCpfMask } from '../../utils/cpf';

const PartnerLogin = (): React.ReactElement => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = React.useState(false);
  const [formData, setFormData] = React.useState({
    user: '',
    code: '',
  });

  const { authenticate, signin } = useAuthenticate();

  React.useEffect(() => {
    if (authenticate?.isAuthenticate) {
      navigate('/home');
    }
  }, [authenticate, navigate]);

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    try {
      event.preventDefault();
      setIsLoading(true);

      const { user, code } = formData;

      if (!user?.length || !code?.length) {
        toast('Por favor, preencha os campos antes de submeter o formulário.', {
          type: 'error',
        });
        return;
      }

      const cpf = removeCpfMask(user);

      const data = await singin({ user: cpf, code });

      signin(data.token);
    } catch (error: any) {
      const message = 'Usuário ou senha inválidos!';
      toast(message, { type: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (event: any) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeCpf = (event: any): void => {
    setFormData({
      ...formData,
      user: cpfMask(event.target.value),
    });
  };

  return (
    <Div>
      {/* <StyleLinkPartnerContent>
        <StyleLinkPartner onClick={() => navigate('/')}>
          Área do cliente
        </StyleLinkPartner>
      </StyleLinkPartnerContent> */}
      <Div2>
        <Div3>Milhas na conta</Div3>

        <PlaneContainer>
          <Plane />
        </PlaneContainer>

        <Div4>
          <Div5>
            <BemVindo>Bem-vindo ao </BemVindo>

            <MilhasConta>Milhas na conta!</MilhasConta>
          </Div5>

          <Div6>Conecte-se para acessar o painel.</Div6>

          <FormLoginStyled>
            <Div7>Usuário</Div7>
            <SimpleInput
              placeholder={'Digite seu usuário (CPF ou CNPJ)'}
              name="user"
              onChange={handleChangeCpf}
              value={formData.user}
            />
            <Div9>Senha</Div9>
            <SimpleInput
              placeholder={'Digite sua senha'}
              name="code"
              type="password"
              onChange={handleChange}
              value={formData.code}
            />

            <SubmitButton onClick={handleSubmit} disabled={isLoading}>
              {isLoading ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                <span>Entrar</span>
              )}
            </SubmitButton>
          </FormLoginStyled>
        </Div4>
      </Div2>
    </Div>
  );
};

export default PartnerLogin;
