import * as React from 'react';

import HomeIcon from '@mui/icons-material/Home';

import Lottie from 'react-lottie';
import { Div18, Div19, Div25, Div28, Div37, Div38, Div32 } from './styles';

import animationData from '../success.json';
import { formatDate } from '../../../utils/date';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

interface SuccessRequest {
  id: string;
  date: Date;
}

interface Props {
  successData: SuccessRequest | null;
  handleFinishRequest: () => void;
}

const StepFourRequest: React.FC<Props> = ({
  successData,
  handleFinishRequest,
}): React.ReactElement => {
  return (
    <React.Fragment>
      <Div18>
        <Div19>Solicitação concluída com sucesso!</Div19>
      </Div18>

      <Div25>
        <Div28>
          <div>ID da solicitação:</div> {successData?.id}
        </Div28>
        <Div28>
          <div>Finalizada em:</div> {formatDate(successData?.date)}
        </Div28>
      </Div25>
      <div>
        <Lottie options={defaultOptions} height={150} width={150} />
      </div>
      <Div32>
        <Div37 onClick={() => handleFinishRequest()}>
          <Div38>Página inicial</Div38>
          <HomeIcon />
        </Div37>
      </Div32>
    </React.Fragment>
  );
};

export default StepFourRequest;
