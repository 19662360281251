import styled from 'styled-components';

export const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 265px;
  text-align: center;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 24px 40px rgba(126, 196, 31, 0.1);
  margin-bottom: 50px;
`;

export const StyledCardFigure = styled.figure`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  height: 190px;
  background: var(--color-primary-light);
`;

export const StyledCardContent = styled.div`
  flex: 1;
  padding: 24px 20px 40px;
  background: var(--color-white);
  position: relative;
`;

export const StyledCardStep = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 55px;
  height: 55px;
  border-radius: 55px;
  margin: -53px auto 16px;
  background: var(--color-primary);
  color: var(--color-white);
  font-size: 27px;
  font-weight: 500;
  line-height: 1;
`;

export const StyledCardTitle = styled.h3`
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5;
  text-transform: uppercase;
  margin-bottom: 12px;
`;

export const StyledCardText = styled.p`
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
`;
