import React, { ReactElement } from 'react';
import {
  StyledImageContainer,
  StyledLogoContainer,
  StyledTitle,
} from './styles';
import Plane from '../../images/Plane';

const Logo = (): ReactElement => {
  return (
    <StyledLogoContainer>
      <StyledImageContainer>
        <Plane />
      </StyledImageContainer>
      <StyledTitle>Milhas na conta</StyledTitle>
    </StyledLogoContainer>
  );
};

export default Logo;
