import React, { ReactElement } from 'react';
// import Lottie from 'react-lottie';
// import animationData from './connect.json';
import { StyledRoot, StyledText } from './styles';

// const defaultOptions = {
//   loop: true,
//   autoplay: true,
//   animationData,
//   rendererSettings: {
//     preserveAspectRatio: 'xMidYMid slice',
//   },
// };

const Loading: React.FC = (): ReactElement => {
  return (
    <StyledRoot>
      {/* <Lottie options={defaultOptions} height={300} width={300} /> */}
      <StyledText>Carregando</StyledText>
    </StyledRoot>
  );
};

export default Loading;
