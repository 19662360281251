import React from 'react';

export const ReceiptFigure = (): React.ReactElement => (
  <svg xmlns="http://www.w3.org/2000/svg" width={234} height={158} fill="none">
    <path
      fill="#D6EF89"
      d="M33.633 139.251 26.577 92.1l1.153-.727 5.472 35.693-1.008-39.9 1.584-1.596 2.015 44.25 4.75-45.554 1.584.142-4.033 57.311-4.461-2.468Z"
    />
    <path
      fill="#D6EF89"
      d="M63.007 138.721H16.63c-.791 0-1.435.648-1.435 1.446v3.573c0 .798.643 1.446 1.435 1.446h.964l1.917 11.757h40.612l1.917-11.757h.964c.792 0 1.435-.648 1.435-1.446v-3.573c0-.798-.643-1.446-1.435-1.446h.003Z"
    />
    <path
      fill="#fff"
      d="M61.292 145.329H19.326a.19.19 0 0 1-.189-.19.19.19 0 0 1 .189-.19h41.966a.19.19 0 0 1 .19.19.19.19 0 0 1-.19.19Z"
    />
    <path
      fill="#D6EF89"
      d="M18.005 17.37c-.755 4.767-1.428 13.41 3.346 14.584 4.717 1.161 2.591-7.352.907-12.52 3.086 1.61 6.145 3.474 8.895 5.593-1.8 6.248-9.04 15.498-3.39 16.748 4.31.954 5.984-8.09 6.614-13.988 2.052 1.963 3.798 4.116 5.075 6.452-1.496 3.393-3.845 7.987-6.877 11.604-5.496 6.547-1.075 8.431 2.136 6.526 3.545-2.105 5.543-9.368 6.436-13.922.178.675.323 1.365.417 2.068 2.51 18.408-15.256 23.4-15.256 23.4C13.399 67.263 1.997 60.058.103 50.308c6.193 4.34 16.507 10.605 19.435 6.843C23.113 52.556 5.973 47.21.093 45.54c.122-.975.334-1.963.647-2.955a62.71 62.71 0 0 0 1.46-5.43c5.326 3.563 13.949 8.534 15.863 4.863 2.14-4.102-9.368-8.02-15.015-9.664.37-2.809.489-5.376.445-7.678 3.834 2.71 8.579 5.243 9.913 2.397 1.853-3.95-5.647-6.944-10.425-8.408-.728-4.68-1.965-7.274-1.965-7.274s8.05 1.926 16.993 5.974l-.004.003ZM67.896 6.475c.755 4.768 1.429 13.41-3.346 14.585-4.717 1.162-2.59-7.352-.906-12.52-3.086 1.61-6.146 3.474-8.895 5.593 1.8 6.248 9.04 15.498 3.39 16.748-4.31.954-5.985-8.089-6.615-13.988-2.052 1.963-3.797 4.116-5.074 6.453 1.496 3.392 3.844 7.986 6.877 11.603 5.495 6.547 1.075 8.432-2.136 6.527-3.545-2.106-5.543-9.37-6.436-13.923a16.874 16.874 0 0 0-.418 2.068c-2.51 18.409 15.257 23.4 15.257 23.4 12.908 3.349 24.162-3.793 26.056-13.546-6.193 4.34-16.359 10.541-19.287 6.779-3.575-4.595 13.566-9.94 19.445-11.61a16.6 16.6 0 0 0-.647-2.955 62.739 62.739 0 0 1-1.459-5.43c-5.327 3.562-13.95 8.534-15.863 4.863-2.14-4.102 9.367-8.021 15.014-9.665a51.22 51.22 0 0 1-.444-7.678c-3.835 2.71-8.579 5.244-9.913 2.398-1.853-3.95 5.647-6.944 10.425-8.408.727-4.68 1.964-7.274 1.964-7.274s-8.05 1.926-16.992 5.973l.003.007Z"
    />
    <path fill="#fff" d="M233.808 74.157H78.932v11.335h154.876V74.157Z" />
    <path
      fill="#081906"
      d="M233.808 85.679h-78.097a.19.19 0 0 1-.189-.19.19.19 0 0 1 .189-.19h77.912V74.342h-57.089a.189.189 0 0 1-.188-.19c0-.105.084-.19.188-.19h57.278c.104 0 .188.085.188.19v11.335c0 .106-.084.19-.188.19h-.004Z"
    />
    <path
      fill="#081906"
      d="M229.033 156.902a.189.189 0 0 1-.188-.19V85.655a.189.189 0 1 1 .377 0v71.057a.19.19 0 0 1-.189.19ZM114.014 82.409h38.072c1.749 0 3.167 1.43 3.167 3.192v.027h-44.409V85.6c0-1.763 1.418-3.192 3.167-3.192h.003Zm61.829-9.974a1.312 1.312 0 0 0-1.233-.873h-48.803c-.552 0-1.044.35-1.233.873l-.691 1.926h52.655l-.691-1.926h-.004Z"
    />
    <path fill="#081906" d="M146.182 71.688h8.171v-12.89h-8.171v12.89Z" />
    <path
      fill="#081906"
      d="M188.691-.007h-73.575c-3.545 0-6.419 2.896-6.419 6.469v47.082c0 3.573 2.874 6.47 6.419 6.47h73.575c3.545 0 6.419-2.897 6.419-6.47V6.462c0-3.573-2.874-6.47-6.419-6.47Z"
    />
    <path fill="#fff" d="M191.542 56.852V3.158h-79.273v53.694h79.273Z" />
    <path fill="#fff" d="M164.62 36.997h-4.65v2.845h4.65v-2.845Z" />
    <path
      fill="#081906"
      d="M164.62 40.03h-4.65a.189.189 0 0 1-.189-.191v-2.846a.19.19 0 0 1 .189-.19h4.65c.104 0 .188.085.188.19v2.846c0 .105-.084.19-.188.19Zm-4.462-.378h4.273v-2.465h-4.273v2.465Z"
    />
    <path
      fill="#D6EF89"
      d="M120.584 3.154h-8.315v53.694h8.315V3.154Zm70.958 0h-8.191v53.694h8.191V3.154Zm-20.045 11.2h-35.036v3.942h35.036v-3.942Z"
    />
    <path
      fill="#081906"
      d="M132.819 10.088h-9.182a.189.189 0 0 1-.188-.19c0-.105.084-.19.188-.19h9.182a.19.19 0 0 1 .189.19.19.19 0 0 1-.189.19Zm0-2.163h-9.182a.19.19 0 0 1-.189-.19.19.19 0 0 1 .189-.19h9.182a.19.19 0 0 1 .189.19.19.19 0 0 1-.189.19Zm0 4.561h-9.182a.189.189 0 0 1-.188-.19c0-.106.084-.19.188-.19h9.182a.19.19 0 0 1 .189.19.19.19 0 0 1-.189.19Zm47.354-4.561h-7.679a.189.189 0 0 1-.189-.19.19.19 0 0 1 .189-.19h7.679c.104 0 .188.085.188.19s-.084.19-.188.19Z"
    />
    <path fill="#fff" d="M160.024 23.356h-35.096V26.2h35.096v-2.845Z" />
    <path
      fill="#081906"
      d="M160.024 26.392h-35.096a.189.189 0 0 1-.189-.19v-2.846c0-.106.084-.19.189-.19h35.096c.104 0 .188.084.188.19V26.2c0 .106-.084.19-.188.19Zm-34.908-.377h34.722v-2.466h-34.722v2.466Z"
    />
    <path fill="#fff" d="M164.62 23.356h-4.65V26.2h4.65v-2.845Z" />
    <path
      fill="#081906"
      d="M164.62 26.392h-4.65a.189.189 0 0 1-.189-.19v-2.846c0-.106.084-.19.189-.19h4.65c.104 0 .188.084.188.19V26.2c0 .106-.084.19-.188.19Zm-4.462-.377h4.273v-2.466h-4.273v2.466Z"
    />
    <path fill="#fff" d="M171.325 23.356h-6.742V26.2h6.742v-2.845Z" />
    <path
      fill="#081906"
      d="M171.325 26.392h-6.742a.189.189 0 0 1-.189-.19v-2.846c0-.106.084-.19.189-.19h6.742a.19.19 0 0 1 .189.19V26.2a.19.19 0 0 1-.189.19Zm-6.557-.377h6.368v-2.466h-6.368v2.466Z"
    />
    <path fill="#D6EF89" d="M178.259 23.356h-6.742V26.2h6.742v-2.845Z" />
    <path fill="#fff" d="M160.024 27.903h-35.096v2.846h35.096v-2.846Z" />
    <path
      fill="#081906"
      d="M160.024 30.939h-35.096a.189.189 0 0 1-.189-.19v-2.846c0-.106.084-.19.189-.19h35.096c.104 0 .188.084.188.19v2.845c0 .106-.084.19-.188.19Zm-34.908-.38h34.722v-2.466h-34.722v2.465Z"
    />
    <path fill="#fff" d="M164.62 27.903h-4.65v2.846h4.65v-2.846Z" />
    <path
      fill="#081906"
      d="M164.62 30.939h-4.65a.189.189 0 0 1-.189-.19v-2.846c0-.106.084-.19.189-.19h4.65c.104 0 .188.084.188.19v2.845c0 .106-.084.19-.188.19Zm-4.462-.38h4.273v-2.466h-4.273v2.465Z"
    />
    <path fill="#fff" d="M171.325 27.903h-6.742v2.846h6.742v-2.846Z" />
    <path
      fill="#081906"
      d="M171.325 30.939h-6.742a.189.189 0 0 1-.189-.19v-2.846c0-.106.084-.19.189-.19h6.742a.19.19 0 0 1 .189.19v2.845a.19.19 0 0 1-.189.19Zm-6.557-.38h6.368v-2.466h-6.368v2.465Z"
    />
    <path fill="#D6EF89" d="M178.259 27.903h-6.742v2.846h6.742v-2.846Z" />
    <path fill="#fff" d="M160.024 32.45h-35.096v2.846h35.096V32.45Z" />
    <path
      fill="#081906"
      d="M160.024 35.486h-35.096a.189.189 0 0 1-.189-.19V32.45c0-.106.084-.19.189-.19h35.096c.104 0 .188.084.188.19v2.845c0 .106-.084.19-.188.19Zm-34.908-.38h34.722V32.64h-34.722v2.465Z"
    />
    <path fill="#fff" d="M164.62 32.45h-4.65v2.846h4.65V32.45Z" />
    <path
      fill="#081906"
      d="M164.62 35.486h-4.65a.189.189 0 0 1-.189-.19V32.45a.19.19 0 0 1 .189-.19h4.65c.104 0 .188.085.188.19v2.845c0 .106-.084.19-.188.19Zm-4.462-.38h4.273V32.64h-4.273v2.465Z"
    />
    <path fill="#fff" d="M171.325 32.45h-6.742v2.846h6.742V32.45Z" />
    <path
      fill="#081906"
      d="M171.325 35.486h-6.742a.189.189 0 0 1-.189-.19V32.45a.19.19 0 0 1 .189-.19h6.742a.19.19 0 0 1 .189.19v2.845a.19.19 0 0 1-.189.19Zm-6.557-.38h6.368V32.64h-6.368v2.465Z"
    />
    <path fill="#D6EF89" d="M178.259 32.45h-6.742v2.846h6.742V32.45Z" />
    <path fill="#fff" d="M160.024 36.997h-35.096v2.845h35.096v-2.845Z" />
    <path
      fill="#081906"
      d="M160.024 40.029h-35.096a.189.189 0 0 1-.189-.19v-2.846a.19.19 0 0 1 .189-.19h35.096c.104 0 .188.085.188.19v2.846c0 .105-.084.19-.188.19Zm-34.908-.377h34.722v-2.465h-34.722v2.465Z"
    />
    <path fill="#fff" d="M177.976 45.269H159.97v6.337h18.006v-6.337Z" />
    <path
      fill="#081906"
      d="M177.976 51.792H159.97a.189.189 0 0 1-.189-.19v-6.336c0-.106.084-.19.189-.19h18.006c.105 0 .189.084.189.19v6.336a.19.19 0 0 1-.189.19Zm-17.818-.377h17.629V45.46h-17.629v5.956Z"
    />
    <path fill="#fff" d="M171.325 36.997h-6.742v2.846h6.742v-2.846Z" />
    <path
      fill="#081906"
      d="M171.325 40.03h-6.742a.189.189 0 0 1-.189-.191v-2.846a.19.19 0 0 1 .189-.19h6.742a.19.19 0 0 1 .189.19v2.846a.19.19 0 0 1-.189.19Zm-6.557-.378h6.368v-2.465h-6.368v2.465Z"
    />
    <path fill="#D6EF89" d="M178.259 36.997h-6.742v2.845h6.742v-2.845Z" />
    <path
      fill="#081906"
      d="M177.976 41.897h-10.782a.189.189 0 0 1-.189-.19.19.19 0 0 1 .189-.19h10.782c.105 0 .189.084.189.19a.19.19 0 0 1-.189.19Z"
    />
    <path
      fill="#fff"
      d="m125.278 156.82 10.863-33.53-35.167-4.594-13.066 38.124h37.37Z"
    />
    <path
      fill="#081906"
      d="M87.908 157.011c-.02 0-.04 0-.06-.011a.191.191 0 0 1-.119-.241l13.07-38.124a.19.19 0 0 1 .202-.126l35.164 4.598a.184.184 0 0 1 .135.088c.03.051.037.106.02.16l-10.86 33.527a.191.191 0 0 1-.236.122.193.193 0 0 1-.121-.238l10.789-33.309-34.786-4.547-13.02 37.978a.192.192 0 0 1-.178.129v-.006Z"
    />
    <path
      fill="#fff"
      d="M115.396 128.724s-4.566 13.141-9.714 28.093h43.317c3.794-9.023 7.878-19.149 11.429-29.306 6.011-17.189-6.681-35.197-24.762-35.197H40.911l-5.722 14.945c-3.986 10.408 3.676 21.597 14.745 21.529 26.225-.16 65.458-.068 65.458-.068l.004.004Z"
    />
    <path
      fill="#081906"
      d="M105.682 157.011c-.021 0-.041 0-.061-.011a.187.187 0 0 1-.115-.241c4.704-13.658 8.97-25.95 9.627-27.842-3.491-.007-40.39-.085-65.192.068h-.098c-5.222 0-10.105-2.581-13.077-6.917a16.12 16.12 0 0 1-1.748-14.87l5.721-14.945a.186.186 0 0 1 .175-.122h94.756c8.693 0 16.561 4.102 21.584 11.253 5.014 7.138 6.237 15.957 3.356 24.195-3.005 8.592-6.637 17.906-11.436 29.316a.188.188 0 0 1-.245.102.192.192 0 0 1-.102-.248c4.795-11.403 8.424-20.711 11.426-29.295 2.841-8.123 1.634-16.816-3.309-23.849-4.953-7.05-12.706-11.094-21.278-11.094H41.042l-5.677 14.826a15.739 15.739 0 0 0 1.708 14.52c2.941 4.296 7.591 6.755 12.764 6.755h.1c25.925-.157 65.068-.068 65.459-.068.06 0 .118.03.151.081a.197.197 0 0 1 .024.173c-.047.133-4.623 13.312-9.714 28.094a.192.192 0 0 1-.179.129l.004-.01Z"
    />
    <path
      fill="#081906"
      d="M129.413 128.948h-13.168a.19.19 0 0 1-.189-.19.19.19 0 0 1 .189-.191h13.168a.19.19 0 0 1 .188.191c0 .105-.084.19-.188.19Z"
    />
    <path
      fill="#fff"
      d="M92.403 12.564s6.573 14.038.623 19.41c-1.385 1.25-4.535 1.752-5.597 1.508-.212-.048-4.889 9.47-5.098 9.416-4.346-1.175-11.699-2.108-11.699-2.108S85.512 8.52 92.4 12.56l.004.004Z"
    />
    <path
      fill="#081906"
      d="M82.352 43.095c-.02 0-.044-.006-.068-.01-4.282-1.158-11.597-2.092-11.672-2.102a.19.19 0 0 1-.141-.095.196.196 0 0 1-.007-.173c.492-1.066 12.134-26.161 19.563-28.511.92-.292 1.749-.224 2.47.197a.17.17 0 0 1 .074.081c.017.034 1.665 3.576 2.558 7.733 1.192 5.541.508 9.657-1.978 11.902-1.395 1.26-4.488 1.766-5.658 1.572-.293.421-1.475 2.713-2.439 4.578-2.264 4.387-2.514 4.828-2.706 4.828h.004Zm-11.436-2.458c1.327.176 7.5 1.022 11.358 2.05.303-.437 1.486-2.733 2.45-4.597 2.503-4.85 2.53-4.843 2.75-4.792 1.027.235 4.117-.281 5.427-1.467 5.597-5.052-.209-18.18-.643-19.135-.613-.34-1.324-.387-2.116-.136-6.897 2.184-18.003 25.472-19.223 28.077h-.003Z"
    />
    <path
      fill="#081906"
      d="M87.436 33.642a.206.206 0 0 1-.088-.02.191.191 0 0 1-.08-.256c.791-1.545 1.627-1.864 1.664-1.877a.183.183 0 0 1 .239.112.185.185 0 0 1-.111.24c-.014.008-.748.306-1.46 1.695a.188.188 0 0 1-.168.102l.004.004Z"
    />
    <path
      fill="#fff"
      d="M131.886 53.29c6.318-3.325 13.36-6.755 20.288-5.05.778.19 1.674.6 1.769 1.402.128 1.104-1.291 1.634-2.386 1.776l-5.394.693-.283.071c1.32.595 2.672 1.331 3.423 2.578.751 1.246.633 3.13-.603 3.891a3.468 3.468 0 0 1 .977 3.155 3.44 3.44 0 0 1-2.16 2.49c.509.688.354 1.683-.064 2.434-.677 1.216-2.001 2.037-3.383 2.102-1.327.061-2.662-.58-3.464-1.647.017-.034.037-.068.054-.105l-.151-.028c.03.045.064.089.097.133a33.966 33.966 0 0 1-5.377 7.722 38.52 38.52 0 0 1-1.419 1.44l-10.209-17.862c2.685-1.915 5.408-3.678 8.285-5.192v-.004Z"
    />
    <path
      fill="#081906"
      d="M133.81 76.534a.188.188 0 0 1-.135-.058.192.192 0 0 1 .004-.268 33.69 33.69 0 0 0 6.701-9.006.51.51 0 0 1-.03-.044.19.19 0 0 1-.003-.21.188.188 0 0 1 .192-.085l.151.027c.058.01.108.047.135.102a.188.188 0 0 1 0 .17 3.947 3.947 0 0 0 3.231 1.476c1.314-.06 2.581-.848 3.228-2.003.341-.611.566-1.562.078-2.228a.194.194 0 0 1-.031-.17.186.186 0 0 1 .118-.122 3.25 3.25 0 0 0 2.039-2.35c.222-1.062-.138-2.234-.923-2.978a.185.185 0 0 1-.058-.156.203.203 0 0 1 .088-.142c1.176-.72 1.226-2.49.542-3.63-.724-1.206-2.035-1.916-3.339-2.503a.19.19 0 0 1 .031-.357l.283-.071s.013 0 .02-.004l5.394-.692c1.096-.14 2.332-.635 2.224-1.566-.087-.754-1.058-1.1-1.627-1.24-6.665-1.64-13.336 1.444-20.153 5.033-2.608 1.372-5.233 3.02-8.265 5.179a.188.188 0 0 1-.263-.044.191.191 0 0 1 .044-.265c3.046-2.17 5.684-3.824 8.306-5.206 6.883-3.623 13.622-6.737 20.418-5.063 1.146.282 1.827.839 1.911 1.566.141 1.205-1.284 1.823-2.551 1.986l-5.088.652c1.173.57 2.295 1.28 2.969 2.404.741 1.23.701 3.114-.475 4.017a3.679 3.679 0 0 1 .869 3.169 3.646 3.646 0 0 1-2.062 2.54c.425.784.185 1.783-.179 2.435-.707 1.266-2.095 2.129-3.538 2.197-1.29.06-2.581-.506-3.436-1.491a34.072 34.072 0 0 1-5.274 7.505c-.4.434-.855.893-1.425 1.446a.197.197 0 0 1-.131.054l.01-.006Z"
    />
    <path
      fill="#081906"
      d="M147.985 58.36a.176.176 0 0 1-.111-.038 15.756 15.756 0 0 0-3.477-1.919.186.186 0 0 1-.105-.244.184.184 0 0 1 .243-.105 16.202 16.202 0 0 1 3.558 1.966c.084.061.104.18.04.265a.187.187 0 0 1-.151.078l.003-.004Zm-1.021 5.922a.178.178 0 0 1-.121-.044 12.075 12.075 0 0 0-2.679-1.732.19.19 0 0 1 .159-.343c.994.468 1.924 1.07 2.763 1.786a.191.191 0 0 1-.125.336l.003-.003Zm-6.466 2.778a.19.19 0 0 1-.188-.184c0-.105.081-.19.182-.194l2.766-.07a.186.186 0 0 1 .192.182c0 .106-.081.19-.182.194l-2.766.071h-.004Z"
    />
    <path
      fill="#7EC41F"
      d="M33.134 101.622c4.347-13.057 21.48-49.025 24.122-54.574.93-1.953 1.887-3.946 3.407-5.474 3.497-3.525 9.013-3.776 13.956-3.59 6.21.235 15.978.184 21.487 3.08 4.528 2.381 13.437 19.245 16.426 25.041 4.495-2.876 8.895-4.791 12.864-7.62l10.21 17.862c-.816.795-5.189 4.662-9.863 7.77-5.431 3.613-12.052 5.528-18.188 3.341-6.136-2.187-11.16-9.593-13.65-15.655l-.06-.125c-1.115 5.98-2.568 12.812-4.35 18.626-.212.689-.455 1.42-1.01 1.874-.668.544-1.602.554-2.46.54-1.8-.034-3.6-.064-5.399-.098-2.072 4.706-4.14 8.496-6.213 13.202-.859 1.953-1.755 3.96-3.295 5.427-1.87 1.783-4.471 2.554-7.008 2.981-7.073 1.192-14.31.177-21.396-.93-2.164-.34-4.367-.696-6.322-1.695-1.954-.998-3.652-2.75-4.033-4.923-.296-1.698.23-3.42.775-5.053v-.007Z"
    />
    <path
      fill="#081906"
      d="M91.894 71.708c-.02 0-.04 0-.058-.01a.192.192 0 0 1-.12-.238 349.11 349.11 0 0 1 2.486-7.362c.084-.244.202-.58.472-.788.647-.495 1.53.089 1.82.282a.189.189 0 0 1-.207.316c-.63-.418-1.095-.516-1.384-.295-.165.129-.256.353-.347.611a329.829 329.829 0 0 0-2.484 7.355.187.187 0 0 1-.178.13Zm3.254-65.219c-1.088-.873-2.473-1.375-3.871-1.345-.913.02-1.766.292-2.618.577-1.021.136-2.066.384-3.107.649-1.233-.051-2.45-.462-3.693-.53a9.56 9.56 0 0 0-4.407.839c-1.826.815-3.295 2.204-4.269 3.898-.57-1.426-2.008-2.451-3.561-2.563-2.234-.163-4.344 1.392-5.304 3.348-1.004 2.037-.856 4.506.633 6.265 1.524 1.8 4.024 2.007 6.207 1.807.057-.007.118-.014.175-.017a11.29 11.29 0 0 1-.188 1.769c-.617 1.67-1.685 3.114-3.424 3.81-3.137 1.253-7.43.224-9.677 3.355-1.065 1.48-.862 3.348-1.2 5.05-.168.852-.505 1.599-1.118 2.22-.563.567-1.24.995-1.904 1.426-.71.462-1.435.934-2.035 1.539a6.78 6.78 0 0 0-1.432 2.14c-.697 1.63-.758 3.49-.111 5.147a7.1 7.1 0 0 0 1.314 2.157c.596.672 1.344 1.103 2.049 1.646.3.231.593.5.764.843.23.462.216.991.23 1.494.023.91.158 1.813.448 2.676 1.129 3.375 4.279 5.81 7.884 5.555a7.04 7.04 0 0 0 2.595-.686c.862-.417 1.614-1.022 2.375-1.596.789-.59 1.61-1.181 2.564-1.467.452-.136.934-.207 1.405-.142.499.067.967.268 1.436.444 1.738.656 3.568.557 5.232-.278 1.604-.801 2.83-2.248 3.555-3.881.364-.819.61-1.671.758-2.557.166-.995.32-1.906.978-2.704.566-.689 1.236-1.28 1.725-2.03.478-.734.791-1.58.953-2.438.364-1.919.027-3.871-.637-5.688a28.601 28.601 0 0 0-1.25-2.846c-.438-.89-.947-1.796-1.088-2.795-.115-.798.04-1.657.623-2.24.22-.218.155-.5-.016-.666 1.502-2.591 3.581-5.889 3.581-5.889s2.288-1.45 3.137-4.611v-.007c2.17-.829 4.37-1.953 5.593-4 1.48-2.472.947-5.868-1.317-7.678h.013Z"
    />
    <path
      fill="#fff"
      d="M87.5 23.818s-.936-3.776-4.124-3.59c-3.187.19-2.999 5.668-.374 7.933 2.625 2.265 3.747.754 3.747.754l.748-5.1.003.003Z"
    />
    <path
      fill="#081906"
      d="M85.573 29.597c-.795 0-1.698-.434-2.696-1.294-1.691-1.46-2.507-4.319-1.823-6.373.388-1.155 1.207-1.827 2.308-1.892 3.306-.197 4.307 3.691 4.317 3.732a.19.19 0 0 1-.138.231.191.191 0 0 1-.23-.14c-.037-.149-.94-3.623-3.928-3.446-1.203.071-1.739.927-1.975 1.633-.563 1.681-.01 4.48 1.712 5.967.99.856 1.904 1.27 2.628 1.195.573-.058.839-.397.85-.41a.187.187 0 0 1 .262-.035c.08.065.098.18.037.265-.013.02-.36.476-1.095.557a2.515 2.515 0 0 1-.23.014v-.004Z"
    />
    <path
      fill="#081906"
      d="M85.536 26.164a.187.187 0 0 1-.185-.166c0-.027-.334-2.69-2.19-2.93a.187.187 0 0 1-.162-.211.186.186 0 0 1 .209-.163c2.146.278 2.5 3.14 2.514 3.26a.188.188 0 0 1-.166.21h-.023.003Z"
    />
    <path
      fill="#fff"
      d="M87.466 123.576h-5.499L65.463 51.408c-.768-3.358-3.736-5.739-7.156-5.739H20.44c-4.734 0-8.231 4.449-7.15 9.09l17.15 74.213c.668 2.889 2.228 4.862 5.638 4.862h51.387c2.665 0 4.825-2.176 4.825-4.862v-.537c0-2.686-2.16-4.863-4.825-4.863v.004Z"
    />
    <path
      fill="#081906"
      d="M87.467 134.028H36.079c-3.137 0-5.04-1.64-5.819-5.009L13.11 54.808a7.598 7.598 0 0 1 1.428-6.449 7.474 7.474 0 0 1 5.904-2.876h29.314a.19.19 0 0 1 .188.19.19.19 0 0 1-.188.19H20.442a7.09 7.09 0 0 0-5.607 2.734 7.217 7.217 0 0 0-1.358 6.126l17.15 74.211c.745 3.22 2.477 4.717 5.452 4.717h51.388c2.557 0 4.636-2.095 4.636-4.673v-.536c0-2.577-2.079-4.673-4.636-4.673h-5.5a.185.185 0 0 1-.181-.146l-5.823-25.458a.189.189 0 1 1 .367-.085l5.79 25.312h5.35c2.763 0 5.014 2.265 5.014 5.053v.537c0 2.784-2.248 5.053-5.014 5.053l-.003-.007Z"
    />
    <path
      fill="#081906"
      d="M84.417 156.977a.19.19 0 0 1-.189-.173l-2.082-22.963a.19.19 0 0 1 .168-.207.19.19 0 0 1 .206.17l2.082 22.962a.19.19 0 0 1-.168.207h-.017v.004Zm-46.101 0H38.3a.187.187 0 0 1-.168-.207l2.082-22.963a.188.188 0 1 1 .374.038l-2.082 22.962a.19.19 0 0 1-.189.173v-.003Zm42.563-33.089h-5.722a.185.185 0 0 1-.181-.146l-15.813-69.09a.189.189 0 1 1 .367-.085l15.779 68.941h5.573a.19.19 0 0 1 .189.19.19.19 0 0 1-.189.19h-.003Z"
    />
    <path
      fill="#fff"
      d="M194.702 60.57h36.279v7.09h-36.279c-1.924 0-3.487-1.575-3.487-3.514v-.061c0-1.94 1.563-3.515 3.487-3.515Z"
    />
    <path
      fill="#081906"
      d="M230.981 67.85h-36.676c-1.806 0-3.275-1.48-3.275-3.3v-.866c0-1.82 1.469-3.3 3.275-3.3h36.676c.105 0 .189.084.189.19v7.09a.19.19 0 0 1-.189.19v-.003Zm-36.676-7.093c-1.601 0-2.901 1.31-2.901 2.924v.866c0 1.613 1.3 2.924 2.901 2.924h36.487v-6.714h-36.487Z"
    />
    <path
      fill="#081906"
      d="M207.934 67.23a1.465 1.465 0 0 1-1.459-1.47v-3.288c0-.812.654-1.47 1.459-1.47a.19.19 0 0 1 .189.19.19.19 0 0 1-.189.19c-.597 0-1.082.489-1.082 1.09v3.287c0 .601.485 1.09 1.082 1.09a.19.19 0 0 1 .189.19.19.19 0 0 1-.189.19Z"
    />
    <path
      fill="#081906"
      d="M214.1 65.77h-5.988a.189.189 0 0 1-.188-.191c0-.105.084-.19.188-.19h5.988a.19.19 0 0 1 .189.19.19.19 0 0 1-.189.19Zm4.31-2.942h-8.448a.189.189 0 0 1-.188-.19c0-.105.084-.19.188-.19h8.448c.104 0 .188.085.188.19 0 .106-.084.19-.188.19Z"
    />
    <path
      fill="#fff"
      d="M194.396 67.66h36.582v6.47h-36.582c-1.756 0-3.181-1.437-3.181-3.206v-.058c0-1.769 1.425-3.205 3.181-3.205Z"
    />
    <path
      fill="#081906"
      d="M230.981 74.32h-36.946c-1.658 0-3.005-1.359-3.005-3.03v-.79c0-1.671 1.347-3.03 3.005-3.03h36.946a.19.19 0 0 1 .189.19v6.47a.19.19 0 0 1-.189.19Zm-36.949-6.47c-1.449 0-2.632 1.19-2.632 2.653v.791c0 1.46 1.18 2.652 2.632 2.652h36.76v-6.092h-36.76v-.003Z"
    />
    <path
      fill="#081906"
      d="M207.934 73.698a1.465 1.465 0 0 1-1.459-1.47v-2.666c0-.811.654-1.47 1.459-1.47a.19.19 0 0 1 .189.19.19.19 0 0 1-.189.19c-.597 0-1.082.49-1.082 1.09v2.666c0 .6.485 1.09 1.082 1.09a.19.19 0 0 1 .189.19.19.19 0 0 1-.189.19Z"
    />
    <path fill="#D6EF89" d="M204.49 69.128h-11.011v3.68h11.011v-3.68Z" />
    <path
      fill="#fff"
      d="M178.192 73.151h6.948c1.209 0 1.947-.733 2.197-2.214l1.418-7.457c0-1.521-1.236-1.375-2.746-1.375h-8.686c-1.51 0-2.746-.146-2.746 1.375l1.418 7.457c.297 1.41.987 2.215 2.197 2.215Z"
    />
    <path
      fill="#081906"
      d="M185.14 73.339h-6.948c-1.246 0-2.048-.795-2.382-2.364l-1.418-7.46v-.038c0-.499.124-.862.377-1.117.465-.462 1.264-.458 2.19-.448h9.421c.927-.007 1.729-.014 2.19.448.253.255.378.618.378 1.117v.038l-1.419 7.457c-.263 1.569-1.065 2.367-2.382 2.367h-.007Zm-10.374-9.879 1.415 7.44c.293 1.386.95 2.062 2.011 2.062h6.948c1.122 0 1.779-.673 2.012-2.058l1.415-7.444c0-.383-.091-.655-.266-.832-.354-.353-1.082-.346-1.924-.34h-9.428c-.842-.006-1.57-.013-1.924.34-.175.177-.263.449-.266.832h.007Z"
    />
    <path
      fill="#fff"
      d="M189.658 71.644h-16.062l1.291 2.516 13.231.017 1.54-2.533Z"
    />
    <path
      fill="#081906"
      d="m188.118 74.364-13.231-.017a.195.195 0 0 1-.169-.102l-1.29-2.516a.191.191 0 0 1 .007-.187.183.183 0 0 1 .161-.092h16.062c.068 0 .132.038.165.099a.19.19 0 0 1-.003.19l-1.54 2.53a.184.184 0 0 1-.162.091v.004Zm-13.117-.394 13.013.014 1.307-2.153h-15.415l1.095 2.136v.003Zm12.609-3.372h-1.166a.189.189 0 0 1-.189-.19.19.19 0 0 1 .189-.19h1.166c.99 0 1.796-.812 1.796-1.81V66.53c0-.999-.806-1.81-1.796-1.81h-1.099a.189.189 0 0 1-.188-.19c0-.106.084-.19.188-.19h1.099c1.196 0 2.17.981 2.17 2.186v1.878a2.181 2.181 0 0 1-2.17 2.187v.007Z"
    />
  </svg>
);
