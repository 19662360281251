import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import { Divider } from '@mui/material';
import {
  StyleBox,
  StyleContent,
  StyleContentButton,
  StyledContentCause,
} from './styles';
import { Button } from '../Button';

interface Props {
  open: boolean;
  message: string;
  cause: string[][];
  handleClose: () => void;
}

export default function ModalErrorUploadFiles({
  open,
  message,
  cause,
  handleClose,
}: Props): React.ReactElement {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <StyleBox>
          <Typography
            id="transition-modal-title"
            variant="h6"
            component="h2"
            color="error"
          >
            Oops. Não foi possível fazer o upload da planilha!
          </Typography>
          <StyleContent>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              {message}
            </Typography>
            {!!cause.length && (
              <StyledContentCause>
                {cause.map((item: string[], index: number) => (
                  <React.Fragment key={index}>
                    {item.map((item, indexItem) => (
                      <React.Fragment key={`${index}-${indexItem}`}>
                        <Typography component="li">{item}</Typography>
                      </React.Fragment>
                    ))}
                    {index < cause?.length - 1 && <Divider />}
                  </React.Fragment>
                ))}
              </StyledContentCause>
            )}
          </StyleContent>
          <StyleContentButton>
            <Button onClick={handleClose}>
              <span>Fechar</span>
            </Button>
          </StyleContentButton>
        </StyleBox>
      </Fade>
    </Modal>
  );
}
