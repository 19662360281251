export const formatStatus = (status: 'active' | 'inactive'): string => {
  const options = {
    active: 'Ativo',
    inactive: 'Inativo',
  };

  return options[status];
};

export const formatStatusColor = (status: 'active' | 'inactive'): string => {
  const options = {
    active: 'green',
    inactive: 'red',
  };

  return options[status];
};
