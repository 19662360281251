export const formatDate = (date: Date | undefined): string => {
  if (!date) {
    return '-';
  }

  const options: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  return new Intl.DateTimeFormat('pt-BR', options).format(new Date(date));
};

export const formatShortDate = (date: Date | undefined): string => {
  if (!date) {
    return '-';
  }

  const options: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  };

  return new Intl.DateTimeFormat('pt-BR', options).format(new Date(date));
};

export const formatOnlyDate = (date: Date | undefined): string => {
  if (!date) {
    return '-';
  }

  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  };

  return new Intl.DateTimeFormat('pt-BR', options).format(new Date(date));
};

export const formatOnlyHour = (date: Date | undefined): string => {
  if (!date) {
    return '-';
  }

  const options: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
  };

  return new Intl.DateTimeFormat('pt-BR', options).format(new Date(date));
};
