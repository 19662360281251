import React from 'react';
import { handleGoToTop } from '../../utils';
import {
  StyledActionButton,
  StyledList,
  StyledListItem,
  StyledSection,
} from './styles';

const MOCK = [
  {
    question: 'Quando recebo os pontos que comprei?',
    answer:
      'Caso o pagamento e envio de solicitação sejam concluídos na plataforma Milhas na Conta até meio dia, o crédito dos pontos será feito entre 16h e 23h do mesmo dia. Caso a solicitação seja concluída após meio dia, o crédito será feito entre 16h e 23h do dia seguinte, dentro da validade da campanha.',
  },
  {
    question: 'O bônus cai de imediato?',
    answer:
      'Não existe nenhuma garantia que o bônus caia imediatamente. A depender da cia e da campanha, o crédito dos pontos pode ser feito junto com o bônus da campanha, mas as cias têm até o prazo informado no regulamento para creditar a bonificação.',
  },
  {
    question: 'O que acontece se não fizer o cadastro na campanha?',
    answer:
      'O cadastro na campanha é obrigatório. Caso não seja feito, infelizmente o bônus não será creditado nem de imediato e nem no prazo que consta no regulamento. Este é um pré requisito da cia aérea e precisa ser cumprido.',
  },
  {
    question: 'Qual percentual de bônus irei receber?',
    answer:
      'Antes de comprar, é preciso verificar no site da cia aérea qual o percentual de bônus elegível. Geralmente o percentual de bônus tem relação com o tipo de clube e tempo de assinatura ativa. O bônus exibido no Milhas na Conta é a bonificação máxima possível da campanha.',
  },
  {
    question: 'Quais as formas de pagamento e a chave PIX?',
    answer:
      'A única forma de pagamento disponível é através da chave PIX 54264159000142. Os dados da empresa são: RP Travel & Tech e o CNPJ é 542.641.59/0001-42 e é necessário salvar o comprovante para solicitar o crédito na plataforma.',
  },
  {
    question: 'Qual código devo usar para fazer login no Milhas na Conta?',
    answer:
      'Ao clicar no botão “Comprar pontos” nesta página, você será redirecionado para a página de login e o campo “código” será preenchido automaticamente. O código é alterado a cada promoção, mas o preenchimento sempre será automático.',
  },
  {
    question: 'É possível reembolso após crédito dos pontos?',
    answer:
      'O momento do crédito dos pontos é quando existe o “consumo/utilização” do produto, já que é feita a conversão do programa do banco para as milhas TudoAzul. Dessa forma, não é possível solicitar cancelamento, estorno/reembolso ou devolução dos pontos ou milhas após o crédito ser feito no programa da cia aérea.',
  },
  {
    question: 'Como posso tirar dúvidas sobre o Milhas na Conta?',
    answer:
      'Para falar com a equipe do Milhas na Conta, basta clicar no ícone do WhatsApp no canto direito inferior desta página ou enviar mensagem para +55 (31) 9 9576-0123. A resposta pode demorar um pouco nos dias de campanha por conta da alta demanda.',
  },
];

export const FAQ = (): React.ReactElement => {
  const [isOpen, setIsOpen] = React.useState<number | null>(null);

  const handleToggle = (index: number) => {
    setIsOpen((prev) => (prev === index ? null : index));
  };

  return (
    <StyledSection id="perguntas-frequentes">
      <h3 className="section-title">Perguntas Frequentes</h3>

      <StyledList>
        {MOCK.map(({ question, answer }, index) => (
          <StyledListItem
            key={`faq-${index}`}
            isOpen={index === isOpen}
            onClick={() => handleToggle(index)}
          >
            <strong>
              {question}

              <svg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg">
                {isOpen === index ? (
                  <path
                    d="M20.225 11.956H3"
                    stroke="currentColor"
                    strokeWidth="1.2"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                ) : (
                  <path
                    d="M9.62 1v17.24M18.225 9.62H1"
                    stroke="currentColor"
                    strokeWidth="1.2"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                )}
              </svg>
            </strong>
            <p>{answer}</p>
          </StyledListItem>
        ))}
      </StyledList>

      <StyledActionButton onClick={handleGoToTop} noShadow>
        Saber mais
      </StyledActionButton>
    </StyledSection>
  );
};
