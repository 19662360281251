import React, { HTMLInputTypeAttribute } from 'react';
import styled from 'styled-components';

const InputContainer = styled.div`
  margin-bottom: 20px;
  display: flex;
`;

const InputField = styled.input`
  font-family: Rubik, sans-serif;
  border-radius: 4px;
  border: 1px solid #ced4da;
  margin-top: 13px;
  justify-content: center;
  align-items: start;
  color: #878a99;
  white-space: nowrap;
  padding: 13px 60px 13px 15px;
  @media (max-width: 991px) {
    white-space: initial;
    max-width: 100%;
    padding-right: 20px;
  }
  background-color: rgba(121, 204, 114, 0.22);
  width: 100%;
  font-size: 14px;
`;

interface SimpleInputProps {
  placeholder?: string;
  value?: string;
  onChange?: (event?: any) => void;
  name?: string;
  type?: HTMLInputTypeAttribute;
}

const SimpleInput = ({
  placeholder,
  value,
  onChange,
  name,
  type = 'text',
}: SimpleInputProps): any => {
  return (
    <InputContainer>
      <InputField
        type={type}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        name={name}
      />
    </InputContainer>
  );
};

export default SimpleInput;
