import React from 'react';
import styled from 'styled-components';

export const StyledWhatsAppButton = styled.a`
  position: fixed;
  bottom: 24px;
  right: 24px;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  background-color: #00e857;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s;

  &:hover {
    background-color: var(--color-primary-dark);
  }

  svg {
    width: 40px;
    height: 40px;
  }
`;

export const WhatsAppButton = (): React.ReactElement => {
  return (
    <StyledWhatsAppButton
      href="https://wa.me/5531995760123?text=Ol%C3%A1%2C%20tudo%20bem%3F%0AGostaria%20de%20tirar%20d%C3%BAvida(s)%20sobre%20a%20plataforma%20Milhas%20na%20Conta.%0A%3A)"
      target="_blank"
      rel="noopener noreferrer"
    >
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M39.1512 19.0718C39.1512 29.6032 30.5478 38.1436 19.9341 38.1436C16.5625 38.1436 13.3943 37.2813 10.6428 35.7699L0 39.1512L3.46848 28.9201C1.71971 26.0475 0.712103 22.6759 0.712103 19.0718C0.712103 8.5404 9.31548 0 19.9341 0C30.5527 0 39.1512 8.5404 39.1512 19.0718ZM19.9341 3.03734C11.0207 3.03734 3.77367 10.231 3.77367 19.0718C3.77367 22.579 4.91691 25.8295 6.84976 28.4745L4.82971 34.428L11.04 32.4564C13.593 34.1277 16.6448 35.1062 19.9292 35.1062C28.8378 35.1062 36.0848 27.9125 36.0848 19.0718C36.0848 10.231 28.8378 3.03734 19.9292 3.03734H19.9341ZM29.6371 23.4655C29.516 23.2718 29.206 23.1555 28.7361 22.9181C28.2662 22.6856 25.9458 21.552 25.5146 21.397C25.0835 21.242 24.7686 21.1645 24.4538 21.6296C24.1389 22.0994 23.2378 23.1506 22.9617 23.4607C22.6856 23.7756 22.4143 23.8095 21.9444 23.5769C21.4745 23.3444 19.9534 22.8503 18.1562 21.2565C16.7562 20.0164 15.8116 18.4856 15.5355 18.0206C15.2642 17.5555 15.5064 17.2988 15.7438 17.0663C15.9569 16.858 16.2137 16.5189 16.4511 16.2476C16.6884 15.9715 16.7659 15.7777 16.9209 15.4677C17.076 15.1576 16.9985 14.8815 16.8822 14.649C16.7659 14.4165 15.8213 12.1155 15.4289 11.1805C15.0365 10.2456 14.6442 10.2795 14.368 10.2795C14.0919 10.2795 13.5833 10.3618 13.5833 10.3618C13.5833 10.3618 12.6386 10.4781 12.2075 10.948C11.7764 11.413 10.5605 12.5466 10.5605 14.8476C10.5605 17.1486 12.2463 19.3721 12.4836 19.6822C12.721 19.997 15.7438 24.8704 20.5299 26.7402C25.3209 28.6101 25.3209 27.9852 26.1831 27.9077C27.0454 27.8302 28.9686 26.779 29.361 25.6842C29.7534 24.5894 29.7534 23.6544 29.6371 23.4607V23.4655Z"
          fill="white"
        />
      </svg>
    </StyledWhatsAppButton>
  );
};
