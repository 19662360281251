export const formatMoney = (price: string | number): string => {
  return `${Number(price).toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  })}`;
};

export const removeMaskMoney = (price: string): string => {
  const value = price
    .replaceAll('.', '')
    .replaceAll(',', '')
    .replaceAll('R$', '')
    .replaceAll(' ', '');

  return value;
};
