import * as React from 'react';
import { CircularProgress, Modal, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import {
  Div9,
  FormLoginStyled,
  StyledBox,
  StyledContentButtons,
} from './styles';
import SimpleInput from '../../Login/Input';
import SubmitButton, { Button } from '../../../components/Button';
import SimpleSelect from '../../Login/Select';
import { maskCpf } from '../../../utils/mask';
import { cpfMask, removeCpfMask } from '../../../utils/cpf';

interface Props {
  open: boolean;
  title: string;
  isLoading: boolean;
  defaultValues?: {
    name: string;
    cpf: string;
    role: string;
    status: string;
    email: string;
  };
  handleClose: () => void;
  handleSubmit: (values: any) => void;
}

const ModalEditUser = ({
  open,
  title,
  isLoading,
  defaultValues,
  handleClose,
  handleSubmit,
}: Props): React.ReactElement => {
  const [values, setValues] = React.useState({
    name: '',
    cpf: '',
    role: 'user',
    status: 'active',
    email: '',
  });

  React.useEffect(() => {
    if (!open) {
      setValues({
        name: '',
        cpf: '',
        role: 'user',
        status: 'active',
        email: '',
      });
    }
  }, [open]);

  React.useEffect(() => {
    if (defaultValues) {
      setValues({ ...defaultValues, cpf: maskCpf(defaultValues.cpf) });
    }
  }, [defaultValues]);

  const handleChange = (event: any): void => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeCpf = (event: any): void => {
    setValues({
      ...values,
      cpf: cpfMask(event.target.value),
    });
  };

  const submit = (e: any): void => {
    e.preventDefault();

    if (
      !values.name ||
      !values.cpf ||
      !values.status ||
      !values.role ||
      !values.email
    ) {
      toast('Por favor, preencha os campos antes de submeter o formulário.', {
        type: 'error',
      });
      return;
    }

    const cpf = removeCpfMask(values.cpf);

    if (cpf.length !== 11) {
      toast('Por favor, verifique o campo CPF.', {
        type: 'error',
      });
      return;
    }

    handleSubmit({ ...values, cpf });
  };

  return (
    <Modal open={open}>
      <StyledBox>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {title}
        </Typography>
        <FormLoginStyled>
          <Div9>Nome</Div9>
          <SimpleInput
            placeholder={'Nome'}
            name="name"
            onChange={handleChange}
            value={values.name}
          />

          <Div9>E-mail</Div9>
          <SimpleInput
            placeholder={'E-mail'}
            name="email"
            type="email"
            onChange={handleChange}
            value={values.email}
          />

          <Div9>CPF</Div9>
          <SimpleInput
            placeholder={'CPF'}
            name="cpf"
            onChange={handleChangeCpf}
            value={values.cpf}
          />

          <Div9>Permissão</Div9>
          <SimpleSelect
            name="role"
            value={values.role}
            onChange={handleChange}
            options={[
              { value: 'user', label: 'Cliente' },
              { value: 'partner', label: 'Parceiro' },
              { value: 'admin', label: 'Administrador' },
            ]}
          />

          <Div9>Status</Div9>
          <SimpleSelect
            name="status"
            value={values.status}
            onChange={handleChange}
            options={[
              { value: 'active', label: 'Ativo' },
              { value: 'inactive', label: 'Inativo' },
            ]}
          />

          <StyledContentButtons>
            <div style={{ width: '45%' }}>
              <Button onClick={handleClose} disabled={isLoading}>
                {isLoading ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <span>Cancelar</span>
                )}
              </Button>
            </div>
            <div style={{ width: '45%' }}>
              <SubmitButton onClick={submit} disabled={isLoading}>
                {isLoading ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <span>Salvar</span>
                )}
              </SubmitButton>
            </div>
          </StyledContentButtons>
        </FormLoginStyled>
      </StyledBox>
    </Modal>
  );
};

export default ModalEditUser;
