import React from 'react';
import styled from 'styled-components';

const StyledLogo = styled.img`
  width: 100%;
  max-width: 260px;
  pointer-events: none;
`;

export const Logo = ({
  isLarge,
}: {
  isLarge?: boolean;
}): React.ReactElement => (
  <StyledLogo
    src={`/images/landing-page/logo-milhas-na-conta${
      isLarge ? '.large' : ''
    }.png`}
    alt="Milhas Na Conta"
  />
);
