import React, { ReactElement } from 'react';

import { FormControlLabel, Switch } from '@mui/material';

import {
  StyledFormGroup,
  StyledItem,
  StyledLabelSwitch,
  StyledNotFound,
  StyledRoot,
  StyledTitle,
} from './styles';

import SimpleInput from '../../pages/Login/Input';

interface Config {
  images: {
    mobile: string;
    desktop: string;
  };
  callToAction: {
    text: string;
    url: string;
  };
  rules: {
    url: string;
  };
  date: string;
}

interface Values {
  hero: Config[];
  campaignEnabled: boolean;
}

interface Props {
  data: Values;
}

const SettingsLandingPage = ({ data }: Props): ReactElement => {
  return (
    <StyledRoot>
      <StyledTitle>Possuí campanha habilitada</StyledTitle>
      <StyledFormGroup>
        <FormControlLabel
          control={<Switch checked={data?.campaignEnabled} disabled />}
          label={
            <StyledLabelSwitch>
              {data?.campaignEnabled
                ? 'Possuí campanha habilitada'
                : 'Nenhuma campanha habilitada'}
            </StyledLabelSwitch>
          }
        />
      </StyledFormGroup>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: 16,
        }}
      >
        <StyledTitle>Campanhas</StyledTitle>
      </div>

      {!data?.hero?.length && (
        <StyledNotFound>Nenhuma campanha adicionada ainda!</StyledNotFound>
      )}

      {data?.hero.map((item, index) => (
        <div
          key={index}
          style={{
            border: '1px solid #ccc',
            borderRadius: 8,
            padding: 32,
            marginBottom: 16,
          }}
        >
          <StyledTitle>Imagem mobile</StyledTitle>
          <StyledItem>
            {!item?.images?.mobile && (
              <React.Fragment>
                <StyledNotFound>
                  Nenhuma imagem adicionada ainda!
                </StyledNotFound>
              </React.Fragment>
            )}

            <div
              style={{
                display: 'flex',
                overflowX: 'auto',
                gap: 16,
                padding: 16,
                maxWidth: '100%',
              }}
            >
              {!!item?.images?.mobile && (
                <div style={{ width: 200, position: 'relative' }}>
                  <img src={`${item?.images?.mobile}`} style={{ width: 200 }} />
                </div>
              )}
            </div>
          </StyledItem>

          <StyledTitle>Imagem desktop</StyledTitle>
          <StyledItem>
            {!item?.images?.desktop && (
              <React.Fragment>
                <StyledNotFound>
                  Nenhuma imagem adicionada ainda!
                </StyledNotFound>
              </React.Fragment>
            )}

            <div
              style={{
                display: 'flex',
                overflowX: 'auto',
                gap: 16,
                padding: 16,
                maxWidth: '100%',
              }}
            >
              {!!item?.images?.desktop && (
                <div style={{ width: 200, position: 'relative' }}>
                  <img
                    src={`${item?.images?.desktop}`}
                    style={{ width: 200 }}
                  />
                </div>
              )}
            </div>
          </StyledItem>

          <StyledTitle>Data da campanha</StyledTitle>
          <SimpleInput
            placeholder="Data da campanha"
            name="date"
            disabled
            value={item.date}
          />

          <StyledTitle>URL destino</StyledTitle>
          <SimpleInput
            placeholder="URL destino"
            name="url"
            disabled
            value={item.callToAction.url}
          />

          <StyledTitle>URL regulamento</StyledTitle>
          <SimpleInput
            placeholder="URL regulamento"
            name="url"
            disabled
            value={item.rules.url}
          />
        </div>
      ))}
    </StyledRoot>
  );
};

export default SettingsLandingPage;
