import * as React from 'react';
import { toast } from 'react-toastify';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ClearIcon from '@mui/icons-material/Clear';
import DownloadIcon from '@mui/icons-material/Download';
import HomeIcon from '@mui/icons-material/Home';

import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  IconButton,
} from '@mui/material';
import Lottie from 'react-lottie';
import { useNavigate } from 'react-router-dom';
import { createRequest, preCreateRequest, uploadFile } from '../../api/request';

import AttachmentButton from '../../components/AttachmentButton';
import {
  Div,
  Div10,
  Div11,
  Div12,
  Div13,
  Div18,
  Div19,
  Div2,
  Div20,
  Div25,
  Div28,
  Div32,
  Div37,
  Div38,
  Div39,
  Div8,
  Div9,
  GreenSquare,
  Img3,
  Img4,
  StyledButtonBack,
  StyledButtonDonwload,
  StyledButtonDownloadIcon,
  StyledContentButtons,
  StyledContentCheckbox,
  StyledContentFiles,
  StyledContentFilesItem,
  StyledContentHeaderCard,
  StyledLink,
  StyledSpanCheckBox,
  StyledStepContentContainer,
  StyledStepItem,
  StyledStepItemContainer,
  StyledStepsContainer,
} from './styles';

import ModalErrorPermissionRequest from '../../components/ModalErrorPermissionRequest';
import ModalErrorUploadFiles from '../../components/ModalErrorUploadFiles';
import NewLoggedHeader from '../../components/NewLoggedHeader';
import config from '../../config/config';
import useAuthenticate from '../../recoil/hooks/authenticate';
import { formatDate } from '../../utils/date';
import { formatMoney } from '../../utils/money';
import ModalNews from './ModalNews';
import { formatNumber } from '../../utils/number';
import animationData from './success.json';
import { submitNewsletter } from '../../api/newsletter';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

interface Step {
  step: string;
  label: string;
  onClick: () => void;
}

interface AttachmentFile {
  id: string;
  name: string;
}

interface SuccessRequest {
  id: string;
  date: Date;
}

const NewRequest = (): React.ReactElement => {
  const navigate = useNavigate();

  const [modalError, setModalError] = React.useState({
    open: false,
    title: '',
    cause: [],
  });

  const [modalPermission, setModalPermission] = React.useState(false);

  const [modalNews, setModalNews] = React.useState(false);

  const [acceptTerms, setAcceptTerms] = React.useState(false);

  const { authenticate, updateUserNewsletter } = useAuthenticate();

  const [isLoading, setIsLoading] = React.useState(false);

  const [paymentFiles, setPaymentsFiles] = React.useState<AttachmentFile[]>([]);
  const [accountFiles, setAccountFiles] = React.useState<AttachmentFile[]>([]);

  const [successData, setSuccessData] = React.useState<SuccessRequest | null>(
    null
  );

  const [resumeData, setResumeData] = React.useState({
    averageMilesPerCpf: 0,
    biggestValue: 0,
    totalCpfs: 0,
    totalMiles: 0,
    totalPaymentFiles: 0,
    totalValue: 0,
  });

  React.useEffect(() => {
    if (['admin', 'partner'].includes(authenticate?.user?.role)) {
      setModalPermission(true);
    }
  }, [authenticate?.user?.role]);

  const [stepSelected, setStepSelected] = React.useState(0);

  const steps: Step[] = [
    { step: 'payment', label: 'Pagamento', onClick: () => setStepSelected(0) },
    { step: 'miles', label: 'Planilhas', onClick: () => setStepSelected(1) },
    {
      step: 'resume',
      label: 'Resumo',
      onClick: () => setStepSelected(2),
    },
    {
      step: 'confirmation',
      label: 'Confirmação',
      onClick: () => setStepSelected(3),
    },
  ];

  const handleSubmitPayments = (): void => {
    if (!paymentFiles.length) {
      toast('Por favor, anexe os comprovantes de pagamento.', {
        type: 'error',
      });
    } else {
      setStepSelected(1);
    }
  };

  const handleSubmitAccounts = async (): Promise<void> => {
    if (!accountFiles.length) {
      toast('Por favor, anexe os arquivos com as contas.', {
        type: 'error',
      });
      return;
    }

    if (!acceptTerms) {
      toast('Por favor, aceite os termos.', {
        type: 'error',
      });
      return;
    }

    try {
      setIsLoading(true);
      const data = {
        payments: paymentFiles.map((item) => item.id),
        accounts: accountFiles.map((item) => item.id),
      };

      const result = await preCreateRequest(
        data,
        authenticate.token,
        authenticate.user.code
      );

      setResumeData(result.data);

      setStepSelected(2);
    } catch (error) {
      toast(
        'Não foi possível realizar sua solicitação. Por favor, tente novamente!',
        {
          type: 'error',
        }
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmitResume = async (): Promise<void> => {
    if (!accountFiles.length) {
      toast('Por favor, anexe os arquivos com as contas.', {
        type: 'error',
      });
      return;
    }

    if (!acceptTerms) {
      toast('Por favor, aceite os termos.', {
        type: 'error',
      });
      return;
    }

    try {
      setIsLoading(true);
      const data = {
        payments: paymentFiles.map((item) => item.id),
        accounts: accountFiles.map((item) => item.id),
      };

      const result = await createRequest(
        data,
        authenticate.token,
        authenticate.user.code
      );

      setSuccessData(result.data);

      setStepSelected(3);

      if (!authenticate?.user?.isSubscribedNewsletter) {
        setTimeout(() => {
          setModalNews(true);
        }, 1500);
      }
    } catch (error) {
      toast(
        'Não foi possível realizar sua solicitação. Por favor, tente novamente!',
        {
          type: 'error',
        }
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleFinishRequest = (): void => {
    navigate('/home');
  };

  const handleUploadPaymentFile = async (files: FileList): Promise<void> => {
    try {
      setIsLoading(true);

      const result = await uploadFile(files, 'payment', authenticate.token);

      setPaymentsFiles([...paymentFiles, ...result.data]);
    } catch (error) {
      toast(
        'Não foi possível fazer o upload do arquivo. Por favor, tente novamente!',
        {
          type: 'error',
        }
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleUploadAccountFile = async (files: FileList): Promise<void> => {
    try {
      setIsLoading(true);
      const result = await uploadFile(files, 'account', authenticate.token);

      setAccountFiles([...accountFiles, ...result.data]);
    } catch (error: any) {
      setModalError({
        open: true,
        title:
          error?.response?.data?.message ||
          'Não foi possível fazer o upload do arquivo. Por favor, tente novamente!',
        cause: error?.response?.data?.cause || [],
      });
      // toast(
      //   error?.response?.data?.message ||
      //     'Não foi possível fazer o upload do arquivo. Por favor, tente novamente!',
      //   {
      //     type: 'error',
      //   }
      // );
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelectFile =
    (step: string) =>
    (files: FileList): void => {
      if (step === 'payment') {
        handleUploadPaymentFile(files);
      }

      if (step === 'account') {
        handleUploadAccountFile(files);
      }
    };

  const removePaymentItem = (id: string): void => {
    setPaymentsFiles(paymentFiles.filter((item) => item.id !== id));
  };

  const removeAccountItem = (id: string): void => {
    setAccountFiles(accountFiles.filter((item) => item.id !== id));
  };

  const handleDownloadSheet = () => {
    window.open(
      `${config.urlServer}/assets/modelo-Milhas-na-Conta.xlsx`,
      '_blank'
    );
  };

  const handleSubmitNewsletter = async (values: any): Promise<void> => {
    submitNewsletter(values, authenticate.token);

    toast('Inscrição realizada com sucesso!', {
      type: 'success',
    });

    setModalNews(false);
    updateUserNewsletter();
  };

  const makeUrlConfirm = React.useMemo((): React.ReactNode => {
    if (authenticate.user?.codeUrl && authenticate.user?.codeName) {
      return (
        <StyledSpanCheckBox>
          Confirmo que me inscrevi na promoção{' '}
          <b>{authenticate.user.codeName}</b> e estou ciente das{' '}
          <StyledLink
            href={authenticate.user.codeUrl}
            target="_blank"
            rel="noreferrer"
          >
            regras disponíveis
          </StyledLink>
        </StyledSpanCheckBox>
      );
    }

    if (authenticate.user?.codeName) {
      return (
        <StyledSpanCheckBox>
          Confirmo que me inscrevi na promoção{' '}
          <b>{authenticate.user.codeName}</b>
        </StyledSpanCheckBox>
      );
    }

    return (
      <span>
        Confirmo que estou ciente da solicitação e da veracidade das informações
        enviadas!
      </span>
    );
  }, [authenticate]);

  return (
    <Div>
      <ModalErrorUploadFiles
        open={modalError.open}
        message={modalError.title}
        cause={modalError.cause}
        handleClose={() => setModalError({ ...modalError, open: false })}
      />

      <ModalNews
        open={modalNews}
        handleClose={() => setModalNews(false)}
        handleSubmit={handleSubmitNewsletter}
        isLoading={false}
      />

      <ModalErrorPermissionRequest open={modalPermission} />
      <Div2>
        {/* <LoggedHeader /> */}
        <NewLoggedHeader />

        <Div8>
          <Div9>Crédito de milhas</Div9>
          <Div10>
            Inclua o comprovante de pagamento, Informe a quantidade de pontos da
            compra e envie para aprovação.{' '}
          </Div10>
        </Div8>
        <Div11>
          <Div12>
            <StyledContentHeaderCard>
              <Div13>Siga os passos para solicitar o crédito de milhas</Div13>
              {stepSelected === 1 && (
                <StyledButtonDonwload onClick={handleDownloadSheet}>
                  <Div38>Baixar planilha</Div38>
                  <StyledButtonDownloadIcon>
                    <DownloadIcon htmlColor="#79cc72" />
                  </StyledButtonDownloadIcon>
                </StyledButtonDonwload>
              )}
            </StyledContentHeaderCard>

            <StyledStepsContainer>
              <StyledStepItemContainer>
                {steps?.map(({ step, label }, index) => {
                  const isSelected = index === stepSelected;
                  return (
                    <StyledStepItem
                      key={step}
                      selected={String(isSelected)}
                      // onClick={onClick}
                    >
                      {isSelected ? (
                        <Img3
                          loading="lazy"
                          src="https://cdn.builder.io/api/v1/image/assets/TEMP/6a865ddd290f7dcdf95cfa3c9688e74ce5cf2480acc744066c984529e641018a?"
                        />
                      ) : (
                        <Img4
                          loading="lazy"
                          src="https://cdn.builder.io/api/v1/image/assets/TEMP/d746c7dc86f492df326d2db468bee968c64b3c116337e3fe8b2d9f3816e761e3?"
                        />
                      )}
                      <strong>Passo {index + 1}</strong> {label}
                    </StyledStepItem>
                  );
                })}
              </StyledStepItemContainer>

              <StyledStepContentContainer>
                {stepSelected === 0 && (
                  <React.Fragment>
                    <Div18>
                      <Div19>Anexar um ou mais comprovantes de pagamento</Div19>
                      <Div20>
                        Anexe um ou mais comprovantes de pagamento de até 10Mb
                        por arquivo.
                      </Div20>
                    </Div18>
                    <Div25>
                      <AttachmentButton
                        label="Anexar comprovante*"
                        disabled={isLoading}
                        handleChange={handleSelectFile('payment')}
                        acceptTypes="image/*,.pdf"
                        multiple
                      />
                      {paymentFiles?.length ? (
                        <Div28>
                          {paymentFiles?.length > 1
                            ? `${paymentFiles.length} arquivos adicionados`
                            : '1 arquivo adicionado'}
                        </Div28>
                      ) : (
                        <Div28>Nenhum arquivo adicionado</Div28>
                      )}
                      <StyledContentFiles>
                        {paymentFiles.map((file) => (
                          <StyledContentFilesItem key={file.id}>
                            <div>{file.name}</div>
                            <IconButton
                              onClick={() => removePaymentItem(file.id)}
                            >
                              <ClearIcon fontSize="small" color="error" />
                            </IconButton>
                          </StyledContentFilesItem>
                        ))}
                      </StyledContentFiles>
                    </Div25>
                    <GreenSquare>
                      RP Travel & Tech • Chave Pix: 54.264.159/0001-42 (cnpj)
                      {/* <img
                        style={{
                          position: 'absolute',
                          right: 0,
                          top: 0,
                          height: 48,
                        }}
                        src="/images/pix.png"
                      /> */}
                    </GreenSquare>
                    <Div32>
                      <Div37
                        onClick={() => !isLoading && handleSubmitPayments()}
                      >
                        <Div38>Próximo passo</Div38>
                        {isLoading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : (
                          <ArrowForwardIcon />
                        )}
                      </Div37>
                    </Div32>
                  </React.Fragment>
                )}
                {stepSelected === 1 && (
                  <React.Fragment>
                    <Div18>
                      <Div19>
                        Anexar arquivo com as contas para crédito de milhas
                      </Div19>
                      <Div20>
                        Anexe um ou mais arquivos xlsx com as contas do
                        fidelidade para crédito das milhas
                      </Div20>
                    </Div18>
                    <Div25>
                      <AttachmentButton
                        label="Anexar arquivo xlsx*"
                        disabled={isLoading}
                        handleChange={handleSelectFile('account')}
                        acceptTypes=".xlsx"
                      />
                      {accountFiles?.length ? (
                        <Div28>
                          {accountFiles?.length > 1
                            ? `${accountFiles.length} arquivos adicionados`
                            : '1 arquivo adicionado'}
                        </Div28>
                      ) : (
                        <Div28>Nenhum arquivo adicionado</Div28>
                      )}
                      <StyledContentFiles>
                        {accountFiles.map((file) => (
                          <StyledContentFilesItem key={file.id}>
                            <div>{file.name}</div>
                            <IconButton
                              onClick={() => removeAccountItem(file.id)}
                            >
                              <ClearIcon fontSize="small" color="error" />
                            </IconButton>
                          </StyledContentFilesItem>
                        ))}
                      </StyledContentFiles>

                      <StyledContentCheckbox>
                        <FormGroup>
                          <FormControlLabel
                            required
                            control={
                              <Checkbox
                                value={acceptTerms}
                                defaultChecked={acceptTerms}
                                onChange={(e) =>
                                  setAcceptTerms(e.target.checked)
                                }
                              />
                            }
                            label={makeUrlConfirm}
                          />
                        </FormGroup>
                      </StyledContentCheckbox>
                    </Div25>
                    <StyledContentButtons>
                      <StyledButtonBack onClick={() => setStepSelected(0)}>
                        <Div38>Voltar</Div38>
                      </StyledButtonBack>
                      <Div37
                        onClick={() => !isLoading && handleSubmitAccounts()}
                      >
                        <Div38>Próximo passo</Div38>

                        {isLoading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : (
                          <ArrowForwardIcon />
                        )}
                      </Div37>
                    </StyledContentButtons>
                  </React.Fragment>
                )}
                {stepSelected === 2 && (
                  <React.Fragment>
                    <Div18>
                      <Div19>Resumo da sua solicitação</Div19>
                    </Div18>
                    <Div25>
                      <Div28>
                        <b>Total de milhas:</b>{' '}
                        {formatNumber(resumeData?.totalMiles)} milhas
                      </Div28>
                      <Div28>
                        <b>Qtd. CPFs:</b> {resumeData?.totalCpfs} contas
                        distintas
                      </Div28>
                      <Div28>
                        <b>Média de milhas:</b>{' '}
                        {formatNumber(resumeData?.averageMilesPerCpf)} milhas
                        por CPF
                      </Div28>
                      <Div28>
                        <b>Maior crédito:</b>{' '}
                        {formatNumber(resumeData?.biggestValue)} milhas para um
                        CPF
                      </Div28>
                      <Div28>
                        <b>Qtd. comprovante:</b> {resumeData?.totalPaymentFiles}{' '}
                        {resumeData?.totalPaymentFiles === 1
                          ? 'comprovante'
                          : 'comprovantes'}
                      </Div28>
                      <Div28>
                        <b>Valor:</b> {formatMoney(resumeData?.totalValue)}{' '}
                        (confira os comprovantes)
                      </Div28>
                    </Div25>
                    <GreenSquare>
                      Solicitações até meio-dia serão creditadas entre 16h e 23h
                      do mesmo dia.
                    </GreenSquare>
                    <StyledContentButtons>
                      <StyledButtonBack onClick={() => setStepSelected(1)}>
                        <Div38>Voltar</Div38>
                      </StyledButtonBack>
                      <Div37 onClick={() => handleSubmitResume()}>
                        <Div38>Enviar solicitação</Div38>
                        {isLoading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : (
                          <ArrowForwardIcon />
                        )}
                      </Div37>
                    </StyledContentButtons>
                  </React.Fragment>
                )}
                {stepSelected === 3 && (
                  <React.Fragment>
                    <Div18>
                      <Div19>Solicitação concluída com sucesso!</Div19>
                    </Div18>
                    <div>
                      <Lottie
                        options={defaultOptions}
                        height={150}
                        width={150}
                      />
                    </div>
                    <Div25>
                      <Div28>
                        <b>ID da solicitação:</b> {successData?.id}
                      </Div28>
                      <Div28>
                        <b>Finalizada em:</b> {formatDate(successData?.date)}
                      </Div28>
                    </Div25>
                    <Div32>
                      <Div37 onClick={() => handleFinishRequest()}>
                        <Div38>Página inicial</Div38>
                        <HomeIcon />
                      </Div37>
                    </Div32>
                  </React.Fragment>
                )}
              </StyledStepContentContainer>
            </StyledStepsContainer>
          </Div12>
          <Div39>Entrar</Div39>
        </Div11>
      </Div2>
    </Div>
  );
};

export default NewRequest;
