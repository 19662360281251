import * as React from 'react';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import { CircularProgress } from '@mui/material';

import {
  Div18,
  Div19,
  Div25,
  Div28,
  Div37,
  Div38,
  StyledButtonBack,
  StyledContentButtons,
  GreenSquare,
} from './styles';

import { formatNumber } from '../../../utils/number';
import { formatMoney } from '../../../utils/money';

interface Props {
  resumeData: any;
  isLoading: boolean;
  handleSubmitResume: () => void;
  goBack: () => void;
}

const StepTwoRequest: React.FC<Props> = ({
  resumeData,
  isLoading,
  handleSubmitResume,
  goBack,
}): React.ReactElement => {
  return (
    <React.Fragment>
      <Div18>
        <Div19>Resumo da sua solicitação</Div19>
      </Div18>
      <Div25>
        <Div28>
          <div>Total de milhas:</div> {formatNumber(resumeData?.totalMiles)}{' '}
          milhas
        </Div28>
        <Div28>
          <div>Qtd. CPFs:</div> {resumeData?.totalCpfs} contas distintas
        </Div28>
        <Div28>
          <div>Média de milhas:</div>{' '}
          {formatNumber(resumeData?.averageMilesPerCpf)} milhas por CPF
        </Div28>
        <Div28>
          <div>Maior crédito:</div> {formatNumber(resumeData?.biggestValue)}{' '}
          milhas para um CPF
        </Div28>
        {/* <Div28>
          <div>Qtd. comprovante:</div> {resumeData?.totalPaymentFiles}{' '}
          {resumeData?.totalPaymentFiles === 1 ? 'comprovante' : 'comprovantes'}
        </Div28> */}
        <Div28>
          <div>Valor total:</div> {formatMoney(resumeData?.totalValue)}
        </Div28>
        <Div28>
          <div>Valor médio por 1.000 pontos:</div>{' '}
          {formatMoney(
            (resumeData?.totalValue / resumeData?.totalMiles) * 1000
          )}
        </Div28>
      </Div25>
      <GreenSquare>
        Solicitações até meio-dia serão creditadas entre 16h e 23h do mesmo dia.
      </GreenSquare>
      <StyledContentButtons>
        <StyledButtonBack onClick={() => goBack()}>
          <Div38>Voltar</Div38>
        </StyledButtonBack>
        <Div37 onClick={() => handleSubmitResume()}>
          <Div38>Próximo</Div38>
          {isLoading ? (
            <CircularProgress color="inherit" size={20} />
          ) : (
            <ArrowForwardIcon />
          )}
        </Div37>
      </StyledContentButtons>
    </React.Fragment>
  );
};

export default StepTwoRequest;
