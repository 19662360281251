import axios from 'axios';

import { setRecoil } from 'recoil-nexus';

import config from '../config/config';
import authenticateAtom from '../recoil/atoms/authenticate';

const instance = axios.create({
  baseURL: config.urlServer,
  timeout: 360000,
  headers: { 'Content-Type': 'application/json' },
});

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      setRecoil(authenticateAtom, {
        isAuthenticate: false,
        token: '',
        user: null,
      });

      if (!['#/', '#/partner'].includes(window.location.hash)) {
        window.location.hash = '/';
      }
    }

    return Promise.reject(error);
  }
);

export default instance;
