import React from 'react';
import { handleGoToSection } from '../../utils';
import { Logo } from '../Logo';
import {
  StyledMenu,
  StyledMenuCloseButton,
  StyledMenuItem,
  StyledMenuList,
} from './styles';

interface MenuProps {
  isMenuOpen: boolean;
  handleCloseMenu: () => void;
}

export const Menu = ({
  isMenuOpen,
  handleCloseMenu,
}: MenuProps): React.ReactElement => {
  const MenuItem = ({
    href,
    children,
    isSmall,
    onClick,
  }: React.AnchorHTMLAttributes<HTMLAnchorElement> & {
    isSmall?: boolean;
    onClick?: React.MouseEventHandler<HTMLLIElement>;
  }) => (
    <StyledMenuItem isSmall={isSmall} onClick={onClick}>
      {href ? <a href={href}>{children}</a> : <span>{children}</span>}
    </StyledMenuItem>
  );

  const handleClick = (id: string) => {
    handleGoToSection(id);
    handleCloseMenu();
  };

  return (
    <StyledMenu isOpen={isMenuOpen}>
      <Logo />

      <StyledMenuList>
        <MenuItem onClick={() => handleClick('home')}>Home</MenuItem>
        <MenuItem onClick={() => handleClick('recomendacoes')}>
          Recomendações
        </MenuItem>
        <MenuItem onClick={() => handleClick('como-funciona')}>
          Como Funciona?
        </MenuItem>
        <MenuItem onClick={() => handleClick('perguntas-frequentes')}>
          Perguntas Frequentes
        </MenuItem>
        <MenuItem onClick={() => handleClick('como-funciona')}>
          Contato
        </MenuItem>
        <br />
        <MenuItem href="/#termos" isSmall>
          Termos e condições
        </MenuItem>
        <MenuItem href="/#politica" isSmall>
          Politica de privacidade
        </MenuItem>
      </StyledMenuList>

      <StyledMenuCloseButton onClick={handleCloseMenu}>
        <svg viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M1.228 23.144 23.143 1.228M1.228 1.28l21.915 21.916"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </StyledMenuCloseButton>
    </StyledMenu>
  );
};
