export enum RequestStatusEnum {
  all = 'all',
  open = 'open',
  pending = 'pending',
  analysing = 'analysing',
  confirmed = 'confirmed',
  completed = 'completed',
}

export const requestStatusLabel = (status: RequestStatusEnum): string => {
  const options = {
    all: 'Todos',
    open: 'Em aberto',
    pending: 'Pendente',
    analysing: 'Em análise',
    confirmed: 'Confirmado',
    completed: 'Concluído',
  };

  return options[status] || options.pending;
};
