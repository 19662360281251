import React, { useMemo } from 'react';
import { Footer } from './components/Footer';
import { Header } from './components/Header';
import { WhatsAppButton } from './components/WhatsAppButton';
import { BannerAction } from './sections/BannerAction';
import { FAQ } from './sections/FAQ';
import { Hero } from './sections/Hero';
import { HowItWorks } from './sections/HowItWorks';
import { WarningList } from './sections/WarningList';
import { StyledLandingPage } from './styles';

import mock from './mock';
import { CampaignSoon } from './sections/CampaignSoon';
import { getLandingPageData } from '../../api/landing-page';
import Loading from '../../components/Loading';
import NotFound from '../NotFound';
import useAuthenticate from '../../recoil/hooks/authenticate';

const LandingPage = (): React.ReactElement => {
  const { authenticate } = useAuthenticate();

  const [data, setData] = React.useState<any>(null);

  const [isLoading, setIsLoading] = React.useState(true);

  const getData = React.useCallback(async () => {
    try {
      setIsLoading(true);
      const data = await getLandingPageData();

      setData(data.data);
    } catch (error) {
      // TODO: error
      setData(mock);
    } finally {
      setIsLoading(false);
    }
  }, []);

  React.useEffect(() => {
    getData();
  }, [getData]);

  const campaignEnabled = useMemo(
    () => data?.campaignEnabled && data?.hero.length > 0,
    [data]
  );

  if (!authenticate.isAuthenticate || !authenticate.showLandingPage) {
    return <NotFound />;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <StyledLandingPage>
      <Header removeMenu={campaignEnabled} />

      {campaignEnabled ? (
        <>
          <Hero items={data?.hero} />
          <WarningList />
          <BannerAction />
          <HowItWorks />
          <FAQ />
        </>
      ) : (
        <CampaignSoon />
      )}

      <Footer />
      <WhatsAppButton />
    </StyledLandingPage>
  );
};

export default LandingPage;
