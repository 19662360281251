import { TextField } from '@mui/material';
import styled from 'styled-components';
import { ActionButton } from '../../components/ActionButton';

export const StyledCampaignSoon = styled.section`
  position: relative;
  background: var(--color-primary);
  line-height: 0;

  @media (max-width: 700px) {
    margin-bottom: 40px;
  }
`;

export const StyledPicture = styled.picture`
  display: block;
  max-width: 1000px;
  margin: 0 auto;
  cursor: pointer;

  img {
    width: 100%;
  }
`;

export const StyledActionButton = styled(ActionButton)`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  z-index: 1;
  display: flex;

  @media (min-width: 700px) {
    display: none;
  }
`;

export const StyledModal = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  max-width: calc(100% - 32px);
  border-radius: 8px;
  background: #fff;
  padding: 24px;
  font-family: 'Roboto', sans-serif;
  outline: none;
`;

export const StyledModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;

  > svg {
    width: 60px;
  }
`;

export const StyledButtonClose = styled.button`
  appearance: none;
  border: none;
  background: none;
  cursor: pointer;
  line-height: 0;

  svg {
    width: 25px;
    height: 24px;
    fill: #b9babe;
  }
`;

export const StyledModalTitle = styled.h3`
  text-transform: uppercase;
  text-align: center;
  font-size: 30px;
  color: #044330;
  margin-bottom: 24px;
`;

export const StyledModalDescription = styled.p`
  color: #707070;
  text-align: center;
  max-width: 400px;
  margin: 0 auto;
`;

export const StyledModalForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 32px 0;
  max-width: 400px;
  margin: 0 auto;
`;

export const StyledInput = styled(TextField)`
  .MuiFormLabel-root {
    color: #044330;
    font-weight: 500;
  }

  .MuiInputBase-root {
    border-radius: 10px;
  }

  .MuiFormHelperText-root {
    color: #b9babe;
  }
`;

export const StyledButton = styled.button`
  appearance: none;
  border: none;
  border-radius: 30px;
  padding: 16px 32px;
  background: #044330;
  color: #fff;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  margin: 24px auto 0;
  width: 100%;
  max-width: 300px;
`;
