import styled from 'styled-components';

export const StyledMenu = styled.nav<{ isOpen: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 60px;

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--color-white);
  z-index: 10;

  ${({ isOpen }) => `
    transform: translateX(${isOpen ? '0' : '100%'});
    transition: transform 0.3s;
  `}
`;

export const StyledMenuList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
`;

export const StyledMenuItem = styled.li<{ isSmall?: boolean }>`
  list-style: none;

  a:hover,
  span:hover {
    color: var(--color-primary-dark);
  }

  ${({ isSmall }) => `
    a, span {
      font-size: ${isSmall ? '20' : '24px'};
      font-weight: ${isSmall ? '400' : '500'};
      color: var(${isSmall ? '--color-gray-light' : '--color-gray'});
      cursor: pointer;
    }
  `}
`;

export const StyledMenuCloseButton = styled.button`
  appearance: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 74px;
  height: 74px;
  background: var(--color-primary);
  color: var(--color-white);
  border-radius: 50%;
  cursor: pointer;

  svg {
    width: 24px;
  }
`;
