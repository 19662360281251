import React, { ReactElement } from 'react';
import { StyledRoot } from './styles';

const NotFound: React.FC = (): ReactElement => {
  return (
    <StyledRoot>
      <div>Página não encontrada</div>
    </StyledRoot>
  );
};

export default NotFound;
