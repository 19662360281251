import React from 'react';

export const PaymentFigure = (): React.ReactElement => (
  <svg xmlns="http://www.w3.org/2000/svg" width={234} height={168} fill="none">
    <path
      fill="#081906"
      d="M81.335 32.672a.253.253 0 0 1-.251-.254V20.756H42.136a.253.253 0 0 1-.252-.254.25.25 0 0 1 .252-.253h39.198c.138 0 .252.112.252.253v11.916a.253.253 0 0 1-.252.254Z"
    />
    <path
      fill="#D6EF89"
      d="M33.082 110.699a2.696 2.696 0 0 0-2.683-2.71c-1.483-.002-2.687 1.209-2.69 2.704a2.696 2.696 0 0 0 2.683 2.71 2.697 2.697 0 0 0 2.69-2.704Zm9.046 0a2.696 2.696 0 0 0-2.682-2.71 2.698 2.698 0 0 0-2.69 2.704 2.696 2.696 0 0 0 2.683 2.71 2.697 2.697 0 0 0 2.69-2.704Z"
    />
    <path
      fill="#7EC41F"
      d="M51.172 110.703c.002-1.495-1.2-2.709-2.683-2.71a2.696 2.696 0 0 0-2.689 2.703c-.002 1.495 1.2 2.709 2.683 2.71a2.696 2.696 0 0 0 2.689-2.703Z"
    />
    <path
      fill="#081906"
      d="M119.356 75.507c0-.138.112-.254.252-.254h17.09c.138 0 .252.112.252.254a.25.25 0 0 1-.252.254h-17.09a.253.253 0 0 1-.252-.254Z"
    />
    <path
      fill="#081906"
      d="M136.695 75.253c.137 0 .252.112.252.254v7.333a.254.254 0 0 1-.252.254.25.25 0 0 1-.252-.254v-7.333c0-.138.111-.254.252-.254Zm93.228-59.933c-3.383-3.294-8.676-3.9-13.23-2.707-4.741 1.24-8.954 4.306-11.742 8.348-2.489 3.61-4.815 8.744-2.715 13.036 1.341 2.747 4.04 4.669 6.962 5.5 2.921.83 6.052.656 9.016-.013 5.319-1.2 10.459-4.178 13.181-8.939 2.722-4.76 2.447-11.408-1.472-15.225Z"
    />
    <path
      fill="#081906"
      d="M198.526 13.945c-5.863-5.318-14.915-6.733-22.099-3.449-3.402 1.553-6.487 4.253-7.573 7.857-1.087 3.6.376 8.078 3.801 9.584-1.793.498-2.283 3.106-1.22 4.643 1.063 1.536 3.062 2.123 4.92 2.192 1.865.07 3.798-.257 5.555.37 1.292.461 2.352 1.394 3.419 2.261 3.039 2.473 6.595 4.626 10.498 4.751 10.953.353 10.707-14.395 7.488-21.546-1.132-2.516-2.751-4.814-4.789-6.663Z"
    />
    <path
      fill="#fff"
      d="m147.102 167.442 12.794-51.335 43.19 8.856c9.801 13.062 13.688 28.981 15.069 42.482h-71.053v-.003Z"
    />
    <path
      fill="#081906"
      d="M217.955 166.185a.23.23 0 0 1-.226-.204c-.863-8.859-2.941-17.557-6.009-25.157a74.95 74.95 0 0 0-5.529-11.012 68.438 68.438 0 0 0-1.669-2.598c-.108-.161-.219-.346-.337-.54-.412-.686-.925-1.54-1.534-1.665-2.296-.479-4.596-.95-6.896-1.418-4.757-.97-9.599-1.936-14.277-2.872-4.364-.874-8.879-1.774-13.315-2.677-2.299-.468-4.599-.936-6.899-1.415l-.942-.194c-.063-.013-.112-.026-.157-.036a.47.47 0 0 0-.072-.017c-.02.036-.043.089-.066.139-.14.306-.212.709-.281 1.094-.036.201-.069.393-.115.574-.274 1.141-.553 2.282-.831 3.419-1.197 4.88-2.411 9.65-3.441 13.676l-1.325 5.17c-1.858 7.237-3.779 14.725-5.562 22.11-.271 1.134-.543 2.265-.811 3.399a.225.225 0 1 1-.438-.105c.268-1.135.539-2.269.811-3.403 1.783-7.392 3.703-14.876 5.562-22.116l1.324-5.17c1.031-4.023 2.245-8.793 3.442-13.67.278-1.137.556-2.278.831-3.419.039-.168.072-.353.108-.547.075-.416.15-.844.317-1.204.164-.359.252-.468.648-.372l.144.033.942.194c2.3.475 4.597.947 6.896 1.415 4.437.9 8.948 1.803 13.312 2.674 4.681.936 9.523 1.902 14.28 2.871 2.3.469 4.6.94 6.899 1.418.802.168 1.371 1.115 1.829 1.876.115.191.223.369.324.521a72.377 72.377 0 0 1 1.678 2.615 75.106 75.106 0 0 1 5.562 11.078c3.085 7.639 5.175 16.379 6.042 25.281a.224.224 0 0 1-.203.248h-.022l.006.006Z"
    />
    <path
      fill="#081906"
      d="M177.565 166.186h-.019a.225.225 0 0 1-.203-.247l.049-.525c.14-1.506.284-3.063.186-4.592-.347-5.454-5.054-7.692-9.114-9.034a.229.229 0 0 1-.144-.287.225.225 0 0 1 .285-.145c4.197 1.385 9.062 3.709 9.425 9.436.098 1.563-.046 3.139-.187 4.665l-.049.524a.226.226 0 0 1-.226.208l-.003-.003Z"
    />
    <path
      fill="#fff"
      d="m192.725 36.909 6.396 22.126s-12.206 15.163-21.406 14.567c-9.199-.597-7.485-11.092-.631-14.35l6.69-1.951-1.995-17.343 10.946-3.043v-.006Z"
    />
    <path
      fill="#081906"
      d="M178.256 73.842a10.3 10.3 0 0 1-.556-.016c-3.553-.231-5.902-1.998-6.439-4.844-.7-3.712 1.816-8.078 5.729-9.937.009-.007.023-.01.032-.013l6.507-1.9-1.972-17.15a.224.224 0 0 1 .163-.245l10.947-3.043a.229.229 0 0 1 .278.155l6.395 22.127a.226.226 0 0 1-.042.207c-.491.61-11.915 14.666-21.039 14.666l-.003-.007Zm-1.093-14.382c-3.726 1.78-6.124 5.922-5.46 9.433.504 2.668 2.643 4.257 6.026 4.474 8.61.554 20.103-13.129 21.14-14.388l-6.297-21.797-10.544 2.935L184 57.267a.226.226 0 0 1-.16.245l-6.674 1.948h-.003Z"
    />
    <path
      fill="#fff"
      d="M173.39 32.322c-.464 2.545-.474 5.226.377 7.669.854 2.443 2.65 4.616 5.051 5.542 2.08.801 4.459.61 6.517-.257 2.054-.864 3.804-2.36 5.231-4.082 2.195.785 4.868-.132 6.13-2.1.73-1.137.985-2.65.39-3.864-.596-1.213-2.163-1.939-3.376-1.362-.694.33-1.185 1.003-1.891 1.303-.786.336-1.724.135-2.434-.343-.71-.478-1.217-1.207-1.623-1.962a11.415 11.415 0 0 1-1.05-7.88c-.059.25-1.371.627-1.606.706a11.111 11.111 0 0 1-5.477.409 11.471 11.471 0 0 1-1.806-.475c-.464-.168-1.069-.61-1.567-.617-1.037-.013-1.348 1.896-1.596 2.631-.52 1.53-.982 3.086-1.273 4.682h.003Z"
    />
    <path
      fill="#081906"
      d="M181.645 46.265a8.058 8.058 0 0 1-2.909-.52c-2.313-.89-4.249-3.014-5.182-5.678-.788-2.252-.916-4.87-.386-7.784.252-1.392.661-2.889 1.279-4.715.043-.126.085-.284.138-.462.255-.926.644-2.324 1.665-2.324h.013c.376.003.762.2 1.135.395.183.093.354.182.504.235a10.945 10.945 0 0 0 5.359.498 10.63 10.63 0 0 0 1.773-.436c.033-.013.088-.03.16-.05.946-.296 1.237-.448 1.309-.523a.231.231 0 0 1 .262-.139c.121.03.196.152.17.274a11.21 11.21 0 0 0 1.03 7.721c.478.89.972 1.49 1.551 1.883.71.478 1.56.603 2.218.323.337-.142.625-.382.929-.633.291-.244.595-.494.952-.663 1.276-.606 2.993.076 3.677 1.468.592 1.21.438 2.776-.402 4.088-1.283 2.001-3.998 2.964-6.249 2.242-1.586 1.882-3.34 3.238-5.218 4.026a9.784 9.784 0 0 1-3.775.778l-.003-.004Zm-8.035-13.903c-.517 2.835-.393 5.374.37 7.55.886 2.539 2.725 4.56 4.917 5.404 1.907.735 4.22.643 6.346-.254 1.849-.778 3.583-2.13 5.146-4.02a.225.225 0 0 1 .249-.068c2.087.748 4.665-.136 5.866-2.012.742-1.16.893-2.588.376-3.64-.566-1.157-2.035-1.754-3.079-1.256-.307.145-.575.37-.86.604-.317.263-.644.534-1.04.702-.799.34-1.813.201-2.647-.363-.641-.435-1.181-1.084-1.695-2.044-1.213-2.262-1.632-4.959-1.184-7.49a10.13 10.13 0 0 1-1.053.372l-.151.046a10.94 10.94 0 0 1-1.848.455 11.388 11.388 0 0 1-5.581-.521 4.815 4.815 0 0 1-.556-.257c-.327-.168-.668-.343-.936-.346h-.007c-.68 0-1.01 1.197-1.23 1.991-.052.188-.098.356-.144.488-.611 1.803-1.014 3.28-1.262 4.649l.003.01Z"
    />
    <path
      fill="#fff"
      d="M194.269 37.07c-1.223-.063-2.45.679-2.97 1.8l2.97-1.8Z"
    />
    <path
      fill="#081906"
      d="M191.299 39.094a.23.23 0 0 1-.203-.326c.56-1.204 1.871-1.995 3.187-1.929a.224.224 0 0 1 .212.237.228.228 0 0 1-.235.215c-1.135-.056-2.271.63-2.755 1.668a.23.23 0 0 1-.206.132v.003Z"
    />
    <path
      fill="#7EC41F"
      d="M211.576 129.875c4.498-2.364 7.344-6.999 9.5-11.628 5.065-10.884 7.521-22.945 7.482-34.966-.02-5.96-.752-12.232-4.21-17.068-3.743-5.233-10.139-7.953-16.469-8.916-2.548-.385-5.257-.507-7.606.56-3.056 1.389-4.914 4.478-7.217 6.931a21.816 21.816 0 0 1-9.88 6.03c-2.394.683-5.286.851-6.85-1.463-.7-1.036-.916-2.318-.753-3.561.344-2.608 2.035-4.62 3.678-6.545-3.151-.514-6.396.755-8.882 2.77-2.49 2.014-4.329 4.718-6.007 7.454-5.126 8.368-9.065 17.435-12.219 26.743-1.115 3.293-2.149 6.732-1.793 10.194.403 3.914 1.123 7.653 3.992 10.323a69.678 69.678 0 0 0 2.581 2.288c1.462 1.24 3.379 1.758 5.27 1.494 10.417-1.444 35.61 6.785 37.743 8.529 3.209 2.625 8.054 2.717 11.643.831h-.003Z"
    />
    <path
      fill="#081906"
      d="M199.118 97.854a.25.25 0 0 1-.069-.01.23.23 0 0 1-.147-.286l4.979-15.75a.23.23 0 0 1 .285-.149c.118.04.183.165.147.287l-4.979 15.75a.228.228 0 0 1-.216.158Zm-35.175-6.043h-.033a.223.223 0 0 1-.19-.257 66.104 66.104 0 0 1 3.203-12.934.22.22 0 0 1 .288-.136.225.225 0 0 1 .134.29 65.63 65.63 0 0 0-3.183 12.846.226.226 0 0 1-.223.194l.004-.003Z"
    />
    <path
      fill="#fff"
      d="M197.437 125.315c-.174.574-.426 1.227-.992 1.415a1.48 1.48 0 0 1-1.357-.254c-7.839-6.446-14.091-10.597-23.372-14.623-1.181-.511.684 4.682-6.029.047-1.06-.732-.173-2.915 1.093-3.129-1.047-.149-1.944-1.062-2.074-2.12-.135-1.059.503-2.166 1.482-2.575-1.015.191-1.96-.828-2.009-1.866-.049-1.039.559-2.002 1.295-2.73-2.807 1.374-6.745 5.377-8.97 2.68-.595-2.733 1.302-4.19 3.406-6.017 2.106-1.827 4.848-2.733 7.58-3.25 6.071-1.152 12.392-.578 18.326 1.153 5.222 1.523 10.191 3.92 14.804 6.808 1.132.709 1.511.864 1.527 2.18.02 1.411-.392 2.967-.611 4.362a134.426 134.426 0 0 1-1.652 8.651 134.13 134.13 0 0 1-2.447 9.275v-.007Z"
    />
    <path
      fill="#081906"
      d="M171.549 111.972a.229.229 0 0 1-.068-.01l-2.686-.864a.23.23 0 0 1-.147-.286.23.23 0 0 1 .284-.149l2.686.864c.118.04.183.165.147.287a.227.227 0 0 1-.216.158Zm40.21-4.091a.225.225 0 0 1-.144-.053 41.73 41.73 0 0 0-8.715-5.549.23.23 0 0 1-.108-.303.223.223 0 0 1 .301-.109 42 42 0 0 1 8.81 5.608.227.227 0 0 1 .029.32.216.216 0 0 1-.173.082v.004Z"
    />
    <path
      fill="#fff"
      d="M163.236 114.409c-.043 1.784-.55 3.696-1.947 4.794-1.799 1.411-4.374 1.012-6.565.376-10.394-3.014-19.77-8.823-25.158-18.273-.222-.389-2.202-5.166-1.39-6.244.762-1.016 2.653.913 3.889 1.174a130.021 130.021 0 0 1 15.805 4.401c2.656.923 5.287 1.932 7.878 3.027 1.982.837 3.67 1.062 4.992 2.875 1.649 2.255 2.558 5.067 2.493 7.87h.003Z"
    />
    <path
      fill="#081906"
      d="M154.725 119.806c-.02 0-.043 0-.063-.01-11.607-3.363-20.351-9.72-25.291-18.378-.026-.046-2.349-5.2-1.374-6.495a.226.226 0 0 1 .317-.043.231.231 0 0 1 .043.32c-.681.907 1.089 5.44 1.406 5.994 4.878 8.553 13.531 14.837 25.024 18.167a.224.224 0 0 1 .153.28.226.226 0 0 1-.215.165Z"
    />
    <path
      fill="#081906"
      d="M122.638 85.922h19.108c.409 0 .792.188 1.044.511l15.405 19.753c.684.877.066 2.166-1.043 2.166h-18.448c-.399 0-.775-.181-1.027-.491L121.61 88.108c-.709-.873-.094-2.186 1.028-2.186Z"
    />
    <path
      fill="#fff"
      d="M130.761 88.464c.229-.21.556-.422.831-.27.238.129.281.452.297.722.154 2.674.305 5.348.458 8.022-.032-2.39.586-4.867 2.124-6.69.379-.449 1.046-.87 1.495-.491.268.227.291.633.274.986-.13 3.003-1.325 6.106-.278 8.918a20.57 20.57 0 0 1 1.253-5.23c.265-.685.589-1.394 1.185-1.82.595-.428 1.534-.45 1.979.133.474.62.18 1.503-.079 2.242a17.041 17.041 0 0 0-.932 6.693c.288-1.767.651-3.676 1.96-4.89.382-.356.857-.643 1.377-.686.52-.042 1.083.205 1.308.676.249.521.043 1.135-.121 1.685-.638 2.133-.624 4.451-.003 6.584.465 1.603 2.454 3.756.35 5.005-.723.429-1.613.462-2.444.343-2.345-.339-4.383-1.767-6.215-3.28-1.872-1.547-3.651-3.241-5.016-5.253-1.364-2.01-2.306-4.368-2.375-6.808-.068-2.437.802-4.949 2.585-6.597l-.013.006ZM36.274 27.3c3.298-9.178-1.423-19.307-10.544-22.627C16.606 1.353 6.537 6.104 3.24 15.28c-3.298 9.179 1.423 19.307 10.544 22.624 9.12 3.32 19.19-1.43 22.491-10.606v.003Z"
    />
    <path
      fill="#081906"
      d="M19.718 39.338c-4.21 0-8.254-1.48-11.516-4.233-3.667-3.106-5.911-7.458-6.33-12.258-.85-9.911 6.474-18.672 16.325-19.529 4.77-.419 9.425 1.062 13.092 4.168 3.667 3.105 5.911 7.458 6.33 12.255.416 4.8-1.053 9.482-4.142 13.171-3.088 3.69-7.413 5.948-12.182 6.367-.524.05-1.054.07-1.577.07v-.01Zm.065-35.347c-.504 0-1.008.02-1.508.069-9.445.818-16.472 9.218-15.657 18.72.396 4.607 2.545 8.784 6.068 11.751a16.99 16.99 0 0 0 12.553 3.993 17.025 17.025 0 0 0 11.682-6.103c2.961-3.534 4.364-8.021 3.969-12.627-.396-4.607-2.546-8.784-6.069-11.751A16.987 16.987 0 0 0 19.78 3.984l.01.01-.007-.003Z"
    />
    <path
      fill="#D6EF89"
      d="M25.956 35.992c8.067-3.442 11.836-12.816 8.414-20.933C30.948 6.942 21.63 3.15 13.563 6.592c-8.07 3.446-11.84 12.816-8.417 20.933 3.422 8.117 12.739 11.91 20.81 8.467Z"
    />
    <path
      fill="#fff"
      d="M33.346 24.845c1.953-7.55-2.548-15.266-10.053-17.227C15.788 5.653 8.12 10.183 6.167 17.733c-1.953 7.55 2.548 15.265 10.056 17.227 7.505 1.965 15.173-2.565 17.127-10.115h-.004Z"
    />
    <path
      fill="#081906"
      d="M19.746 36.236c-7.616 0-14.12-5.882-14.787-13.66-.706-8.206 5.362-15.47 13.521-16.181a14.787 14.787 0 0 1 12.834 5.499.377.377 0 0 1-.059.528.373.373 0 0 1-.523-.06 14.095 14.095 0 0 0-12.193-5.229c-7.75.673-13.52 7.564-12.85 15.374.638 7.389 6.814 12.977 14.05 12.977.406 0 .812-.02 1.23-.05a14.03 14.03 0 0 0 9.593-5.004c2.427-2.901 3.579-6.58 3.261-10.36a.366.366 0 0 1 .34-.398.364.364 0 0 1 .397.343 14.9 14.9 0 0 1-3.436 10.903c-2.554 3.056-6.133 4.926-10.092 5.265-.435.04-.86.06-1.286.06l.01-.01-.01.003Z"
    />
    <path
      fill="#7EC41F"
      d="m19.387 29.77-.164-1.958c-1.374.059-2.738-.195-3.553-.584l.406-2.453c.9.399 2.14.722 3.465.613 1.16-.099 1.926-.623 1.848-1.44-.069-.779-.766-1.217-2.323-1.596-2.244-.544-3.83-1.451-3.998-3.446-.153-1.81.988-3.34 3.135-3.963l-.164-1.958 2.002-.175.154 1.81c1.374-.06 2.323.145 3.02.419l-.396 2.377c-.553-.175-1.528-.574-3-.448-1.325.118-1.705.728-1.656 1.305.06.68.812 1.052 2.604 1.556 2.506.653 3.58 1.695 3.746 3.545.154 1.83-.998 3.505-3.31 4.118l.183 2.093-2.002.175v.01h.003Z"
    />
    <path
      fill="#044330"
      d="M100.702 93.647a9.961 9.961 0 0 1-7.115-2.97L83.31 80.32c-.723-.728-1.979-.725-2.699 0l-10.31 10.392a9.961 9.961 0 0 1-7.116 2.971H61.16L74.174 106.8a10.352 10.352 0 0 0 14.718 0l13.05-13.152h-1.24ZM63.182 57.02a9.961 9.961 0 0 1 7.115 2.97L80.61 70.386a1.9 1.9 0 0 0 2.699 0L93.583 60.03a9.961 9.961 0 0 1 7.116-2.97h1.236l-13.05-13.153a10.352 10.352 0 0 0-14.718 0L61.153 57.023h2.025l.004-.003Z"
    />
    <path
      fill="#044330"
      d="m112.735 67.936-7.888-7.95a1.51 1.51 0 0 1-.559.116h-3.585a7.06 7.06 0 0 0-4.98 2.08L85.448 72.54a4.897 4.897 0 0 1-3.488 1.454 4.897 4.897 0 0 1-3.487-1.454L68.16 62.147a7.053 7.053 0 0 0-4.979-2.08h-4.41c-.186 0-.363-.047-.53-.11l-7.917 7.98c-4.063 4.094-4.063 10.734 0 14.832l7.917 7.98c.167-.063.344-.11.53-.11h4.41a7.07 7.07 0 0 0 4.98-2.076l10.31-10.393c1.866-1.876 5.114-1.876 6.976 0l10.275 10.353a7.054 7.054 0 0 0 4.98 2.08h3.585c.2 0 .386.043.559.116l7.888-7.95c4.063-4.094 4.063-10.735 0-14.833Z"
    />
    <path
      fill="#7EC41F"
      d="M41.957 13.26a6.286 6.286 0 0 0 0-8.84 6.169 6.169 0 0 0-8.772 0 6.286 6.286 0 0 0 0 8.84 6.169 6.169 0 0 0 8.772 0Z"
    />
    <path
      fill="#fff"
      d="M36.987 11.502a.289.289 0 0 1-.199-.08l-2.146-2.008A.297.297 0 0 1 34.625 9a.292.292 0 0 1 .413-.016l1.914 1.787 3.12-4.076a.29.29 0 0 1 .41-.052c.127.099.15.283.052.412l-3.317 4.329a.284.284 0 0 1-.207.112h-.023v.007Z"
    />
    <path
      fill="#D6EF89"
      d="M142.191 35.326a.29.29 0 0 0 .229.02.313.313 0 0 0 .213-.386l-.304-1.065c-.053-.165-.219-.264-.383-.215a.314.314 0 0 0-.213.386l.305 1.065a.31.31 0 0 0 .157.195h-.004Zm1.057 3.714a.28.28 0 0 0 .226.02.313.313 0 0 0 .213-.385l-.305-1.065a.309.309 0 1 0-.595.171l.301 1.065c.02.083.085.155.154.192l.006.003Zm.612-2.771c.072.04.151.043.236.023l1.037-.314a.309.309 0 0 0 .202-.389.31.31 0 0 0-.386-.207l-1.037.313a.307.307 0 0 0-.202.389.276.276 0 0 0 .15.181v.004Zm-3.622 1.084c.072.04.151.043.236.023l1.037-.313a.308.308 0 0 0 .203-.39.31.31 0 0 0-.386-.207l-1.037.313a.308.308 0 0 0-.203.39c.02.082.078.145.15.184ZM138.2 25.447a.32.32 0 0 0 .295 0 .314.314 0 0 0 .13-.418c-7.864-15.025-20.09-21.082-26.675-23.367a.313.313 0 0 0-.396.195.316.316 0 0 0 .193.399c6.504 2.252 18.566 8.233 26.329 23.063a.288.288 0 0 0 .131.135h-.007v-.007Z"
    />
    <path
      fill="#D6EF89"
      d="M127.499 16.835a.302.302 0 0 0 .357-.05.316.316 0 0 0 .02-.441C117.204 4.732 104.4 2.552 104.272 2.529a.314.314 0 0 0-.095.62c.124.02 12.726 2.166 23.247 13.617.026.023.049.046.085.063h-.01v.006ZM3.894 111.607a.311.311 0 0 0-.422.386l.353 1.048a.31.31 0 0 0 .392.195.313.313 0 0 0 .193-.396l-.353-1.049a.315.315 0 0 0-.163-.184Zm-1.231-3.66a.294.294 0 0 0-.229-.01.31.31 0 0 0-.193.396l.353 1.048a.307.307 0 0 0 .393.195.312.312 0 0 0 .193-.396l-.353-1.048a.304.304 0 0 0-.164-.185Zm-.481 2.799a.324.324 0 0 0-.235-.013l-1.021.366a.315.315 0 0 0-.186.399.31.31 0 0 0 .395.188l1.021-.366a.313.313 0 0 0 .187-.399.281.281 0 0 0-.16-.175Zm3.566-1.259a.339.339 0 0 0-.236-.013l-1.02.366a.311.311 0 0 0 .21.586l1.02-.366a.309.309 0 0 0 .186-.398.281.281 0 0 0-.16-.175Zm2.611 11.793a.312.312 0 0 0-.4.442c8.578 14.622 21.079 20.075 27.765 22.037a.314.314 0 0 0 .174-.603c-6.602-1.929-18.939-7.316-27.408-21.751a.288.288 0 0 0-.138-.128h.007v.003Z"
    />
    <path
      fill="#D6EF89"
      d="M19.459 129.358a.304.304 0 0 0-.354.066.314.314 0 0 0 0 .442c11.218 11.078 24.111 12.631 24.239 12.644a.315.315 0 0 0 .066-.627c-.128-.013-12.818-1.543-23.876-12.466-.026-.02-.052-.043-.088-.059h.013Z"
    />
  </svg>
);
