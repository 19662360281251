/* eslint-disable no-underscore-dangle */
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import styled from 'styled-components';
import { TablePagination } from '@mui/material';
import MoreOptions from '../../../components/MoreOptions';
import {
  StyledContentLoading,
  StyledLink,
} from '../../../components/TableCollapse/styles';

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#79cc72',
    color: '#FFF',
    fontWeight: 'bold',
    fontSize: 16,
    fontFamily: 'Poppins,sans-serif',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: 'Poppins,sans-serif',
  },
}));

const StyledTableRow = styled(TableRow)<{ open?: string }>(
  ({ onClick, open }) => ({
    '&:hover': {
      backgroundColor: '#79cc7222',
      transition: '0.3s',
    },
    transition: '0.5s',
    '&:last-child td, &:last-child th': {
      border: 0,
    },
    backgroundColor: open === 'true' ? '#79cc7222' : '',
    cursor: onClick ? 'pointer' : 'auto',
  })
);

const StyledContentPagination = styled('div')({
  width: '100%',
});

interface Option {
  id: string;
  label: string;
  icon?: React.ReactElement;
  onClick: (id: string) => void;
}

interface RowProps {
  row: any;
  rows: string[];
  options?: Option[] | ((item: any) => Option[]);
  handlePreview: (attachmentId: string) => void;
  handleDownload: (
    requestId: string,
    attachmentId: string,
    filename: string
  ) => void;
}

function Row(props: RowProps) {
  const { row, rows, options, handlePreview } = props;

  return (
    <React.Fragment>
      <StyledTableRow>
        {rows.map((rowKey) => (
          <StyledTableCell key={rowKey} align="center">
            {row[rowKey]}
          </StyledTableCell>
        ))}
        <StyledTableCell align="center">
          {row.paymentReceiptId ? (
            <StyledLink
              onClick={() => handlePreview(row.paymentReceiptId)}
              style={{
                display: 'flex',
                justifyContent: 'center',
                cursor: 'pointer',
              }}
            >
              <StyledContentLoading>Visualizar</StyledContentLoading>
            </StyledLink>
          ) : (
            '-'
          )}
        </StyledTableCell>
        {options && (
          <StyledTableCell align="center">
            <MoreOptions
              options={typeof options === 'function' ? options(row) : options}
              id={row.id}
            />
          </StyledTableCell>
        )}
      </StyledTableRow>
    </React.Fragment>
  );
}

interface Props {
  head: string[];
  rows: string[];
  data: any[];
  itemsPerPage: number;
  page: number;
  count: number;
  changePage: (page: number) => void;
  changeItemsPerPage: (items: number) => void;
  options?: Option[] | ((item: any) => Option[]);
  handlePreview: (attachmentId: string) => void;
  handleDownload: (
    requestId: string,
    attachmentId: string,
    filename: string
  ) => void;
}

export const ExtractTableCollapse = ({
  head,
  rows,
  options,
  data,
  changeItemsPerPage,
  changePage,
  page,
  count,
  itemsPerPage,
  handlePreview,
  handleDownload,
}: Props): React.ReactElement => {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            {head.map((item) => (
              <StyledTableCell align="center" key={item}>
                {item}
              </StyledTableCell>
            ))}
            {options && <StyledTableCell align="center">Ações</StyledTableCell>}
          </TableRow>
        </TableHead>

        <TableBody>
          {data.map((item) => (
            <Row
              key={item.id}
              row={item}
              rows={rows}
              options={options}
              handleDownload={handleDownload}
              handlePreview={handlePreview}
            />
          ))}
        </TableBody>
      </Table>
      <StyledContentPagination>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          labelRowsPerPage="Itens por página"
          labelDisplayedRows={(item) =>
            `${item.from} - ${item.to} de ${item.count}`
          }
          component="div"
          count={count}
          rowsPerPage={itemsPerPage}
          page={page}
          onPageChange={(e, page) => changePage(page)}
          onRowsPerPageChange={(event) =>
            changeItemsPerPage(Number(event.target.value))
          }
        />
      </StyledContentPagination>
    </TableContainer>
  );
};

export default ExtractTableCollapse;
