import React, { useEffect } from 'react';
import { Logo } from '../Logo';
import {
  StyledCol,
  StyledCopyRight,
  StyledFooter,
  StyledFooterInner,
  StyledLogos,
  StyledMenu,
  StyledMenuItem,
  StyledTitle,
} from './styles';

export const Footer = (): React.ReactElement => {
  const [isLarge, setIsLarge] = React.useState(false);

  useEffect(() => {
    const handleResize = () => setIsLarge(window.innerWidth > 768);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <StyledFooter id="contato">
      <StyledFooterInner>
        <StyledCol>
          <Logo isLarge={isLarge} />
        </StyledCol>

        <StyledCol>
          <StyledTitle>Sobre</StyledTitle>
          <StyledMenu>
            <StyledMenuItem
              href="https://milhasnaconta.com.br/terms/"
              target="_blank"
            >
              Termos e condições
            </StyledMenuItem>
            <StyledMenuItem
              href="https://milhasnaconta.com.br/privacy/"
              target="_blank"
            >
              Política de privacidade
            </StyledMenuItem>
          </StyledMenu>
        </StyledCol>

        <StyledCol>
          <StyledTitle>Contatos</StyledTitle>
          <StyledMenu>
            <StyledMenuItem
              href="https://wa.me/5531995760123?text=Ol%C3%A1%2C%20tudo%20bem%3F%0AGostaria%20de%20tirar%20d%C3%BAvida(s)%20sobre%20a%20plataforma%20Milhas%20na%20Conta.%0A%3A)"
              target="_blank"
            >
              +55 (31) 9 9576-0123
            </StyledMenuItem>
            <StyledMenuItem href="mailto:milhasnaconta2024@gmail.com">
              milhasnaconta2024@gmail.com
            </StyledMenuItem>
          </StyledMenu>
        </StyledCol>

        <StyledCol>
          <StyledTitle>Segurança</StyledTitle>
          <StyledLogos>
            <img src="images/landing-page/selo-site-seguro.png" alt="" />
            <img src="images/landing-page/google-site-seguro.png" alt="" />
            <img src="images/landing-page/selo-site-blindado.png" alt="" />
            <img src="images/landing-page/selo-ssl-secured.png" alt="" />
          </StyledLogos>
        </StyledCol>
      </StyledFooterInner>

      <StyledCopyRight>
        Copyright © 2024 Milhas Na Conta. Todos os direitos reservados.
      </StyledCopyRight>
    </StyledFooter>
  );
};
