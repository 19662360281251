import styled from 'styled-components';

export const StyledLogoContainer = styled.div`
  min-width: 178px;
  width: 600px;
  position: relative;
  box-sizing: border-box;
  max-width: 100%;
  font-family: Roboto, poppins;
  border: 1px solid #ccc;
  margin: 0px 10px;
  border-radius: 8px;
  overflow-y: auto;
`;

export const StyledImageContainer = styled('div')({
  '> svg': {
    position: 'absolute',
    width: '62px',
    top: '-24px',
    left: '-35px',
  },
});

export const StyledTitle = styled.div`
  font-feature-settings: 'clig' off, 'liga' off;
  flex-basis: auto;
  font: 26px Saira Stencil One, sans-serif;
`;

export const StyleContentFilter = styled('div')({
  display: 'flex',
  width: '100%',
  gap: 8,

  '@media (max-width: 600px)': {
    flexDirection: 'column',
  },

  '.simple-input': {
    marginBottom: 0,
  },
});

export const StyleContentExtract = styled('div')<{ selected: boolean }>(
  ({ selected }) => ({
    display: 'flex',
    width: '100%',
    padding: 8,
    transition: '0.4s',
    borderRadius: 8,
    justifyContent: 'space-between',
    background: selected ? '#dfdfdf' : '',

    '&:hover': {
      background: '#dfdfdf',
      cursor: 'pointer',
    },
  })
);

export const StyleContentExtractItem = styled('div')({
  width: 160,
});

export const StyleContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
  padding: '0px 0px 16px 0px',
  transition: '0.4s',
});

export const Div7 = styled.div`
  margin-top: 24px;
  color: #fff;
  font-family: Poppins, sans-serif;
  font-weight: 500;

  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

export const StyledTitleExtract = styled('div')({
  textAlign: 'center',
  fontSize: 16,
  fontWeight: 'bold',
});

export const StyledContentBox = styled('div')({
  flex: 1,
  overflowY: 'scroll',
  padding: '8px 16px',
  height: 'calc(100% - 50px)',
});

export const StyledContentButtons = styled('div')({
  height: 50,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});
