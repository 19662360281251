import React from 'react';
import { Logo } from '../Logo';
import { Menu } from '../Menu';
import {
  StyledHeader,
  StyledHeaderInner,
  StyledToggleMenuButton,
} from './styles';

interface HeaderProps {
  removeMenu?: boolean;
}

export const Header = ({ removeMenu }: HeaderProps): React.ReactElement => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  const handleToggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
  };

  return (
    <StyledHeader id="home">
      <StyledHeaderInner>
        <Logo />

        {!!removeMenu && (
          <StyledToggleMenuButton onClick={handleToggleMenu}>
            <svg viewBox="0 0 27 23" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M25 2H2m23 9H2m23 10H2"
                stroke="currentColor"
                strokeWidth="3"
                strokeLinecap="round"
              />
            </svg>
          </StyledToggleMenuButton>
        )}
      </StyledHeaderInner>

      {!!removeMenu && (
        <Menu isMenuOpen={isMenuOpen} handleCloseMenu={handleToggleMenu} />
      )}
    </StyledHeader>
  );
};
