import styled from 'styled-components';

export const StyledLandingPage = styled.div`
  // reset
  // ----------------------------------------
  *: {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  & + div {
    display: none;
  }

  // variables
  // ----------------------------------------
  --color-primary: #7ec41f;
  --color-primary-dark: #044330;
  --color-primary-light: #ebffb9;
  --color-gray: #707070;
  --color-gray-light: #bababa;
  --color-gray-extra-light: #f2f2f2;
  --color-gray-custom: #6c8a76;
  --color-white: #ffffff;

  --container-max-width: 1000px;

  --swiper-pagination-color: var(--color-primary-dark);

  // styles landing
  // ----------------------------------------
  min-height: 100vh;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  color: var(--color-gray);

  .section-title {
    text-transform: uppercase;
    text-align: center;
    font-size: 30px;
    color: var(--color-primary-dark);
    margin-bottom: 24px;
  }
`;
