import * as React from 'react';
import { CircularProgress, Modal, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import {
  Div9,
  FormLoginStyled,
  StyledBox,
  StyledContentButtons,
} from './styles';
import SubmitButton, { Button } from '../../../components/Button';
import SimpleSelect from '../../Login/Select';
import { RequestStatusEnum, requestStatusLabel } from '../utils';

interface Props {
  open: boolean;
  isLoading: boolean;
  defaultValues?: {
    status: RequestStatusEnum;
  };
  handleClose: () => void;
  handleSubmit: (values: any) => void;
}

const ModalEditRequestStatus = ({
  open,
  isLoading,
  defaultValues,
  handleClose,
  handleSubmit,
}: Props): React.ReactElement => {
  const [values, setValues] = React.useState({
    status: RequestStatusEnum.pending,
  });

  React.useEffect(() => {
    if (!open) {
      setValues({
        status: RequestStatusEnum.pending,
      });
    }
  }, [open]);

  React.useEffect(() => {
    if (defaultValues) {
      setValues({ ...defaultValues });
    }
  }, [defaultValues]);

  const handleChange = (event: any): void => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const submit = (e: any): void => {
    e.preventDefault();

    if (!values.status) {
      toast('Por favor, preencha os campos antes de submeter o formulário.', {
        type: 'error',
      });
      return;
    }

    handleSubmit({ ...values });
  };

  const getOptions = () => {
    const options = [
      {
        value: RequestStatusEnum.pending,
        label: requestStatusLabel(RequestStatusEnum.pending),
      },
      {
        value: RequestStatusEnum.analysing,
        label: requestStatusLabel(RequestStatusEnum.analysing),
      },
      {
        value: RequestStatusEnum.confirmed,
        label: requestStatusLabel(RequestStatusEnum.confirmed),
      },
      {
        value: RequestStatusEnum.completed,
        label: requestStatusLabel(RequestStatusEnum.completed),
      },
    ];

    if (defaultValues?.status === RequestStatusEnum.pending) {
      return options.slice(0, 2);
    }

    if (defaultValues?.status === RequestStatusEnum.analysing) {
      return options.slice(0, 3);
    }

    if (defaultValues?.status === RequestStatusEnum.confirmed) {
      return options.slice(1, 4);
    }

    return options;
  };

  return (
    <Modal open={open}>
      <StyledBox>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Alterar Status
        </Typography>
        <FormLoginStyled>
          <Div9>Selecione o novo status</Div9>
          <SimpleSelect
            name="status"
            value={values.status}
            onChange={handleChange}
            options={getOptions()}
          />

          <StyledContentButtons>
            <div style={{ width: '45%' }}>
              <Button onClick={handleClose} disabled={isLoading}>
                {isLoading ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <span>Cancelar</span>
                )}
              </Button>
            </div>
            <div style={{ width: '45%' }}>
              <SubmitButton onClick={submit} disabled={isLoading}>
                {isLoading ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <span>Salvar</span>
                )}
              </SubmitButton>
            </div>
          </StyledContentButtons>
        </FormLoginStyled>
      </StyledBox>
    </Modal>
  );
};

export default ModalEditRequestStatus;
