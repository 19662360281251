export const formatUserRole = (role: string): string => {
  if (role === 'admin') {
    return 'Administrador';
  }

  if (role === 'partner') {
    return 'Parceiro';
  }

  return 'Cliente';
};

export const formatUserStatus = (status: string): string => {
  if (status === 'inactive') {
    return 'Inativo';
  }

  return 'Ativo';
};
