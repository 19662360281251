/* eslint-disable no-underscore-dangle */
import React, { ReactElement, useMemo, useState } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { toast } from 'react-toastify';
import {
  StyleContainer,
  StyleContentExtract,
  StyleContentExtractItem,
  StyleContentFilter,
  StyledContentBox,
  StyledContentButtons,
  StyledLogoContainer,
  StyledTitleExtract,
} from './styles';
import { formatMoney, removeMaskMoney } from '../../../utils/money';
import { formatOnlyDate } from '../../../utils/date';
import SimpleInput from '../Input';
import { Div7 } from '../styles';
import { useDebounce } from '../../../hooks/useDebounce';
import SubmitButton from '../../Button';

interface Props {
  fileId: string;
  extracts?: any[];
  handleSelectExtract?: (id: string) => void;
}

const SelectExtract = ({
  fileId,
  extracts = [],
  handleSelectExtract,
}: Props): ReactElement => {
  const [selected, setSelected] = useState('');

  const [idTransaction, setIdTransaction] = useState('');
  const [dateTransaction, setDateTransaction] = useState('');
  const [valueTransaction, setValueTransaction] = useState('');

  const alreadyVinculated = useMemo(() => {
    const item = extracts.find((item) => {
      return item.paymentReceiptId === fileId;
    });

    return item;
  }, [fileId, extracts]);

  const idSearch = useDebounce(idTransaction, 500);

  const dateSearch = useDebounce(dateTransaction, 500);

  const valueSearch = useDebounce(valueTransaction, 500);

  const result = useMemo(() => {
    let search = [...extracts];

    search = search.filter((item) => !item.paymentReceiptId);

    if (idSearch) {
      search = search.filter((item) => {
        return item.transactionIdentifier
          .toLowerCase()
          .includes(idSearch.toLowerCase());
      });
    }

    if (dateSearch) {
      const dateToCompare = new Date(dateSearch);

      search = search.filter((item) => {
        return (
          formatOnlyDate(item.transactionDateSent) ===
          formatOnlyDate(dateToCompare)
        );
      });
    }

    const value = valueSearch
      ? Number(removeMaskMoney(valueSearch)) / 100
      : undefined;

    if (value) {
      search = search.filter((item) => {
        return item.transactionAmount === value;
      });
    }

    return search;
  }, [idSearch, dateSearch, valueSearch, extracts]);

  const handleChangeValue = (event: any): void => {
    const valueWithoutMask = event.target.value
      ? removeMaskMoney(event.target.value)
      : '0';
    const newValue = Number.isNaN(Number(valueWithoutMask))
      ? 0
      : Number(valueWithoutMask) / 100;

    setValueTransaction(formatMoney(newValue.toString()));
  };

  const handlePressSubmit = () => {
    if (!selected) {
      toast('Por favor, selecione um extrato.', {
        type: 'error',
      });
      return;
    }

    if (handleSelectExtract) {
      handleSelectExtract(selected);
    }
  };

  const formatSendDate = (date: string): string => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + 1);

    return formatOnlyDate(newDate);
  };

  return (
    <StyledLogoContainer>
      <StyledContentBox>
        <StyledTitleExtract>
          {alreadyVinculated ? 'Extrato vinculado' : 'Vincular extratos'}
        </StyledTitleExtract>
        {!alreadyVinculated && (
          <StyleContentFilter>
            <div>
              <Div7>ID da transação</Div7>
              <SimpleInput
                placeholder={'ID da transação'}
                name="idTransaction"
                onChange={(event) => setIdTransaction(event.target.value)}
                value={idTransaction}
              />
            </div>
            <div>
              <Div7>Valor</Div7>
              <SimpleInput
                placeholder={'Valor da transação'}
                name="valueTransaction"
                onChange={handleChangeValue}
                value={valueTransaction}
              />
            </div>
            <div>
              <Div7>Data</Div7>
              <SimpleInput
                placeholder={'Data da transação'}
                name="dateTransaction"
                type="date"
                onChange={(event) => setDateTransaction(event.target.value)}
                value={dateTransaction}
              />
            </div>
          </StyleContentFilter>
        )}
        <StyleContainer>
          {alreadyVinculated && (
            <StyleContentExtract selected style={{ marginTop: 32 }}>
              <StyleContentExtractItem>
                ID: {alreadyVinculated.transactionIdentifier}
              </StyleContentExtractItem>
              <StyleContentExtractItem>
                Valor: {formatMoney(alreadyVinculated.transactionAmount)}
              </StyleContentExtractItem>
              <StyleContentExtractItem>
                Data: {formatSendDate(alreadyVinculated.transactionDateSent)}
              </StyleContentExtractItem>

              <CheckCircleIcon fontSize="medium" color="success" />
            </StyleContentExtract>
          )}
          {!alreadyVinculated &&
            result?.map((item, index) => (
              <StyleContentExtract
                key={index}
                selected={selected === item.transactionIdentifier}
                onClick={() => setSelected(item.transactionIdentifier)}
              >
                <StyleContentExtractItem>
                  ID: {item.transactionIdentifier}
                </StyleContentExtractItem>
                <StyleContentExtractItem>
                  Valor: {formatMoney(item.transactionAmount)}
                </StyleContentExtractItem>
                <StyleContentExtractItem>
                  Data: {formatSendDate(item.transactionDateSent)}
                </StyleContentExtractItem>
                {selected === item.transactionIdentifier ? (
                  <CheckCircleIcon fontSize="medium" />
                ) : (
                  <CheckCircleOutlineIcon fontSize="medium" />
                )}
              </StyleContentExtract>
            ))}
        </StyleContainer>
      </StyledContentBox>
      {!alreadyVinculated && (
        <StyledContentButtons>
          <SubmitButton
            onClick={handlePressSubmit}
            disabled={false}
            style={{ marginTop: 0, width: 300 }}
          >
            <span>Salvar</span>
          </SubmitButton>
        </StyledContentButtons>
      )}
    </StyledLogoContainer>
  );
};

export default SelectExtract;
