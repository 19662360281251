/* eslint-disable import/no-unresolved */
import React from 'react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';

import Card from '../../components/Card';
import { handleGoToTop } from '../../utils';
import { DownloadFigure } from './figures/Download';
import { PaymentFigure } from './figures/Payment';
import { ReceiptFigure } from './figures/Receipt';
import { ResumeFigure } from './figures/Resume';
import { ToSendFigure } from './figures/ToSend';
import {
  StyledActionButton,
  StyledCarousel,
  StyledContainer,
  StyledItem,
  StyledSubtitle,
} from './styles';

const MOCK = [
  {
    key: 'payment',
    figure: <PaymentFigure />,
    align: 'flex-end',
    title: 'Efetue o pagamento via Pix',
    text: 'Efetue o pagamento dos pontos por pix e salve o comprovante.',
  },
  {
    key: 'to-send',
    figure: <ToSendFigure />,
    align: 'center',
    title: '⁠Enviar solicitação no Milhas na conta',
    text: 'Acessar a área logada e iniciar solicitação de crédito de pontos.',
  },
  {
    key: 'receipt',
    figure: <ReceiptFigure />,
    align: 'flex-end',
    title: '⁠Anexar o comprovante de pagamento',
    text: 'O 1º passo da solicitação é anexar o comprovante de pagamento.',
  },
  {
    key: 'download',
    figure: <DownloadFigure />,
    align: 'center',
    title: '⁠Baixar a planilha modelo',
    text: 'Preencher a planilha padrão conforme orientações e anexá-la.',
  },
  {
    key: 'resume',
    figure: <ResumeFigure />,
    align: 'flex-end',
    title: '⁠Conferir o resumo e enviar a solicitação',
    text: 'Conferir os dados, concluir a solicitação e aguardar o crédito.',
  },
];

export const HowItWorks = (): React.ReactElement => {
  return (
    <StyledContainer id="como-funciona">
      <h3 className="section-title">Como funciona?</h3>
      <StyledSubtitle>
        A compra de pontos é super <strong>simples e segura</strong>, em poucos
        minutos o processo é finalizado!
      </StyledSubtitle>

      <StyledCarousel
        spaceBetween={24}
        slidesPerView="auto"
        modules={[Pagination]}
        pagination={{ clickable: true }}
      >
        {MOCK.map((item, index) => (
          <StyledItem key={item.key}>
            <Card>
              <Card.Figure style={{ alignItems: item.align }}>
                {item.figure}
              </Card.Figure>
              <Card.Content>
                <Card.Step>0{index + 1}</Card.Step>
                <Card.Title>{item.title}</Card.Title>
                <Card.Text>{item.text}</Card.Text>
              </Card.Content>
            </Card>
          </StyledItem>
        ))}
      </StyledCarousel>

      <StyledActionButton onClick={handleGoToTop} noShadow>
        Aproveitar a promoção
      </StyledActionButton>
    </StyledContainer>
  );
};
