import { useRecoilState } from 'recoil';

import authenticateAtom from '../atoms/authenticate';

const decodeToken = (token: string): { id: string; role: string } => {
  const tokenSplited = token.split('.');
  const payload = tokenSplited[1];
  const payloadCleaned = payload.replace('-', '+').replace('_', '/');
  const payloadDecoded = decodeURIComponent(escape(atob(payloadCleaned)));
  const payloadParsed = JSON.parse(payloadDecoded);

  return payloadParsed;
};

const useAuthenticate = (): any => {
  const [authenticate, setAuthenticate] = useRecoilState(authenticateAtom);

  const signin = (token: string, showLandingPage?: boolean) => {
    const decode = decodeToken(token);
    setAuthenticate({
      isAuthenticate: true,
      token,
      user: decode,
      showLandingPage,
    });
  };

  const logout = () => {
    setAuthenticate({
      isAuthenticate: false,
      token: '',
      user: null,
      showLandingPage: false,
    });
  };

  const updateUserNewsletter = () => {
    setAuthenticate({
      ...authenticate,
      user: {
        ...authenticate.user,
        isSubscribedNewsletter: true,
      },
    });
  };

  return {
    authenticate,
    setAuthenticate,
    signin,
    logout,
    updateUserNewsletter,
  };
};

export default useAuthenticate;
