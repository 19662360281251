import styled from 'styled-components';

export const StyledRoot = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const StyledText = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  color: #777;
  margin-top: 30px;
  font-family: Poppins;
`;
