import styled from 'styled-components';

export const StyledSection = styled.section`
  max-width: var(--container-max-width);
  margin: 40px auto;
  padding: 16px 16px 32px;
`;

export const StyledList = styled.ul`
  display: grid;
  gap: 24px;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const StyledListItem = styled.li`
  display: flex;
  gap: 16px;
  list-style: none;
  line-height: 1.5;

  &::before {
    display: block;
    content: '';
    width: 22px;
    height: 22px;
    flex: 0 0 22px;
    background: url('/images/landing-page/check.svg') no-repeat center;
    background-size: contain;
  }
`;
