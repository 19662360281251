import React from 'react';
import { StyledList, StyledListItem, StyledSection } from './styles';

export const WarningList = (): React.ReactElement => {
  return (
    <StyledSection id="recomendacoes">
      <h3 className="section-title">Importante!</h3>

      <StyledList>
        <StyledListItem>
          É obrigatório o cadastro no site da cia.
        </StyledListItem>
        <StyledListItem>
          ⁠O crédito dos pontos é diário (entre 16h e 23h) para solicitações
          concluídas até meio dia.
        </StyledListItem>
        <StyledListItem>
          O crédito do bônus é de responsabilidade da cia e tem prazo informado
          no regulamento da campanha.
        </StyledListItem>
        <StyledListItem>
          É possível agrupar o envio de várias contas em apenas um pagamento e
          uma planilha
        </StyledListItem>
        <StyledListItem>
          É recomendado printar a confirmação do cadastro (tela cheia)
        </StyledListItem>
        <StyledListItem>
          O sistema divide automaticamente o crédito em lotes de 100k
        </StyledListItem>
      </StyledList>
    </StyledSection>
  );
};
