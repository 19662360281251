import React from 'react';

import { toast } from 'react-toastify';
import { StyledRoot } from './styles';

const BannerPix = (): React.ReactElement => {
  const handleCopyPix = () => {
    navigator.clipboard.writeText('54264159000142');

    toast('Chave copiada com sucesso!', {
      type: 'success',
    });
  };

  return (
    <StyledRoot onClick={handleCopyPix}>
      <source
        media="(min-width: 900px)"
        srcSet="/images/banner-pix.desktop.png"
      />
      <img src="/images/banner-pix.mobile.png" alt="Banner" />
    </StyledRoot>
  );
};

export default BannerPix;
