import React, { ReactElement } from 'react';

// import Loading from './components/Loading';

interface Props {
  children: ReactElement;
}

const Connect: React.FC<Props> = ({ children }): ReactElement => {
  // const [isConnected, setIsConnected] = React.useState(false);
  // const [isLoading, setIsLoading] = React.useState(false);

  // React.useEffect(() => {
  //   setTimeout(() => {
  //     setIsConnected(true);
  //     setIsLoading(false);
  //   }, 1000);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // if (isLoading) {
  //   return <Loading />;
  // }

  return <React.Fragment>{children}</React.Fragment>;
};

export default Connect;
