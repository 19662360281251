/* eslint-disable no-underscore-dangle */
import AddIcon from '@mui/icons-material/Add';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import PaidIcon from '@mui/icons-material/Paid';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import * as React from 'react';
import Lottie from 'react-lottie';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  Div,
  Div2,
  StyleContentTitleContainer,
  StyledAreaContainer,
  StyledContentButtonsHeader,
  StyledContentContainer,
  StyledContentFilterStatus,
  StyledContentPaymentField,
  StyledHeaderTitlePage,
  StyledLoading,
  StyledPlaneContainer,
  StyledRequestsContainer,
  StyledRequestsContainerTable,
  StyledSpanContent,
} from './styles';

import {
  deleteRequest,
  downloadRequestAttachmentByUser,
  getAllRequestByUser,
  previewRequestAttachmentByUser,
  updateRequestStatus,
} from '../../api/request';
import { ButtonWithIcon } from '../../components/Button';
import ModalExportReportRequests from '../../components/ModalExportReportRequests';
import NewLoggedHeader from '../../components/NewLoggedHeader';
import TableCollapse from '../../components/TableCollapse';
import Plane from '../../images/Plane';
import useAuthenticate from '../../recoil/hooks/authenticate';
import { formatOnlyHour, formatShortDate } from '../../utils/date';
import { maskCpf } from '../../utils/mask';
import { formatMoney } from '../../utils/money';
import { formatNumber } from '../../utils/number';
import animationData from './loading.json';

import ModalEditRequestStatus from './ModalEditRequestStatus';

import { getAllExtract, vinculateExtract } from '../../api/extract';
import BannerPix from '../../components/BannerPix';
import ModalPreviewImage from '../../components/ModalPreviewImage';
import SimpleSelect from '../Login/Select';
import ModalDeleteRequest from './ModalDeleteRequest';
import { RequestStatusEnum, requestStatusLabel } from './utils';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const Home = (): React.ReactElement => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = React.useState(true);

  const [isLoadingPreviewImage, setLoadingPreviewImage] = React.useState(true);

  const [openModalExport, setOpenModalExport] = React.useState(false);

  const [modalUpdateStatus, setModalUpdateStatus] = React.useState({
    open: false,
    isLoading: false,
    requestId: '',
    defaultStatus: RequestStatusEnum.pending,
  });

  const [modalDeleteRequest, setModalDeleteRequest] = React.useState({
    open: false,
    isLoading: false,
    requestId: '',
  });

  const [modalPreview, setModalPreview] = React.useState({
    open: false,
    fileId: '',
    url: '',
    typeFile: '',
    payments: [],
  });

  const [count, setCount] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [itemsPerPage, setItemsPerPage] = React.useState(10);

  const [filterStatus, setFilterStatus] = React.useState(
    RequestStatusEnum.open
  );

  const { authenticate } = useAuthenticate();

  const [data, setData] = React.useState([]);

  const isPartner = React.useMemo(
    () => ['admin', 'partner'].includes(authenticate?.user?.role),
    [authenticate]
  );

  const isAdmin = React.useMemo(
    () => ['admin'].includes(authenticate?.user?.role),
    [authenticate]
  );

  const prepareData = (data: any) => {
    return data.map((item: any) => {
      const requestValue = item.accounts?.length
        ? formatMoney(
            item.accounts.reduce((prev: number, actual: any) => {
              return prev + actual.totalValue;
            }, 0)
          )
        : formatMoney('0');

      const paymentsValue = item.payments?.length
        ? formatMoney(
            item.payments.reduce((prev: number, actual: any) => {
              return prev + (actual.value || 0);
            }, 0)
          )
        : formatMoney('0');
      const formatValue = (
        paymentsValue: string,
        requestValue: string
      ): React.ReactElement | string => {
        if (isAdmin) {
          if (paymentsValue === requestValue) {
            return (
              <StyledContentPaymentField>
                <PaidIcon htmlColor="green" />
                <div style={{ width: 120 }}>{requestValue}</div>
              </StyledContentPaymentField>
            );
          }

          return (
            <StyledContentPaymentField>
              <WarningRoundedIcon htmlColor="orange" />
              <div style={{ width: 120 }}>
                {`${paymentsValue} / ${requestValue}`}
              </div>
            </StyledContentPaymentField>
          );
        }

        return requestValue;
      };

      return {
        id: item._id,
        status: item.status,
        statusLabel: requestStatusLabel(item.status),
        shortId: item._id.substring(17, item._id.length),
        date: formatShortDate(item.createdAt),
        hour: formatOnlyHour(item.createdAt),
        payments: item.payments.length,
        accounts: item.accounts.length,
        user: item.user.name,
        email: item.user.email,
        cpf: maskCpf(item.user.cpf),
        paymentsData: item.payments.map((payment: any) => ({
          id: payment._id,
          shortId: payment._id.substring(17, payment._id.length),
          name: payment.name,
          transaction: payment.transaction,
        })),
        accountsData: item.accounts.map((account: any) => ({
          id: account._id,
          shortId: account._id.substring(17, account._id.length),
          name: account.name,
        })),
        code: item.code || '-',
        descriptionCode: item.descriptionCode || '-',

        value: formatValue(paymentsValue, requestValue),

        miles: item.accounts?.length
          ? formatNumber(
              item.accounts.reduce((prev: number, actual: any) => {
                return prev + actual.miles;
              }, 0)
            )
          : formatNumber('0'),
      };
    });
  };

  const dataPrepared = React.useMemo(() => {
    if (data) {
      return prepareData(data);
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const getData = React.useCallback(
    async (filterStatus: RequestStatusEnum) => {
      try {
        setIsLoading(true);
        const data = await getAllRequestByUser(
          authenticate.token,
          filterStatus,
          page,
          itemsPerPage
        );

        setData(data.data);
        setCount(data.count || 0);
      } catch (error) {
        toast(
          'Não foi possível realizar sua solicitação. Por favor, tente novamente!',
          {
            type: 'error',
          }
        );
      } finally {
        setIsLoading(false);
      }
    },
    [authenticate.token, page, itemsPerPage]
  );

  React.useEffect(() => {
    if (authenticate.isAuthenticate) {
      getData(filterStatus);
    }
  }, [authenticate.isAuthenticate, filterStatus, getData]);

  const handleDownloadFile = async (
    fileId: string,
    filename: string
  ): Promise<void> => {
    try {
      await downloadRequestAttachmentByUser(
        fileId,
        filename,
        authenticate.token
      );
    } catch (error) {
      toast(
        'Não foi possível realizar o download. Por favor, tente novamente!',
        {
          type: 'error',
        }
      );
    }
  };

  const handlePreviewFile = async (
    fileId: string,
    filename: string,
    payments: any
  ): Promise<void> => {
    try {
      setLoadingPreviewImage(true);

      setModalPreview({
        fileId,
        open: true,
        url: '',
        typeFile: '',
        payments,
      });

      const { urlFile, typeFile } = await previewRequestAttachmentByUser(
        fileId,
        authenticate.token
      );

      setModalPreview({
        fileId,
        open: true,
        url: urlFile,
        typeFile,
        payments,
      });
    } catch (error) {
      toast(
        'Não foi possível visualizar a imagem. Por favor, tente novamente!',
        {
          type: 'error',
        }
      );
    } finally {
      setLoadingPreviewImage(false);
    }
  };

  const rowKeys = React.useMemo(() => {
    let keys = [
      'shortId',
      'email',
      'descriptionCode',
      'date',
      'value',
      'miles',
    ];

    if (isPartner) {
      keys = [
        'shortId',
        'email',
        'code',
        'descriptionCode',
        'date',
        'value',
        'miles',
      ];
    }

    keys.push('statusLabel');

    return keys;
  }, [isPartner]);

  const headKeys = React.useMemo(() => {
    let keys = ['ID', 'E-mail', 'Promoção', 'Data', 'Valor', 'Milhas'];
    if (isPartner) {
      keys = ['ID', 'E-mail', 'Código', 'Promoção', 'Data', 'Valor', 'Milhas'];
    }

    keys.push('Status');

    return keys;
  }, [isPartner]);

  const handleChangeStatus = (id: string, status: RequestStatusEnum): void => {
    setModalUpdateStatus({
      open: true,
      isLoading: false,
      requestId: id,
      defaultStatus: status,
    });
  };

  const handleDeleteRequest = (id: string) => {
    setModalDeleteRequest({
      open: true,
      isLoading: false,
      requestId: id,
    });
  };

  const handleSubmitDeleteRequest = async (): Promise<void> => {
    setModalDeleteRequest({ ...modalDeleteRequest, isLoading: true });
    try {
      await deleteRequest(modalDeleteRequest.requestId, authenticate.token);

      setModalDeleteRequest({
        ...modalDeleteRequest,
        open: false,
        isLoading: false,
      });

      toast('Solicitação deletada com sucesso!', {
        type: 'success',
      });

      getData(filterStatus);
    } catch (error) {
      setModalDeleteRequest({
        ...modalDeleteRequest,
        isLoading: false,
      });
      toast(
        'Não foi possível deletar a solicitação. Por favor, tente novamente!',
        {
          type: 'error',
        }
      );
    }
  };

  const handleSubmitUpdateRequestStatus = async (
    values: any
  ): Promise<void> => {
    setModalUpdateStatus({ ...modalUpdateStatus, isLoading: true });
    try {
      await updateRequestStatus(
        values.status,
        modalUpdateStatus.requestId,
        authenticate.token
      );
      setModalUpdateStatus({
        ...modalUpdateStatus,
        open: false,
        isLoading: false,
      });

      toast('Status da solicitação atualizado com sucesso!', {
        type: 'success',
      });

      getData(filterStatus);
    } catch (error) {
      setModalUpdateStatus({ ...modalUpdateStatus, isLoading: false });
      toast(
        'Não foi possível realizar sua solicitação. Por favor, tente novamente!',
        {
          type: 'error',
        }
      );
    }
  };

  const [dataExtract, setDataExtract] = React.useState([]);

  const getDataExtract = React.useCallback(
    async (search?: string) => {
      try {
        const data = await getAllExtract(authenticate.token, search, 'active');

        setDataExtract(data.data);
      } catch (error) {
        toast(
          'Não foi possível realizar sua solicitação. Por favor, tente novamente!',
          {
            type: 'error',
          }
        );
      }
    },
    [authenticate.token]
  );

  React.useEffect(() => {
    if (authenticate?.isAuthenticate && authenticate?.user?.role === 'admin') {
      getDataExtract();
    }
  }, [authenticate, getDataExtract]);

  const handleSelectExtract = async (id: string) => {
    try {
      await vinculateExtract(authenticate.token, modalPreview.fileId, id);

      getDataExtract();

      toast('Comprovante vinculado com sucesso!', {
        type: 'success',
      });
    } catch (error) {
      toast(
        'Não foi possível realizar essa ação. Por favor, tente novamente!',
        {
          type: 'error',
        }
      );
    }
  };

  return (
    <Div>
      <ModalPreviewImage
        open={modalPreview.open}
        fileId={modalPreview.fileId}
        url={modalPreview.url}
        typeFile={modalPreview.typeFile}
        payments={modalPreview.payments}
        isLoading={isLoadingPreviewImage}
        handleClose={() => setModalPreview({ ...modalPreview, open: false })}
        handlePreviewFile={handlePreviewFile}
        handleSelectExtract={handleSelectExtract}
        extracts={dataExtract}
      />
      <ModalExportReportRequests
        open={openModalExport}
        handleClose={() => setOpenModalExport(false)}
      />
      <ModalEditRequestStatus
        open={modalUpdateStatus.open}
        defaultValues={{ status: modalUpdateStatus.defaultStatus }}
        isLoading={modalUpdateStatus.isLoading}
        handleClose={() =>
          setModalUpdateStatus({ ...modalUpdateStatus, open: false })
        }
        handleSubmit={handleSubmitUpdateRequestStatus}
      />

      <ModalDeleteRequest
        open={modalDeleteRequest.open}
        isLoading={modalDeleteRequest.isLoading}
        handleClose={() =>
          setModalDeleteRequest({ ...modalDeleteRequest, open: false })
        }
        handleSubmit={handleSubmitDeleteRequest}
      />

      <Div2>
        {/* <LoggedHeader /> */}
        <NewLoggedHeader />

        <BannerPix />

        <StyledHeaderTitlePage>
          <h1>Histórico de solicitações</h1>
          <StyledContentButtonsHeader>
            {authenticate?.user?.role === 'admin' && (
              <React.Fragment>
                <StyledContentFilterStatus>
                  <SimpleSelect
                    name="status"
                    value={filterStatus}
                    onChange={(event) => {
                      setPage(0);
                      setFilterStatus(event.target.value);
                    }}
                    options={[
                      {
                        value: RequestStatusEnum.all,
                        label: requestStatusLabel(RequestStatusEnum.all),
                      },
                      {
                        value: RequestStatusEnum.open,
                        label: requestStatusLabel(RequestStatusEnum.open),
                      },
                      {
                        value: RequestStatusEnum.pending,
                        label: requestStatusLabel(RequestStatusEnum.pending),
                      },
                      {
                        value: RequestStatusEnum.analysing,
                        label: requestStatusLabel(RequestStatusEnum.analysing),
                      },
                      {
                        value: RequestStatusEnum.confirmed,
                        label: requestStatusLabel(RequestStatusEnum.confirmed),
                      },
                      {
                        value: RequestStatusEnum.completed,
                        label: requestStatusLabel(RequestStatusEnum.completed),
                      },
                    ]}
                  />
                </StyledContentFilterStatus>
                <ButtonWithIcon
                  label="Exportar"
                  icon={<FileDownloadOutlinedIcon />}
                  onClick={() => setOpenModalExport(true)}
                />
              </React.Fragment>
            )}
            <ButtonWithIcon
              label="Nova solicitação"
              icon={<AddIcon />}
              onClick={() => navigate('/new-request')}
            />
          </StyledContentButtonsHeader>
        </StyledHeaderTitlePage>

        {isLoading && (
          <StyledLoading>
            <Lottie options={defaultOptions} height={150} width={150} />
            <div>Carregando</div>
          </StyledLoading>
        )}

        {!isLoading && !data.length && (
          <StyledRequestsContainer>
            <StyleContentTitleContainer>
              <span>Você ainda não tem solicitações :(</span>
            </StyleContentTitleContainer>
            <StyledContentContainer>
              <StyledAreaContainer
                onClick={() => {
                  navigate('/new-request');
                }}
              >
                <StyledPlaneContainer>
                  <Plane />
                </StyledPlaneContainer>
                <StyledSpanContent>
                  <strong>
                    <u>Clique aqui</u>
                  </strong>{' '}
                  para fazer sua primeira solicitação.
                </StyledSpanContent>
              </StyledAreaContainer>
            </StyledContentContainer>
          </StyledRequestsContainer>
        )}

        {!isLoading && !!data.length && (
          <StyledRequestsContainerTable className="table-custom">
            <TableCollapse
              data={dataPrepared}
              rows={rowKeys}
              head={headKeys}
              handleDownload={handleDownloadFile}
              handlePreview={handlePreviewFile}
              page={page}
              itemsPerPage={itemsPerPage}
              changeItemsPerPage={setItemsPerPage}
              changePage={setPage}
              count={count}
              removeRequest={
                authenticate?.user?.role === 'admin'
                  ? handleDeleteRequest
                  : undefined
              }
              handleChangeStatus={
                authenticate?.user?.role === 'admin'
                  ? handleChangeStatus
                  : undefined
              }
              isAdmin={authenticate?.user?.role === 'admin'}
            />
          </StyledRequestsContainerTable>
        )}
      </Div2>
    </Div>
  );
};

export default Home;
