import styled from 'styled-components';

import { Box } from '@mui/material';

export const StyledBox = styled(Box)({
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  borderRadius: 4,
  color: '#212529',
  background: '#fff',
  padding: 32,
  boxSizing: 'border-box',
  p: 4,
});

export const FormLoginStyled = styled.form`
  width: 100%;
  height: 100%;
`;

export const Div7 = styled.div`
  font-family: Rubik, sans-serif;
  margin-top: 32px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

export const StyledContentButtons = styled.div`
  display: flex;
  justify-content: space-between;
`;

// -------------------------------------------------------- form upload

export const Div18 = styled.div`
  display: flex;
  flex-grow: 1;
  flex-basis: 0%;
  flex-direction: column;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

export const Div20 = styled.div`
  color: #878a99;
  margin-top: 12px;
  font: 275 12px Poppins, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

export const Div25 = styled.div`
  display: flex;
  flex-grow: 1;
  flex-basis: 0%;
  flex-direction: column;
`;

export const Div28 = styled.div`
  color: #878a99;
  margin-top: 5px;
  font: italic 300 10px Poppins, sans-serif;
`;

export const Div38 = styled.div`
  font-family: Poppins, sans-serif;
  margin: auto 0;
`;

export const StyledContentFiles = styled.div`
  color: #878a99;
  margin-top: 5px;
  font: italic 300 10px Poppins, sans-serif;

  display: flex;
  flex-direction: column;
`;

export const StyledContentFilesItem = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;
  box-sizing: border-box;
`;
