import styled from 'styled-components';

export const StyledFooter = styled('div')({
  width: '100%',
  fontSize: '12px',
  color: '#777',
  padding: '10px 16px',
  boxSizing: 'border-box',
  backdropFilter: 'blur(5px)',
  fontFamily: 'Poppins',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

  '@media (min-width: 768px)': {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});
