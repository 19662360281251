export const handleGoToTop = (): void => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};

export const handleGoToSection = (id: string): void => {
  const element = document.getElementById(id);

  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
};
