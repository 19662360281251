import * as React from 'react';
import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Modal,
  Typography,
} from '@mui/material';
import { toast } from 'react-toastify';
import {
  Div9,
  FormLoginStyled,
  StyledBox,
  StyledContentButtons,
} from './styles';
import SimpleInput from '../../Login/Input';
import SubmitButton, { Button } from '../../../components/Button';
import { formatMoney, removeMaskMoney } from '../../../utils/money';

interface Props {
  open: boolean;
  title: string;
  isLoading: boolean;
  defaultValues?: {
    name: string;
    code: string;
    url: string;
    partnerValue: string;
    useValueClientSheet: boolean;
    valueMiles: string;
  };
  handleClose: () => void;
  handleSubmit: (values: any) => void;
}

const ModalAddCode = ({
  open,
  title,
  isLoading,
  defaultValues,
  handleClose,
  handleSubmit,
}: Props): React.ReactElement => {
  const [values, setValues] = React.useState({
    name: '',
    code: '',
    url: '',
    partnerValue: '',
    useValueClientSheet: false,
    valueMiles: '',
  });

  React.useEffect(() => {
    if (!open) {
      setValues({
        name: '',
        code: '',
        url: '',
        partnerValue: '',
        useValueClientSheet: false,
        valueMiles: '',
      });
    }
  }, [open]);

  React.useEffect(() => {
    if (defaultValues) {
      setValues({ ...defaultValues });
    }
  }, [defaultValues]);

  const handleChange = (event: any): void => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const isValidUrl = (url: string): boolean => {
    try {
      const newUrl = new URL(url);
      return !!newUrl;
    } catch (error) {
      return false;
    }
  };

  const submit = (e: any): void => {
    e.preventDefault();

    if (!values.name || !values.code) {
      toast('Por favor, preencha os campos antes de submeter o formulário.', {
        type: 'error',
      });
      return;
    }

    const partnerValue = removeMaskMoney(values.partnerValue);
    const valueMiles = removeMaskMoney(values.valueMiles);

    if (!values.useValueClientSheet && !Number(partnerValue)) {
      toast(
        'Por favor, preencha o campo Milheiro do parceiro ou use o valor da planilha.',
        {
          type: 'error',
        }
      );
      return;
    }

    if (!Number(valueMiles)) {
      toast('Por favor, preencha o campo Custo do milheiro.', {
        type: 'error',
      });
      return;
    }

    if (values.url && !isValidUrl(values.url)) {
      toast('Por favor, informe uma URL válida.', {
        type: 'error',
      });
      return;
    }

    handleSubmit({ ...values, partnerValue, valueMiles });
  };

  const handleChangeValue = (event: any): void => {
    const valueWithoutMask = event.target.value
      ? removeMaskMoney(event.target.value)
      : '0';
    const newValue = Number.isNaN(Number(valueWithoutMask))
      ? 0
      : Number(valueWithoutMask) / 100;
    setValues({
      ...values,
      [event.target.name]: formatMoney(newValue.toString()),
    });
  };

  return (
    <Modal open={open}>
      <StyledBox>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {title}
        </Typography>
        <FormLoginStyled>
          <Div9>Descrição</Div9>
          <SimpleInput
            placeholder={'Descrição'}
            name="name"
            onChange={handleChange}
            value={values.name}
          />

          <Div9>Código</Div9>
          <SimpleInput
            placeholder={'Código'}
            name="code"
            onChange={handleChange}
            value={values.code}
          />

          <Div9>URL da promoção</Div9>
          <SimpleInput
            placeholder={'URL da promoção'}
            name="url"
            type="url"
            onChange={handleChange}
            value={values.url}
          />

          <Div9>Milheiro do parceiro</Div9>
          <SimpleInput
            placeholder={'Milheiro do parceiro'}
            name="partnerValue"
            type="string"
            disabled={values.useValueClientSheet}
            onChange={handleChangeValue}
            value={values.partnerValue}
          />

          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  value={values.useValueClientSheet}
                  onChange={(e) =>
                    setValues({
                      ...values,
                      useValueClientSheet: e.target.checked,
                    })
                  }
                />
              }
              label="Usar o valor da planilha"
            />
          </FormGroup>

          <Div9>Custo do milheiro</Div9>
          <SimpleInput
            placeholder={'Custo do milheiro'}
            name="valueMiles"
            type="string"
            onChange={handleChangeValue}
            value={values.valueMiles}
          />

          <StyledContentButtons>
            <div style={{ width: '45%' }}>
              <Button onClick={handleClose} disabled={isLoading}>
                {isLoading ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <span>Cancelar</span>
                )}
              </Button>
            </div>
            <div style={{ width: '45%' }}>
              <SubmitButton onClick={submit} disabled={isLoading}>
                {isLoading ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <span>Salvar</span>
                )}
              </SubmitButton>
            </div>
          </StyledContentButtons>
        </FormLoginStyled>
      </StyledBox>
    </Modal>
  );
};

export default ModalAddCode;
