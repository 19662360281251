import api from '../services/api';

interface NewsletterRequest {
  name: string;
  whatsapp: string;
  email: string;
}

export const submitNewsletter = async (
  data: NewsletterRequest,
  token?: string
): Promise<any> => {
  const result = await api.post(`/api/newsletter`, data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  return result?.data;
};

export default {
  submitNewsletter,
};
