export default {
  campaignEnabled: true,
  hero: [
    {
      images: {
        mobile: '/images/landing-page/banner-01.mobile.png',
        desktop: '/images/landing-page/banner-01.desktop.png',
      },
      callToAction: {
        text: 'Comprar pontos',
        url: 'https://milhasnaconta.com.br/#/?code=43g8',
      },
      rules: {
        url: 'https://www.voeazul.com.br/br/pt/programa-fidelidade/promocoes/promobancos',
      },
      date: 'Válido de 15.07.24 até 16.07.24 às 12h.',
    },
    // {
    //   images: {
    //     mobile: '/images/landing-page/banner-02.mobile.png',
    //     desktop: '/images/landing-page/banner-02.desktop.png',
    //   },
    //   callToAction: {
    //     text: 'Comprar pontos',
    //     url: 'https://milhasnaconta.com.br/#/login?code=43g8',
    //   },
    //   rules: {
    //     url: 'https://www.voeazul.com.br/br/pt/programa-fidelidade/promocoes/promobancos',
    //   },
    //   date: 'Válido de 15.07.24 até 16.07.24 às 12h.',
    // },
  ],
};
