import { FormControl, MenuItem, Select } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

const InputContainer = styled.div`
  margin-bottom: 20px;
  display: flex;
`;

const StyledSelect = styled(Select)({
  // background: 'red',
  fontFamily: 'Rubik, sans-serif !important',
  borderRadius: '4px',
  border: '1px solid #ced4da',
  marginTop: '13px',
  justifyContent: 'center',
  alignItems: 'start',
  color: '#878a99 !important',
  whiteSpace: 'nowrap',
  '@media (max-width: 991px)': {
    whiteSpace: 'initial',
    maxWidth: '100%',
  },
  backgroundColor: 'rgba(121, 204, 114, 0.22)',
  height: 43,
  width: '100%',
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#000 !important',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: '#000 !important',
  },
});

interface Option {
  value: string;
  label: string;
}

interface SimpleSelectProps {
  value?: string;
  onChange?: (event?: any) => void;
  name?: string;
  label?: string;
  options: Option[];
}

const SimpleSelect = ({
  value,
  onChange,
  name,
  label,
  options,
}: SimpleSelectProps): any => {
  return (
    <InputContainer>
      <FormControl fullWidth>
        <StyledSelect
          name={name}
          variant="outlined"
          label={label}
          value={value}
          onChange={onChange}
        >
          {options.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </StyledSelect>
      </FormControl>
    </InputContainer>
  );
};

export default SimpleSelect;
