import React from 'react';
import { StyledButton } from './styles';

interface ActionButtonProps
  extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  noShadow?: boolean;
}

export const ActionButton = ({
  children,
  noShadow,
  ...props
}: ActionButtonProps): React.ReactElement => (
  <StyledButton noShadow={noShadow} {...props}>
    {children}
  </StyledButton>
);
