import React from 'react';

export const ToSendFigure = (): React.ReactElement => (
  <svg xmlns="http://www.w3.org/2000/svg" width={224} height={121} fill="none">
    <path
      fill="#D6EF89"
      d="M119.139 7.065v49.21c0 2.158-1.736 3.908-3.877 3.908H15.455c-2.14 0-3.877-1.75-3.877-3.907V7.066h107.561Z"
    />
    <path
      fill="#C5E96D"
      d="M119.139 3.908v3.154H11.578V3.908C11.578 1.75 13.315 0 15.455 0h99.807c2.141 0 3.877 1.75 3.877 3.908Z"
    />
    <path
      fill="#044330"
      d="M17.939 3.904a1.342 1.342 0 0 0-1.103-1.541 1.335 1.335 0 0 0-1.53 1.11 1.343 1.343 0 0 0 1.103 1.542 1.334 1.334 0 0 0 1.53-1.11Z"
    />
    <path
      fill="#fff"
      d="M21.833 3.904a1.342 1.342 0 0 0-1.103-1.542 1.335 1.335 0 0 0-1.53 1.111 1.342 1.342 0 0 0 1.103 1.542 1.335 1.335 0 0 0 1.53-1.111Zm3.894-.001a1.342 1.342 0 0 0-1.102-1.541 1.335 1.335 0 0 0-1.53 1.11 1.343 1.343 0 0 0 1.103 1.542 1.335 1.335 0 0 0 1.53-1.11Zm93.412 3.304H11.578a.14.14 0 0 1-.14-.142.14.14 0 0 1 .14-.142h107.561c.078 0 .141.064.141.142a.141.141 0 0 1-.141.142Zm-11.072 15.024c0 3.917-3.151 7.092-7.037 7.092H22.184c-3.886 0-7.037-3.175-7.037-7.092 0-3.916 3.151-7.092 7.037-7.092h78.846c3.886 0 7.037 3.176 7.037 7.092Z"
    />
    <path
      fill="#044330"
      d="M25.598 20.933a1.75 1.75 0 0 0 1.743-1.757c0-.97-.78-1.756-1.743-1.756a1.75 1.75 0 0 0-1.742 1.756c0 .97.78 1.757 1.742 1.757Zm0 1.03c-2.095 0-3.88-.033-4.544 1.85.664 1.883 2.449 3.233 4.544 3.233 2.096 0 3.88-1.35 4.544-3.233-.663-1.883-2.448-1.85-4.544-1.85Z"
    />
    <path
      fill="#C5E96D"
      d="M38.246 19.893h38.27a2.332 2.332 0 0 1 2.324 2.341 2.332 2.332 0 0 1-2.323 2.341h-38.27a2.332 2.332 0 0 1-2.323-2.34 2.332 2.332 0 0 1 2.322-2.342Z"
    />
    <path
      fill="#081906"
      d="M88.286 26.778h-52.36a.14.14 0 0 1-.14-.142.14.14 0 0 1 .14-.142h52.36a.14.14 0 0 1 .14.142.14.14 0 0 1-.14.142Z"
    />
    <path
      fill="#044330"
      d="M85.954 24.593a2.34 2.34 0 0 0 2.332-2.35 2.34 2.34 0 0 0-2.332-2.35 2.34 2.34 0 0 0-2.331 2.35 2.34 2.34 0 0 0 2.331 2.35Z"
    />
    <path
      fill="#fff"
      d="M85.817 23.319a.128.128 0 0 1-.093-.036l-.909-.829a.14.14 0 0 1-.009-.198.137.137 0 0 1 .197-.01l.793.724 1.09-1.573a.142.142 0 0 1 .195-.036.144.144 0 0 1 .036.195l-1.184 1.703a.15.15 0 0 1-.099.06h-.017Zm22.25 19.601c0 3.917-3.151 7.092-7.037 7.092H22.184c-3.886 0-7.037-3.175-7.037-7.092s3.151-7.092 7.037-7.092h78.846c3.886 0 7.037 3.175 7.037 7.092Z"
    />
    <path
      fill="#044330"
      d="M28.839 42.311h-6.481a.444.444 0 0 0-.443.443v1.956a3.014 3.014 0 0 0 3.008 3.021h1.348a3.014 3.014 0 0 0 3.007-3.022v-1.955a.442.442 0 0 0-.442-.443h.003Zm-.613-.518a.272.272 0 0 1-.272-.27v-.92c0-1.075-.87-1.95-1.94-1.95h-.832c-1.07 0-1.94.875-1.94 1.95v.92a.272.272 0 0 1-.544 0v-.92a2.49 2.49 0 0 1 2.481-2.494h.832a2.49 2.49 0 0 1 2.48 2.494v.92a.27.27 0 0 1-.271.27h.006Z"
    />
    <path
      fill="#fff"
      d="M26.265 44.131a.667.667 0 0 0-.664-.669.665.665 0 0 0-.664.67c0 .252.14.472.347.584v1.286c0 .178.144.32.32.32a.32.32 0 0 0 .32-.32v-1.287a.67.67 0 0 0 .347-.584h-.006Z"
    />
    <path
      fill="#C5E96D"
      d="M36.052 45.912a.14.14 0 0 1-.14-.142v-4.612a.14.14 0 0 1 .14-.142.14.14 0 0 1 .14.142v4.612a.14.14 0 0 1-.14.142Z"
    />
    <path
      fill="#081906"
      d="M88.286 47.975h-52.36a.14.14 0 0 1-.14-.141.14.14 0 0 1 .14-.142h52.36a.14.14 0 0 1 .14.142.14.14 0 0 1-.14.141Z"
    />
    <path
      fill="#044330"
      d="M85.954 45.812a2.34 2.34 0 0 0 2.332-2.35 2.34 2.34 0 0 0-2.332-2.35 2.34 2.34 0 0 0-2.331 2.35 2.34 2.34 0 0 0 2.331 2.35Z"
    />
    <path
      fill="#fff"
      d="M85.954 44.07a.14.14 0 0 1-.14-.14v-1.76a.14.14 0 0 1 .14-.142.14.14 0 0 1 .14.142v1.76a.14.14 0 0 1-.14.14Zm0 .796a.135.135 0 0 1-.099-.042.142.142 0 0 1-.041-.1c0-.017.003-.035.012-.054a.12.12 0 0 1 .03-.045.092.092 0 0 1 .044-.03.134.134 0 0 1 .108 0 .119.119 0 0 1 .045.03.15.15 0 0 1 .042.1.142.142 0 0 1-.14.141Zm37.033-18.336-5.761 16.96a3.184 3.184 0 0 1-2.4 2.105l-2.565.503s6.669 14.58 17.909 12.136c11.24-2.444 8.768-12.136 8.768-12.136l-2.376-.192c-1.423-.115-2.401-1.498-2.042-2.89l2.672-10.424s-10.89-8.84-14.202-6.065l-.003.003Z"
    />
    <path
      fill="#081906"
      d="M127.68 58.725c-3.799 0-7.503-1.868-10.842-5.492-3.061-3.326-4.762-7.005-4.777-7.041a.228.228 0 0 1 .003-.193.212.212 0 0 1 .152-.114l2.565-.504a2.977 2.977 0 0 0 2.236-1.96l5.761-16.96a.23.23 0 0 1 .065-.1c3.411-2.858 14.03 5.695 14.481 6.062a.225.225 0 0 1 .075.226l-2.673 10.425a2.1 2.1 0 0 0 .32 1.738 2.07 2.07 0 0 0 1.528.874l2.376.193a.225.225 0 0 1 .195.165c.042.163.989 4.02-1.163 7.532-1.495 2.44-4.111 4.08-7.77 4.875a11.9 11.9 0 0 1-2.535.274h.003Zm-15.099-12.464c.436.883 2.03 3.905 4.586 6.68 4.017 4.365 8.379 6.073 12.956 5.079 3.536-.768 6.056-2.341 7.488-4.67 1.824-2.97 1.297-6.3 1.148-7.044l-2.215-.18a2.514 2.514 0 0 1-1.851-1.058 2.549 2.549 0 0 1-.385-2.106l2.636-10.286c-1.141-.906-10.749-8.384-13.769-6.016l-5.739 16.899a3.412 3.412 0 0 1-2.565 2.25l-2.284.45-.006.002Z"
    />
    <path
      fill="#081906"
      d="M152.498 16.17c-.717 4.94-5.766 7.824-9.12 7.477-.12-.012-.236-.027-.353-.042a4.845 4.845 0 0 1-.493.59c-2.165 2.23-6.093 2.272-8.909.95a43.3 43.3 0 0 0-4.457 2.648c-5.545 3.763-7.369.506-9.429-5.956-.275-.862-.708-1.989-.585-3.555.245-3.11 2.481-5.182 2.759-5.435 2.101-1.893 3.695-1.218 8.125-2.338 6.738-1.703 7.832-4.474 13.064-4.369 1.384.027 3.121.084 4.932 1.145 2.637 1.545 5.028 4.98 4.463 8.882l.003.003Z"
    />
    <path
      fill="#fff"
      d="M125.381 31.553s.449 7.059 7.728 8.424c7.282 1.364 10.6-7.382 13.326-16.968-1.004.635-2.786.759-4.188.837-5.703.325-9.162-4.064-9.688-4.658.657 1.886-.048 3.866-1.519 4.848-.813.542-1.791.79-3.629.16 0 0-.069-3.339-3.27-2.576-3.202.762-4.801 8.294 1.237 9.933h.003Z"
    />
    <path
      fill="#081906"
      d="M134.562 40.338c-.478 0-.975-.045-1.492-.145-6.753-1.265-7.766-7.402-7.892-8.468-2.612-.766-4.143-2.79-4.101-5.439.036-2.344 1.36-4.486 3.016-4.88 1.008-.241 1.833-.118 2.448.364.864.675 1.044 1.841 1.083 2.263 1.784.581 2.639.259 3.3-.18 1.45-.968 2.039-2.854 1.432-4.592a.22.22 0 0 1 .371-.22l.107.123c3.393 3.938 6.986 4.6 9.408 4.462 1.231-.07 3.091-.178 4.083-.804a.22.22 0 0 1 .248.009.22.22 0 0 1 .078.238c-2.397 8.424-5.497 17.266-12.086 17.266l-.003.003Zm-9.53-18.61c-.257 0-.538.036-.837.109-1.468.35-2.646 2.307-2.679 4.456-.039 2.482 1.429 4.371 3.925 5.049.09.024.156.105.162.199.018.28.526 6.905 7.551 8.222.478.09.942.132 1.387.132 6.185 0 9.216-8.34 11.548-16.453-1.01.434-2.433.549-3.823.63-2.404.139-5.916-.479-9.282-4.076.239 1.66-.443 3.314-1.818 4.23-.732.488-1.758.892-3.82.184a.224.224 0 0 1-.15-.205c0-.015-.042-1.398-.924-2.085-.331-.26-.75-.389-1.243-.389l.003-.003Z"
    />
    <path
      fill="#081906"
      d="M125.962 28.868h-.003a.219.219 0 0 1-.216-.223c.009-.497.015-1.012-.119-1.485-.141-.5-.437-.883-.807-1.045a.22.22 0 0 1-.114-.29.218.218 0 0 1 .287-.114c.49.217.873.702 1.052 1.329.153.536.144 1.084.135 1.614a.218.218 0 0 1-.218.217l.003-.003Z"
    />
    <path
      fill="#081906"
      d="M130.114 22.276s-.628 5.306 1.064 8.237c1.695 2.932 6.828-.223 12.37 1.748 0 0-2.93 8.686-4.84 10.14-2.412 1.836-15.046-1.819-13.518-10.68 0 0 2.744-3.534 2.033-7.357l2.888-2.088h.003Z"
    />
    <path
      fill="#7EC41F"
      d="M163.006 53.808a19.59 19.59 0 0 0-13.969-7.914 284.228 284.228 0 0 0-11.575-.835 10.248 10.248 0 0 1-1.029 2.314c-1.285 2.13-3.297 3.766-5.539 4.784-2.242 1.018-4.753 1.48-7.205 1.181-2.4-.292-4.684-1.322-6.343-3.121-1.351-1.467-2.975-3.239-3.39-5.164-4.021.229-7.19.563-10.962 1.16-7.202 1.136-13.405 5.528-17.339 11.707-8.582 13.486-18.124 27.721-18.124 44.141 0 18.67 44.509 17.049 44.509 17.049l4.11-2.829 39.287 2.829 27.75-23.74s-8.636-25.865-20.181-41.565v.003Z"
    />
    <path
      fill="#fff"
      d="M108.084 116.079c-.328 2.227 3.958 3.037 3.958 3.037h13.279l19.772-2.558s1.543-25.654-26.411-22.553c0 0-8.436 7.39-10.598 22.077v-.003Z"
    />
    <path
      fill="#081906"
      d="M130.529 116.875h-.033a.222.222 0 0 1-.185-.251c.206-1.428-.126-3.015-.939-4.47-.681-1.221-1.689-2.372-3.079-3.519-.263-.217-.55-.443-.867-.582a.22.22 0 0 1-.113-.289.217.217 0 0 1 .287-.115c.37.163.681.407.968.645 1.435 1.181 2.475 2.374 3.184 3.643.858 1.539 1.211 3.226.989 4.751a.222.222 0 0 1-.215.19l.003-.003Zm6.392-.368a.217.217 0 0 1-.216-.19c-.239-1.702-.583-3.694-1.321-5.555-.762-1.926-1.883-3.495-3.237-4.544a.224.224 0 0 1-.042-.31.221.221 0 0 1 .308-.042c1.417 1.096 2.585 2.732 3.378 4.73.756 1.904 1.106 3.928 1.348 5.655a.22.22 0 0 1-.185.25h-.03l-.003.006Zm5.12 0h-.027a.22.22 0 0 1-.191-.244c.861-7.258-3.862-11.786-3.91-11.831a.218.218 0 1 1 .296-.322c.051.045 4.932 4.721 4.048 12.207a.22.22 0 0 1-.219.196l.003-.006Z"
    />
    <path
      fill="#081906"
      d="M203.943 120.445h-82.385c-.487 0-.975-.127-1.378-.404a2.405 2.405 0 0 1-.897-2.823c.972-2.052 2.98-2.775 5.949-2.775h84.119l-1.092 2.989a4.603 4.603 0 0 1-4.313 3.013h-.003Z"
    />
    <path
      fill="#081906"
      d="M204.409 120.445a4.03 4.03 0 0 0 3.74-2.552l14.585-36.859a3.195 3.195 0 0 0-.32-2.955 3.145 3.145 0 0 0-2.609-1.392H169.17a7.205 7.205 0 0 0-6.789 4.833l-13.691 38.928h55.719v-.003Z"
    />
    <path
      fill="#fff"
      d="M205.593 115.025h-50.201a.22.22 0 0 1 0-.44h50.201a.22.22 0 0 1 0 .44Z"
    />
    <path
      fill="#081906"
      d="M84.985 94.685a.22.22 0 0 1-.218-.216c0-.121.093-.223.216-.226l32.246-.618c.12-.01.221.093.224.217 0 .12-.092.223-.215.226l-32.247.614h-.003l-.003.003Zm10.769-1.84a.219.219 0 0 1-.21-.283 54.81 54.81 0 0 1 4.813-11.313c.416-.736 1.085-1.706 2.09-1.672a.221.221 0 0 1 .212.228.217.217 0 0 1-.227.214c-.774-.018-1.336.817-1.695 1.453a54.375 54.375 0 0 0-4.774 11.222.22.22 0 0 1-.21.157v-.006Zm127.168 28.124H1.77a.22.22 0 0 1 0-.44h221.152a.22.22 0 0 1 0 .44Z"
    />
    <path
      fill="#fff"
      d="M4.621 111.449h47.903v9.333H4.62c-2.45 0-4.439-2.006-4.439-4.474v-.385c0-2.471 1.991-4.474 4.44-4.474Z"
    />
    <path
      fill="#081906"
      d="M52.524 120.969H1.42c-.783 0-1.42-.642-1.42-1.431v-6.839c0-.789.637-1.431 1.42-1.431h51.104c.101 0 .182.081.182.184v9.333c0 .103-.08.184-.182.184ZM1.42 111.632c-.58 0-1.055.476-1.055 1.064v6.839a1.06 1.06 0 0 0 1.055 1.063h50.918v-8.963H1.42v-.003Z"
    />
    <path
      fill="#081906"
      d="M52.524 119.508H22.19a1.868 1.868 0 0 1-1.856-1.871v-3.043c0-1.03.834-1.871 1.856-1.871h30.334c.102 0 .182.081.182.184 0 .102-.08.184-.182.184H22.19c-.822 0-1.488.675-1.488 1.5v3.043c0 .828.67 1.5 1.488 1.5h30.334c.102 0 .182.082.182.184 0 .103-.08.184-.182.184v.006Z"
    />
    <path
      fill="#081906"
      d="M30.307 115.013h-7.88a.182.182 0 0 1-.183-.184c0-.103.081-.184.183-.184h7.88c.101 0 .182.081.182.184 0 .102-.08.184-.182.184Zm5.67 2.907h-11.12a.182.182 0 0 1-.182-.184c0-.102.08-.184.182-.184h11.12c.102 0 .183.082.183.184 0 .103-.08.184-.182.184Z"
    />
    <path
      fill="#fff"
      d="M4.406 102.932h48.118v8.517H4.406c-2.332 0-4.224-1.907-4.224-4.257 0-2.35 1.892-4.257 4.224-4.257v-.003Z"
    />
    <path
      fill="#081906"
      d="M52.524 111.632H1.42c-.783 0-1.42-.641-1.42-1.431v-6.022c0-.79.637-1.431 1.42-1.431h51.104c.101 0 .182.081.182.183v8.517c0 .103-.08.184-.182.184ZM1.42 103.118c-.58 0-1.055.476-1.055 1.064v6.022c0 .585.472 1.064 1.055 1.064h50.918v-8.147H1.42v-.003Z"
    />
    <path
      fill="#081906"
      d="M52.524 110.174H22.19a1.867 1.867 0 0 1-1.856-1.871v-2.223c0-1.03.834-1.871 1.856-1.871h30.334c.102 0 .182.081.182.184 0 .102-.08.184-.182.184H22.19c-.822 0-1.488.674-1.488 1.5v2.223c0 .829.67 1.501 1.488 1.501h30.334c.102 0 .182.081.182.183 0 .103-.08.184-.182.184v.006Z"
    />
    <path fill="#C5E96D" d="M16.418 104.673H1.925v4.844h14.493v-4.844Z" />
    <path
      fill="#fff"
      d="M60.801 108.574c.188-.108 7.276-12.388 5.955-27.627-.144-1.642-1.558-2.895-3.187-2.76-.027 0-.05.003-.078.006a2.946 2.946 0 0 0-2.52 2.07c-.893 2.895-2.466 10.445-2.825 28.311h2.655Z"
    />
    <path
      fill="#081906"
      d="M60.801 108.758H58.15a.199.199 0 0 1-.132-.054.19.19 0 0 1-.054-.133c.359-17.854 1.928-25.43 2.834-28.362a3.117 3.117 0 0 1 2.679-2.2l.08-.005c1.713-.145 3.232 1.168 3.384 2.928.688 7.942-.938 15.022-2.421 19.562-1.614 4.938-3.438 8.134-3.626 8.243a.194.194 0 0 1-.093.024v-.003Zm-2.466-.371h2.376c.3-.455 1.985-3.44 3.525-8.219 1.438-4.462 3.013-11.418 2.338-19.209-.135-1.555-1.477-2.724-2.99-2.591l-.075.006a2.75 2.75 0 0 0-2.361 1.94c-.897 2.902-2.448 10.397-2.813 28.07v.003Z"
    />
    <path
      fill="#081906"
      d="M60.437 105.269s-.03 0-.042-.006a.184.184 0 0 1-.135-.223 120.187 120.187 0 0 0 3.008-19.926.182.182 0 0 1 .194-.172.184.184 0 0 1 .17.196 120.966 120.966 0 0 1-3.016 19.99.185.185 0 0 1-.18.141Z"
    />
    <path
      fill="#fff"
      d="M59.486 108.574h-4.908s-1.134-19.38-1.51-26.626c-.377-7.246 2.675-8.406 4.31-8.084 1.145.226 6.072 1.748 2.108 34.71Z"
    />
    <path
      fill="#081906"
      d="M59.486 108.758h-4.908a.184.184 0 0 1-.183-.175c-.012-.192-1.136-19.462-1.51-26.626-.239-4.61.918-6.635 1.929-7.52 1.067-.934 2.173-.838 2.6-.754 4.066.805 4.867 13.202 2.255 34.912a.184.184 0 0 1-.183.163Zm-4.735-.37h4.574c1.659-13.856 1.943-23.75.843-29.411-.586-3.019-1.537-4.679-2.825-4.932-.374-.075-1.345-.157-2.29.669-.95.831-2.036 2.76-1.803 7.221.356 6.8 1.387 24.5 1.501 26.453Z"
    />
    <path
      fill="#fff"
      d="M53.898 108.574h-3.984s-2.126-4.892-3.585-10.42c-1.459-5.53-2.053-11.808 0-12.112 2.054-.304 5.384 2.181 7.566 22.532h.003Z"
    />
    <path
      fill="#081906"
      d="M53.899 108.758h-3.985a.182.182 0 0 1-.168-.111c-.02-.049-2.146-4.968-3.593-10.446-1.202-4.558-1.898-9.954-.735-11.728.23-.353.529-.56.888-.615.642-.096 1.264.087 1.847.542 2.57 2.007 4.568 9.46 5.928 22.153a.195.195 0 0 1-.045.145.178.178 0 0 1-.137.06Zm-3.866-.37h3.66c-1.352-12.467-3.289-19.764-5.767-21.695-.508-.398-1.023-.549-1.57-.467-.254.036-.46.183-.633.452-1.091 1.672-.36 7.098.786 11.43 1.318 4.995 3.205 9.523 3.527 10.283l-.003-.003Z"
    />
    <path
      fill="#081906"
      d="M51.97 108.216a.182.182 0 0 1-.176-.136l-3.832-14.22a.183.183 0 0 1 .128-.226.181.181 0 0 1 .224.13l3.833 14.22a.183.183 0 0 1-.177.232Zm5.522.162a.181.181 0 0 1-.182-.183l-.15-28.468c0-.103.081-.187.183-.187.101 0 .182.081.182.184l.15 28.468a.184.184 0 0 1-.183.186Z"
    />
    <path
      fill="#fff"
      d="M48.623 107.029h13.972c.82 0 1.444.738 1.315 1.554l-1.949 12.199H49.26l-1.949-12.199a1.336 1.336 0 0 1 1.316-1.554h-.003Z"
    />
    <path
      fill="#081906"
      d="M61.958 120.969H49.256a.186.186 0 0 1-.182-.157l-1.95-12.198a1.523 1.523 0 0 1 1.498-1.769h13.973c.445 0 .864.196 1.154.536.287.341.412.79.344 1.233l-1.95 12.198a.184.184 0 0 1-.182.157h-.003Zm-12.543-.371h12.388l1.925-12.042a1.163 1.163 0 0 0-.26-.934 1.141 1.141 0 0 0-.873-.406H48.622c-.337 0-.654.147-.873.406-.218.259-.313.6-.26.934l1.926 12.042Z"
    />
  </svg>
);
