import api from '../services/api';

export const uploadFileExtract = async (
  files: FileList,
  token: string
): Promise<any> => {
  const formData = new FormData();

  // eslint-disable-next-line no-restricted-syntax
  for (const file of Array.from(files)) {
    formData.append('files', file);
  }

  const result = await api.post(`/api/extract/upload-file`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  });

  return result?.data;
};

export const getAllExtract = async (
  token: string,
  search?: string,
  status?: string,
  page?: number,
  itemsPerPage?: number
): Promise<any> => {
  const result = await api.get(
    `/api/extract?search=${search || ''}&status=${status || ''}&page=${
      page || '0'
    }&itemsPerPage=${itemsPerPage || ''}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return result?.data;
};

export const archiveExtract = async (
  token: string,
  extractId: string
): Promise<any> => {
  const result = await api.patch(
    `/api/extract/${extractId}/archive`,
    {},
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return result?.data;
};

export const vinculateExtract = async (
  token: string,
  fileId: string,
  extractId: string
): Promise<any> => {
  const result = await api.patch(
    '/api/attachment/paymentReceipt',
    { paymentReceiptId: fileId, transactionId: extractId },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return result?.data;
};

export default {
  uploadFileExtract,

  getAllExtract,
  vinculateExtract,
};
