import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { CircularProgress, TablePagination } from '@mui/material';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { RequestStatusEnum, requestStatusLabel } from '../../pages/Home/utils';
import { Button } from '../Button';

import {
  StyleContentChangeStatus,
  StyleContentChangeStatusContent,
  StyleContentLinks,
  StyledContentCollapseCell,
  StyledContentLoading,
  StyledContentPagination,
  StyledLink,
  StyledTableCell,
  StyledTableRow,
} from './styles';

interface RowProps {
  row: any;
  isAdmin: boolean;
  rows: string[];
  head: string[];
  handleDownload: (attachmentId: string, filename: string) => void;
  handlePreview: (
    attachmentId: string,
    filename: string,
    payments: any[]
  ) => void;
  handleChangeStatus?: (id: string, status: RequestStatusEnum) => void;
  removeRequest?: (id: string) => void;
}

function Row(props: RowProps) {
  const {
    row,
    isAdmin,
    rows,
    head,
    handleDownload,
    handlePreview,
    handleChangeStatus,
    removeRequest,
  } = props;
  const [open, setOpen] = React.useState(false);

  const [isLoading, setLoading] = React.useState('');

  const handleLoading = (id: string) => {
    setLoading(id);

    setTimeout(() => {
      setLoading('');
    }, 1000);
  };

  return (
    <React.Fragment>
      <StyledTableRow onClick={() => setOpen(!open)} open={String(open)}>
        {rows.map((rowKey, index) => (
          <StyledTableCell key={rowKey} align="center" data-label={head[index]}>
            {row[rowKey]}
          </StyledTableCell>
        ))}

        <StyledTableCell
          data-label="Mais Detalhes"
          style={{ borderBottom: '3px solid #e9ebec' }}
        >
          <IconButton aria-label="expand row" size="small">
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </StyledTableCell>
      </StyledTableRow>
      <TableRow>
        <StyledContentCollapseCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={12}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography
                variant="h6"
                gutterBottom
                component="div"
                paddingLeft={1}
              >
                Comprovantes de pagamento
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Nome</TableCell>
                    {isAdmin && <TableCell>Transação</TableCell>}
                    <TableCell align="center" width={300}>
                      Ações
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.paymentsData.map((payment: any) => (
                    <TableRow key={payment.id}>
                      <TableCell data-label="ID">{payment.shortId}</TableCell>
                      <TableCell data-label="Nome">{payment.name}</TableCell>
                      {isAdmin && (
                        <TableCell data-label="Transações">
                          {payment.transaction || '-'}
                        </TableCell>
                      )}
                      <TableCell>
                        <StyleContentLinks>
                          <StyledLink
                            onClick={() => {
                              handlePreview(
                                payment.id,
                                payment.name,
                                row.paymentsData
                              );
                              handleLoading(`view-${payment.id}`);
                            }}
                          >
                            <StyledContentLoading>
                              {isLoading === `view-${payment.id}` ? (
                                <CircularProgress size={14} />
                              ) : (
                                'Visualizar'
                              )}
                            </StyledContentLoading>
                          </StyledLink>
                          <StyledLink
                            onClick={() => {
                              handleDownload(payment.id, payment.name);
                              handleLoading(`download-${payment.id}`);
                            }}
                          >
                            <StyledContentLoading>
                              {isLoading === `download-${payment.id}` ? (
                                <CircularProgress size={14} />
                              ) : (
                                'Download'
                              )}
                            </StyledContentLoading>
                          </StyledLink>
                        </StyleContentLinks>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
            <Box sx={{ margin: 1 }}>
              <Typography
                variant="h6"
                gutterBottom
                component="div"
                paddingLeft={1}
              >
                Contas para milhas
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Nome</TableCell>
                    <TableCell align="center" width={300}>
                      Ações
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.accountsData.map((account: any) => (
                    <TableRow key={account.id}>
                      <TableCell data-label="ID">{account.shortId}</TableCell>
                      <TableCell data-label="Nome">{account.name}</TableCell>
                      <TableCell>
                        <StyleContentLinks>
                          <StyledLink
                            onClick={() => {
                              handleDownload(account.id, account.name);
                              handleLoading(`download-${account.id}`);
                            }}
                          >
                            <StyledContentLoading>
                              {isLoading === `download-${account.id}` ? (
                                <CircularProgress size={14} />
                              ) : (
                                'Download'
                              )}
                            </StyledContentLoading>
                          </StyledLink>
                        </StyleContentLinks>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>

            {handleChangeStatus && (
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  Status da solicitação
                </Typography>
                <StyleContentChangeStatus>
                  <StyleContentChangeStatusContent>
                    A solicitação está com o status{' '}
                    {requestStatusLabel(row.status)}
                  </StyleContentChangeStatusContent>
                  <div>
                    <Button
                      onClick={() => handleChangeStatus(row.id, row.status)}
                      style={{ fontWeight: 'bold' }}
                    >
                      Alterar status
                    </Button>
                  </div>
                </StyleContentChangeStatus>
              </Box>
            )}
            {removeRequest && (
              <Box
                sx={{ margin: 1 }}
                style={{
                  background: '#ffebee',
                  borderRadius: 8,
                  padding: 10,
                  boxSizing: 'border-box',
                }}
              >
                <Typography
                  variant="h6"
                  gutterBottom
                  component="div"
                  color="red"
                >
                  Dangerous area
                </Typography>
                <StyleContentChangeStatus>
                  <StyleContentChangeStatusContent>
                    Cuidado, tenha certeza a executar a ação abaixo pois ela é
                    irreversível
                  </StyleContentChangeStatusContent>
                  <div>
                    <Button
                      onClick={() => removeRequest(row.id)}
                      style={{
                        borderColor: 'red',
                        color: 'red',
                        // fontWeight: 'bold',
                        // background: 'transparent',
                      }}
                    >
                      Deletar solicitação
                    </Button>
                  </div>
                </StyleContentChangeStatus>
              </Box>
            )}
          </Collapse>
        </StyledContentCollapseCell>
      </TableRow>
    </React.Fragment>
  );
}

interface Props {
  head: string[];
  rows: string[];
  data: any[];
  isAdmin: boolean;
  itemsPerPage: number;
  page: number;
  count: number;
  changePage: (page: number) => void;
  changeItemsPerPage: (items: number) => void;
  handleDownload: (attachmentId: string, filename: string) => void;
  handlePreview: (
    attachmentId: string,
    filename: string,
    payments: any[]
  ) => void;
  handleChangeStatus?: (id: string, status: RequestStatusEnum) => void;
  removeRequest?: (id: string) => void;
}

export const TableCollapse = ({
  head,
  rows,
  data,
  isAdmin,
  changeItemsPerPage,
  changePage,
  page,
  count,
  itemsPerPage,
  handleDownload,
  handlePreview,
  handleChangeStatus,
  removeRequest,
}: Props): React.ReactElement => {
  return (
    <TableContainer component={Paper} style={{ width: '100%' }}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            {head.map((item) => (
              <StyledTableCell align="center" key={item}>
                {item}
              </StyledTableCell>
            ))}
            <StyledTableCell align="center"></StyledTableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {data.map((item) => (
            <Row
              key={item.id}
              row={item}
              rows={rows}
              head={head}
              handleDownload={handleDownload}
              handlePreview={handlePreview}
              handleChangeStatus={handleChangeStatus}
              removeRequest={removeRequest}
              isAdmin={isAdmin}
            />
          ))}
        </TableBody>
      </Table>
      <StyledContentPagination>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          labelRowsPerPage="Itens por página"
          labelDisplayedRows={(item) =>
            `${item.from} - ${item.to} de ${item.count}`
          }
          component="div"
          count={count}
          rowsPerPage={itemsPerPage}
          page={page}
          onPageChange={(e, page) => changePage(page)}
          onRowsPerPageChange={(event) =>
            changeItemsPerPage(Number(event.target.value))
          }
        />
      </StyledContentPagination>
    </TableContainer>
  );
};

export default TableCollapse;
