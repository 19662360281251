import styled from 'styled-components';
import { ActionButton } from '../../components/ActionButton';

export const StyledSection = styled.section`
  position: relative;
  display: block;
  max-width: 500px;
  margin: 40px auto;
  padding: 0 16px 32px;
  line-height: 0;

  @media (min-width: 900px) {
    display: none;
  }
`;

export const StyledPlane = styled.img`
  position: absolute;
  top: -4px;
  left: 0;
  width: 18vw;
  max-width: 100px;
`;

export const StyledBanner = styled.img`
  width: 100%;
`;

export const StyledButtonAction = styled(ActionButton)`
  display: flex;
  margin: 0 auto;
  transform: translateY(-50%);
`;
