import * as React from 'react';
import { Div26, Div27, Img5 } from './styles';

interface Props {
  label: string;
  disabled?: boolean;
  acceptTypes?: string;
  multiple?: boolean;
  width?: number;
  handleChange: (files: FileList) => void;
}

const AttachmentButton = ({
  label,
  disabled,
  acceptTypes,
  multiple,
  width = 300,
  handleChange,
}: Props): React.ReactElement => {
  const inputRef = React.useRef<HTMLInputElement>(null);

  const handleOpenSelectFile = () => {
    if (!disabled && inputRef?.current) {
      inputRef.current.click();
    }
  };

  const onChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const target = e.target as HTMLInputElement;
    const { files } = target;

    if (files) {
      handleChange(files);
    }

    target.files = null;
    target.value = '';
  };

  return (
    <Div26 onClick={handleOpenSelectFile} style={{ width }}>
      <Img5
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/bef85ae62c2994dac9bd7eb105f20a1b6a7acb8e1abaee4e6d677c74c71afc20?"
      />
      <Div27>{label}</Div27>

      <input
        type="file"
        hidden
        ref={inputRef}
        onChange={onChange}
        multiple={multiple}
        accept={acceptTypes}
      />
    </Div26>
  );
};

export default AttachmentButton;
