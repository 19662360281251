import React from 'react';
import { Autoplay, Pagination } from 'swiper/modules';
import { useNavigate } from 'react-router-dom';
import {
  StyledActionButton,
  StyledCarousel,
  StyledDate,
  StyledHero,
  StyledHeroItem,
  StyledHeroItemInner,
  StyledHeroPicture,
  StyledRuleInfo,
} from './styles';
import useAuthenticate from '../../../../recoil/hooks/authenticate';

interface HeroProps {
  items: any[];
}

export const Hero = ({ items }: HeroProps): React.ReactElement => {
  const { authenticate } = useAuthenticate();

  const navigate = useNavigate();

  const handleClick = (url: string) => {
    if (
      authenticate?.isAuthenticate &&
      url.includes(authenticate?.user?.code)
    ) {
      navigate('/new-request');
    } else {
      window.open(url, '_self');
    }
  };
  return (
    <StyledHero>
      <StyledCarousel
        modules={[Pagination, Autoplay]}
        pagination={{ clickable: true }}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
      >
        {items.map(({ images, callToAction, rules, date }, index) => (
          <StyledHeroItem key={`hero-${index}`}>
            <StyledHeroItemInner>
              <StyledHeroPicture onClick={() => handleClick(callToAction.url)}>
                <source srcSet={images.desktop} media="(min-width: 600px)" />
                <img src={images.mobile} alt="Milhas na Conta" />
              </StyledHeroPicture>

              <StyledActionButton
                onClick={() => handleClick(callToAction.url)}
                target="_blank"
              >
                {callToAction.text || 'Comprar pontos'}
              </StyledActionButton>
            </StyledHeroItemInner>

            {date && <StyledDate>{date}</StyledDate>}

            {rules.url && (
              <StyledRuleInfo>
                <a href={rules.url} target="_blank" rel="noreferrer">
                  VEJA AQUI
                </a>{' '}
                as regras da promoção.
              </StyledRuleInfo>
            )}
          </StyledHeroItem>
        ))}
      </StyledCarousel>
    </StyledHero>
  );
};
