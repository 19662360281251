import styled from 'styled-components';

export const StyledLogoContainer = styled.div`
  min-width: 178px;
  position: relative;
  margin: 8px 28px;
`;

export const StyledImageContainer = styled('div')({
  '> svg': {
    position: 'absolute',
    width: '62px',
    top: '-24px',
    left: '-35px',
  },
});

export const StyledTitle = styled.div`
  font-feature-settings: 'clig' off, 'liga' off;
  flex-basis: auto;
  font: 26px Saira Stencil One, sans-serif;

  @media (max-width: 768px) {
    font-size: 22px;
  }
`;
