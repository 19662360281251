import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import styled from 'styled-components';

export const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#79cc72',
    color: '#FFF',
    fontWeight: 'bold',
    fontSize: 16,
    fontFamily: 'Poppins,sans-serif',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: 'Poppins,sans-serif',
  },
}));

export const StyledTableRow = styled(TableRow)<{ open?: string }>(
  ({ onClick, open }) => ({
    '&:hover': {
      backgroundColor: '#79cc7222',
      transition: '0.3s',
    },
    transition: '0.5s',
    '&:last-child td, &:last-child th': {
      border: 0,
    },
    backgroundColor: open === 'true' ? '#79cc7222' : '',
    cursor: onClick ? 'pointer' : 'auto',
  })
);

export const StyledLink = styled('div')(() => ({
  transition: '0.5s',
  color: '#00bcd4',
  cursor: 'pointer',
  '&:hover': {
    textDecoration: 'underline',
  },
}));

export const StyleContentChangeStatus = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: 30,
}));

export const StyleContentChangeStatusContent = styled('div')(() => ({
  marginTop: 30,
}));

export const StyleContentLinks = styled('div')(() => ({
  display: 'flex',
  gap: 16,
  justifyContent: 'center',
  maxWidth: 300,
  margin: '0 auto',
}));

export const StyledContentLoading = styled('div')(() => ({
  width: 100,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const StyledContentPagination = styled('div')({
  width: '100%',

  '.MuiTablePagination-input': {
    marginRight: 8,
  },

  '.MuiTablePagination-displayedRows + .MuiTablePagination-actions': {
    marginLeft: 8,
  },
});

export const StyledContentCollapseCell = styled(TableCell)(() => ({
  '&.MuiTableCell-body': {
    '@media (max-width: 961px)': {
      padding: 0,
      textAlign: 'left',
    },
  },
}));
