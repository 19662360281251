import styled from 'styled-components';

export const Div18 = styled.div`
  display: flex;
  flex-grow: 1;
  flex-basis: 0%;
  flex-direction: column;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

export const Div19 = styled.div`
  color: #212529;
  font: 500 16px Poppins, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

export const Div25 = styled.div`
  display: flex;
  flex-grow: 1;
  flex-basis: 0%;
  flex-direction: column;
`;

export const Div28 = styled('div')({
  color: '#878a99',
  marginTop: 10,
  font: '300 12px Poppins, sans-serif',
  display: 'flex',
  // flexDirection: 'column',
  gap: 4,

  '& > div': {
    color: '#69bc62',
    fontWeight: 500,
  },
});

export const Div32 = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;

export const Div37 = styled.div`
  width: 185px;
  border-radius: 4px;
  background-color: #79cc72;
  display: flex;
  padding-left: 13px;
  // padding-right: 10px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  font-size: 13px;
  color: #fff;
  font-weight: 500;
  margin: auto 0;
  height: 40px;
  cursor: pointer;
  &:hover {
    cursor: pointer;
    background-color: #69bc62;
  }

  @media (max-width: 480px) {
    width: 100%;
  }
`;

export const Div38 = styled.div`
  font-family: Poppins, sans-serif;
  margin: auto 0;
`;
