import styled from 'styled-components';
// eslint-disable-next-line import/no-unresolved
import { Swiper, SwiperSlide } from 'swiper/react';
import { ActionButton } from '../../components/ActionButton';

export const StyledContainer = styled.section`
  max-width: var(--container-max-width);
  margin: 0 auto;
  padding-bottom: 32px;
`;

export const StyledSubtitle = styled.p`
  font-size: 20px;
  text-align: center;
  line-height: 1.5;
  padding: 0 16px;
  max-width: 530px;
  margin: 0 auto;
`;

export const StyledCarousel = styled(Swiper)`
  margin-top: 40px;
`;

export const StyledItem = styled(SwiperSlide)`
  max-width: 265px;

  &:first-child {
    margin-left: 16px;
  }

  &:last-child {
    margin-right: 16px;
  }
`;

export const StyledActionButton = styled(ActionButton)`
  display: flex;
  margin: 40px auto;
`;
