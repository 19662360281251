import styled from 'styled-components';

export const Div26 = styled.div`
  border-radius: 6px;
  border: 2px dashed #e9ebec;
  display: flex;
  justify-content: space-between;
  gap: 6px;
  font-size: 12px;
  color: #79cc72;
  font-weight: 500;
  white-space: nowrap;
  text-align: center;
  padding: 10px 56px;
  width: 300px;
  align-self: center;
  margin: 10px 0px;
  @media (max-width: 991px) {
    white-space: initial;
  }

  &:hover {
    cursor: pointer;
    border: 2px solid #79cc72;
  }
`;

export const Img5 = styled.img`
  aspect-ratio: 1.11;
  object-fit: auto;
  object-position: center;
  width: 20px;
`;

export const Div27 = styled.div`
  font-family: Poppins, sans-serif;
  align-self: start;
  margin-top: 5px;
  flex-grow: 1;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;
