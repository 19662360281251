/* eslint-disable import/no-unresolved */
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ActionButton } from '../../components/ActionButton';

export const StyledHero = styled.section`
  text-align: center;
`;

export const StyledCarousel = styled(Swiper)`
  .swiper-pagination {
    bottom: 80px;

    @media (min-width: 600px) {
      bottom: 100px;
    }
  }
`;

export const StyledHeroItem = styled(SwiperSlide)`
  line-height: 0;
`;

export const StyledHeroItemInner = styled.div`
  position: relative;
  background: var(--color-primary);
  margin-bottom: 100px;

  @media (min-width: 600px) {
    margin-bottom: 32px;
  }
`;

export const StyledHeroPicture = styled.picture`
  cursor: pointer;

  img {
    width: 100%;
    max-width: 880px;
  }
`;

export const StyledActionButton = styled(ActionButton)`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
`;

export const StyledDate = styled.strong`
  display: block;
  color: var(--color-primary-dark);
  line-height: 1;
  margin-bottom: 20px;
`;

export const StyledRuleInfo = styled.p`
  font-size: 18px;
  line-height: 1;

  a {
    text-decoration: underline;
  }
`;
