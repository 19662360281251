import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

interface AuthenticateDefault {
  isAuthenticate: boolean;
  token: string;
  user: { id: string; role: string } | null;
}

const authenticateAtom = atom({
  key: 'authenticateAtom',
  default: {
    isAuthenticate: false,
    token: '',
    user: null,
  } as AuthenticateDefault,
  effects_UNSTABLE: [persistAtom],
});

export default authenticateAtom;
