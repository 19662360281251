import React from 'react';

export const DownloadFigure = (): React.ReactElement => (
  <svg xmlns="http://www.w3.org/2000/svg" width={163} height={164} fill="none">
    <path
      fill="#7EC41F"
      d="m158.404 117.131-93.828-13.104a4.735 4.735 0 0 1-4.122-4.705L59.172 33.59s2.138-1.548 3.352-2.142a3.662 3.662 0 0 1 2.07-.341l93.553 11.984a4.733 4.733 0 0 1 4.122 4.705v64.562a3.68 3.68 0 0 1-1.338 2.847c-.745.619-2.514 1.933-2.514 1.933l-.01-.006h-.003Z"
    />
    <path
      fill="#D6EF89"
      d="M155.543 118.007 61.99 106.026a4.735 4.735 0 0 1-4.122-4.705V36.758a3.669 3.669 0 0 1 4.129-3.65l93.552 11.98a4.735 4.735 0 0 1 4.122 4.705v64.563a3.672 3.672 0 0 1-4.131 3.651h.003Z"
    />
    <path
      fill="#081906"
      d="M57.871 51.339a.293.293 0 0 0 .292-.295v-14.28c0-.976.413-1.896 1.136-2.537a3.378 3.378 0 0 1 2.66-.82l73.445 9.407a.295.295 0 0 0 .075-.585l-73.445-9.406a3.963 3.963 0 0 0-3.123.96 3.978 3.978 0 0 0-1.337 2.984v14.28c0 .163.13.295.291.295l.006-.003Z"
    />
    <path
      fill="#D6EF89"
      d="m153.553 115.345-89.322-11.44a3.439 3.439 0 0 1-2.998-3.419V38.21a2.443 2.443 0 0 1 2.76-2.437l89.321 11.44a3.441 3.441 0 0 1 2.998 3.419v62.273a2.442 2.442 0 0 1-2.759 2.436v.004Zm-52.59 40.682L4.751 143.564c-.754-.1-1.177-.729-1.17-1.361 0-.432.037-3.513.037-3.513l50.876-29.211c2.545-1.464 5.845-2.027 8.76-1.655l93.99 12.554c.568.078 2.067.194 2.29.729l.155.356s-.083 2.568-.046 3.163c.022.472-.183.951-.677 1.229l-49.597 28.482a13.467 13.467 0 0 1-8.415 1.68l.009.006v.004Z"
    />
    <path
      fill="#fff"
      d="M100.963 152.601 5.095 140.164c-1.27-.163-1.608-1.859-.493-2.5l49.327-28.298a13.511 13.511 0 0 1 8.415-1.679l95.865 12.44c1.269.163 1.607 1.859.493 2.5l-49.331 28.298a13.475 13.475 0 0 1-8.411 1.676h.003Z"
    />
    <path
      fill="#081906"
      d="M102.655 153.002c2.393 0 4.783-.626 6.866-1.824l49.33-28.297a1.622 1.622 0 0 0 .792-1.74 1.617 1.617 0 0 0-1.394-1.307l-3.184-.41a.293.293 0 0 0-.329.256.295.295 0 0 0 .254.332l3.185.41c.546.069.822.482.891.835.068.357-.025.835-.509 1.114l-49.331 28.297a13.203 13.203 0 0 1-8.229 1.639l-62.14-8.061a.295.295 0 0 0-.078.585l62.141 8.061c.577.079 1.151.107 1.729.107l.006.003Zm-63.832-34.67c.047 0 .1-.015.146-.037l15.103-8.665a13.223 13.223 0 0 1 8.23-1.639l37.401 4.851a.297.297 0 0 0 .078-.588l-37.402-4.852a13.85 13.85 0 0 0-8.598 1.712l-15.104 8.665a.294.294 0 0 0 .146.55v.007-.004Z"
    />
    <path
      fill="#D6EF89"
      d="M119.732 136.898 36.6 126.037c-1.145-.147-1.453-1.677-.447-2.259l19.549-11.102a12.18 12.18 0 0 1 7.598-1.517l83.132 10.861c1.146.147 1.453 1.677.447 2.259l-19.548 11.102a12.173 12.173 0 0 1-7.599 1.517Z"
    />
    <path
      fill="#081906"
      d="M116.772 128.852c.146 0 .27-.109.292-.257a.296.296 0 0 0-.252-.331l-41.509-5.615a.294.294 0 0 0-.329.253.293.293 0 0 0 .255.332l41.509 5.615h.037l-.003.003Zm-26.905 1.692a.294.294 0 0 0 .037-.585l-21.554-2.946a.294.294 0 0 0-.329.253.293.293 0 0 0 .255.332l21.553 2.946h.038Z"
    />
    <path
      fill="#fff"
      d="m66.966 104.352 82.496 10.862V24.906L66.966 14.045v90.307Z"
    />
    <path
      fill="#D6EF89"
      d="m90.71 27.242 35.472 4.665v-6.12l-35.471-4.673v6.128Zm3.878 75.928 47.567 6.266v-6.125l-47.567-6.26v6.119Zm-19.024-2.509 14.334 1.889v-6.118l-14.334-1.89v6.119Zm-2.583-66.017 69.177 9.106v-5.562l-69.177-9.103v5.562-.003Z"
    />
    <path
      fill="#D6EF89"
      d="m142.118 100.676-69.177-9.103a.294.294 0 0 1-.254-.294V29.085a.32.32 0 0 1 .099-.226.269.269 0 0 1 .23-.069l69.177 9.107a.293.293 0 0 1 .254.294v62.204a.32.32 0 0 1-.099.225.296.296 0 0 1-.192.069h-.038v-.013Zm-68.845-71.26v61.61l68.585 9.028v-61.61l-68.585-9.028Z"
    />
    <path
      fill="#D6EF89"
      d="M92.542 94.07a.293.293 0 0 0 .292-.294V31.68a.293.293 0 0 0-.292-.294.293.293 0 0 0-.292.294v62.088c0 .163.13.294.292.294v.007Zm2.046-47.742 45.531 5.993v-5.43l-45.531-5.988v5.425Zm0 7.516 45.531 5.988V54.41l-45.531-5.996v5.43Zm0 7.096 45.531 5.993v-5.43l-45.531-5.997v5.433Zm0 7.51 45.531 5.994v-5.425l-45.531-5.993v5.424Zm0 7.1 45.531 5.994v-5.43l-45.531-5.997v5.434Zm0 7.096 45.531 5.996v-5.43l-45.531-5.987v5.424-.003Zm0 7.511 45.531 5.996V90.73l-45.531-5.993v5.42ZM75.564 44.028l14.334 1.89v-5.425l-14.334-1.889v5.424Zm0 7.52 14.334 1.89v-5.43l-14.334-1.89v5.43Zm0 7.092 14.334 1.89v-5.425l-14.334-1.89v5.425Zm0 7.514 14.334 1.886v-5.42l-14.334-1.89v5.424Zm0 7.101 14.334 1.886v-5.43l-14.334-1.89v5.434Zm0 7.091 14.334 1.89v-5.424l-14.334-1.89v5.424Zm0 7.52 14.334 1.887v-5.431l-14.334-1.89v5.434Z"
    />
    <path
      fill="#7EC41F"
      d="m106.414 83.318 43.284 5.678c.792.1 1.316.726 1.316 1.57v31.764c0 1.392-1.39 2.693-2.697 2.521l-43.284-5.677c-.792-.101-1.316-.726-1.316-1.571V85.84c0-1.392 1.393-2.694 2.697-2.522Z"
    />
    <path
      fill="#081906"
      d="m148.277 125.145-43.285-5.678c-.937-.125-1.567-.875-1.567-1.864V85.839c0-.766.385-1.564 1.024-2.127.599-.525 1.332-.782 2.015-.688l43.284 5.678c.937.125 1.567.876 1.567 1.864v31.764c0 .766-.385 1.564-1.015 2.127-.515.457-1.139.713-1.738.713-.093 0-.183 0-.276-.016l-.006-.006-.003-.003Zm-41.9-41.533c-.516-.069-1.077.131-1.555.55-.516.457-.814 1.083-.814 1.677v31.764c0 .688.413 1.192 1.053 1.276l43.284 5.678c.515.069 1.077-.131 1.552-.551.515-.456.816-1.082.816-1.679V90.563c0-.688-.416-1.192-1.055-1.276l-43.284-5.678.003.003Z"
    />
    <path
      fill="#D6EF89"
      d="m134.029 115.376 13.372 1.755c.26.031.431.241.431.519v1.57c0 .457-.462.892-.891.829l-13.375-1.755c-.26-.031-.428-.24-.428-.516v-1.57c0-.457.463-.892.891-.829v-.003Zm-17.087-2.337 6.198.857a.5.5 0 0 1 .432.52v1.564c0 .463-.463.888-.891.829l-6.198-.861a.499.499 0 0 1-.432-.516v-1.564c0-.466.463-.891.891-.829Zm23.786-17.352 6.48.85c.379.048.624.348.624.742v5.509c0 .666-.661 1.276-1.275 1.198l-6.481-.851c-.379-.047-.624-.347-.624-.741v-5.509c0-.666.661-1.276 1.276-1.198Zm0 10.686 6.48.851c.379.047.624.347.624.741v2.931c0 .667-.661 1.277-1.275 1.199l-6.481-.851c-.379-.047-.624-.348-.624-.742v-2.931c0-.666.661-1.276 1.276-1.198Zm-10.852-1.442 6.481.851c.379.047.624.347.624.741v2.931c0 .666-.661 1.276-1.275 1.201l-6.481-.85c-.379-.047-.624-.348-.624-.742v-2.931c0-.666.661-1.276 1.275-1.198v-.003Zm-10.851-1.445 6.481.851c.379.047.624.347.624.741v2.931c0 .666-.661 1.276-1.276 1.198l-6.484-.851c-.375-.047-.624-.347-.624-.741v-2.931c0-.666.662-1.276 1.276-1.198h.003Zm-10.848-1.439 6.481.851c.375.047.623.347.623.741v2.931c0 .667-.661 1.277-1.275 1.202l-6.481-.851c-.376-.047-.624-.348-.624-.742v-2.931c0-.666.661-1.276 1.276-1.198v-.003Z"
    />
    <path
      fill="#fff"
      d="m11.517 148.237-1.046 7.395-2.675.007-.124-7.27 3.845-.132Z"
    />
    <path
      fill="#081906"
      d="m7.501 155.648-.124-7.27a.3.3 0 0 1 .286-.303l3.852-.132a.31.31 0 0 1 .23.1.275.275 0 0 1 .068.241l-1.046 7.395a.298.298 0 0 1-.292.257H7.799v.006a.292.292 0 0 1-.291-.294H7.5Zm3.679-7.101-3.216.106.115 6.692h2.138l.963-6.801v.003Z"
    />
    <path
      fill="#fff"
      d="M10.561 162.696c-1.052.116-2.613.156-4.705-.022a1.302 1.302 0 0 1-1.155-1.508c.323-2.002 1.229-5.546 2.66-7.17l.708 1.014.416-1.045c.068-.116 1.847-.184 1.874.022l.109 1.02 1.009-1.139-.053 3.729c0 .41.031.82.115 1.229l.26 1.292c.162.773-.037 1.602-.577 2.174-.208.226-.447.388-.67.41l.006-.006h.003Z"
    />
    <path
      fill="#081906"
      d="M5.834 162.965a1.573 1.573 0 0 1-1.108-.603 1.634 1.634 0 0 1-.317-1.245c.084-.526.891-5.221 2.729-7.32a.288.288 0 0 1 .239-.1.28.28 0 0 1 .223.125l.391.563.214-.541s.016-.032.022-.041c.223-.369 1.875-.225 1.993-.21.093.016.385.069.431.332v.022l.037.356.569-.635a.286.286 0 0 1 .322-.078.283.283 0 0 1 .183.279l-.052 3.728c0 .388.03.782.108 1.161l.261 1.292c.183.888-.062 1.802-.655 2.437-.276.294-.568.472-.847.494-.494.053-1.183.1-2.067.1a30.07 30.07 0 0 1-2.7-.125h.015l.009.009Zm4.7-.563c.13-.015.306-.131.483-.319.463-.494.646-1.207.5-1.911l-.26-1.292a6.212 6.212 0 0 1-.125-1.292l.047-2.94-.484.541a.292.292 0 0 1-.308.084.283.283 0 0 1-.198-.247l-.093-.86c-.308-.047-1.084-.031-1.385.022l-.37.919a.292.292 0 0 1-.515.06l-.484-.688c-1.27 1.711-2.067 4.973-2.352 6.722-.047.279.03.566.198.788a.942.942 0 0 0 .692.379c2.229.194 3.76.125 4.653.031v-.006.009Z"
    />
    <path
      fill="#081906"
      d="M8.65 161.773c1.176 0 1.9-.147 1.946-.153a.292.292 0 0 0 .23-.347.29.29 0 0 0-.345-.232c-.016 0-1.853.363-4.423-.094a.286.286 0 0 0-.339.241.287.287 0 0 0 .24.341c1.036.185 1.952.241 2.69.241v.006-.003Zm-1.577-2.828h2.43a.292.292 0 0 0 .292-.294.293.293 0 0 0-.292-.294h-2.43a.293.293 0 0 0-.292.294c0 .163.13.294.292.294Z"
    />
    <path
      fill="#fff"
      d="m30.74 148.901.937 8.609 3.607.757-.224-10.095-4.32.726v.003Z"
    />
    <path
      fill="#081906"
      d="m35.225 158.564-3.6-.757a.291.291 0 0 1-.23-.253l-.947-8.619a.298.298 0 0 1 .246-.325l4.32-.729a.29.29 0 0 1 .24.063.286.286 0 0 1 .108.225l.223 10.104a.28.28 0 0 1-.108.231.279.279 0 0 1-.183.069h-.063l-.006-.006v-.003Zm-4.162-9.422.89 8.121 3.03.644-.208-9.391-3.715.626h.003Z"
    />
    <path
      fill="#fff"
      d="M30.157 161.62c-.078-1.508-.084-4.292.832-5.631l2.883 1.029v-.619c0-.2.14-.363.323-.372l1.608-.094a3.519 3.519 0 0 1 1.744.363c1.614.797 6.531 2.846 7.214 5.152.202.679-.33 1.361-1.03 1.376-1.615.047-5.507.1-12.488-.141a1.128 1.128 0 0 1-1.083-1.066l-.003.003Z"
    />
    <path
      fill="#081906"
      d="M31.234 162.99a1.41 1.41 0 0 1-1.37-1.345c-.083-1.564-.077-4.401.886-5.809a.29.29 0 0 1 .338-.109l2.492.891v-.2c0-.357.261-.651.6-.673l1.607-.093c.646-.038 1.3.1 1.89.394l.708.341c2.108.991 6.022 2.837 6.658 4.995.115.404.047.829-.199 1.177-.254.356-.67.572-1.098.588-.693.022-1.754.037-3.232.037-2.144 0-5.174-.037-9.274-.184l-.006-.007v-.003Zm4.581-6.754-1.605.094s-.046.032-.046.079v.619a.301.301 0 0 1-.124.241.298.298 0 0 1-.267.037l-2.66-.951c-.547.992-.792 2.878-.662 5.253.022.434.37.772.798.788 6.907.241 10.774.194 12.472.141a.833.833 0 0 0 .645-.341.745.745 0 0 0 .115-.657c-.562-1.902-4.466-3.745-6.344-4.633-.276-.131-.522-.247-.714-.341a3.164 3.164 0 0 0-1.422-.332h-.177l-.009.007v-.004Z"
    />
    <path
      fill="#081906"
      d="M39.39 161.736c2.145 0 3.499-.1 3.53-.1a.295.295 0 0 0-.047-.588c-.053 0-5.121.378-11.248-.341a.296.296 0 0 0-.062.588c3.023.356 5.782.441 7.834.441h-.006Zm-.896-2.465a.296.296 0 0 0 .17-.535l-2.774-2.002a.29.29 0 0 0-.407.069.3.3 0 0 0 .068.41l2.775 2.002a.29.29 0 0 0 .17.056h-.002Z"
    />
    <path
      fill="#7EC41F"
      d="M31.863 92.997s5.168 35.639 4.06 59.417l-1.614.162c-.869.085-1.738.11-2.607.063l-1.53-.078s-6.158-36.134-9.234-42.131c0 0-8.36 32.136-8.682 40.839 0 0-3.716.378-5.2-.163-.79-8.402-1.023-17.096-1.067-22.557-.115-15.626 1.369-22.721 2.185-26.083a77.33 77.33 0 0 1 2.139-7.305l1.952-5.609 19.592 3.445h.006Z"
    />
    <path
      fill="#081906"
      d="m31.693 152.939-1.53-.078a.286.286 0 0 1-.277-.247c-.062-.347-5.751-33.628-8.864-41.302-1.292 5.043-8.176 32.158-8.468 39.963a.29.29 0 0 1-.26.285c-.156.015-3.8.378-5.33-.178a.29.29 0 0 1-.193-.248c-.853-8.974-1.03-17.999-1.067-22.582-.109-14.868 1.198-22.048 2.191-26.161a80.883 80.883 0 0 1 2.145-7.333l1.952-5.609c.047-.14.183-.215.332-.193L31.916 92.7a.291.291 0 0 1 .24.247c.055.356 5.152 35.933 4.069 59.479 0 .147-.125.263-.261.279l-1.608.162c-.583.063-1.176.085-1.76.085-.301 0-.593 0-.884-.022l-.016.006-.003.003Zm-.09-59.686L12.47 89.888l-1.875 5.377a83.288 83.288 0 0 0-2.129 7.279c-.984 4.076-2.284 11.218-2.176 26.014.031 4.542.215 13.445 1.046 22.336 1.26.356 3.83.178 4.644.106.469-9.003 8.598-40.319 8.681-40.635a.285.285 0 0 1 .255-.216.28.28 0 0 1 .292.156c2.967 5.787 8.644 38.602 9.219 41.984l1.29.062c.854.047 1.714.022 2.562-.062l1.359-.141c1-22.532-3.622-55.913-4.044-58.9l.006.005h.003Z"
    />
    <path
      fill="#081906"
      d="M20.658 111.778a.3.3 0 0 0 .286-.216l.863-3.147a2.012 2.012 0 0 1 1.546-1.454l.807-.169a.296.296 0 0 0-.118-.579l-.807.169c-.969.2-1.73.92-1.993 1.88l-.86 3.147a.29.29 0 0 0 .208.363c.022 0 .053.006.078.006h-.01ZM9.31 105.913h.048c4.814-.804 5.798-4.664 5.813-4.705a.296.296 0 0 0-.214-.356.294.294 0 0 0-.354.216c-.037.147-.915 3.519-5.335 4.263a.288.288 0 0 0-.24.341.29.29 0 0 0 .292.247l-.006-.006h-.003Zm22.784-2.334a.294.294 0 0 0 .27-.178.294.294 0 0 0-.156-.388c-2.476-1.082-2.414-3.394-2.414-3.419a.303.303 0 0 0-.286-.31.305.305 0 0 0-.307.288c0 .031-.069 2.747 2.768 3.982a.293.293 0 0 0 .115.022h.006l.004.003Z"
    />
    <path
      fill="#081906"
      d="M31.448 63.338c-3.415-.388-10.706-1.082-15.718-.619-1.515.14-3.7 1.067-4.852 2.065-3.393 2.946-9.79 9.428-10.857 17.21a2.208 2.208 0 0 0 1.697 2.453l5.913 1.314s-2.352 9.344-2.17 10.955c.193 1.618 5.076 4.358 11.181 2.106 4.758-1.755 11.503-3.157 15.78-1.64.04.016.454 3.319.494 3.326 1.375.2 2.76-.341 2.722-2.284l-.652-10.088 7.835-1.045.701-.232a3.915 3.915 0 0 0 2.39-5.146c-1.837-4.71-4.612-12.337-9.759-16.554-1.099-.898-3.3-1.67-4.705-1.827v.006Z"
    />
    <path
      fill="#D6EF89"
      d="M27.887 94.389H40.13a1.16 1.16 0 0 0 1.124-.882l4.237-17.18a1.164 1.164 0 0 0-1.124-1.449H32.124c-.53 0-.993.363-1.123.883l-4.237 17.18a1.161 1.161 0 0 0 1.123 1.445v.003Z"
    />
    <path
      fill="#fff"
      d="m43.498 83.66-.407 1.732-1.478-.494a11.448 11.448 0 0 1-2.33-1.082l-.848-.52c-.63-.388-1.453-.084-1.707.62-.525 1.492-.422 3.998 4.913 5.568 2.946.867 3.784-2.925 3.405-5.027-.099-.541-.36-.882-.63-1.098a.565.565 0 0 0-.906.3h-.016.004Z"
    />
    <path
      fill="#081906"
      d="M41.545 89.769c-2.607-.767-4.274-1.85-4.966-3.21-.423-.835-.478-1.786-.14-2.746.146-.426.469-.751.89-.898.417-.147.87-.1 1.248.125l.848.52c.714.43 1.477.787 2.275 1.06l1.176.394.339-1.423a.854.854 0 0 1 .562-.61.858.858 0 0 1 .816.147c.391.319.64.74.739 1.276.285 1.564-.063 3.797-1.254 4.88a2.36 2.36 0 0 1-1.624.625 3.16 3.16 0 0 1-.9-.14h-.009Zm2.415-6.225a.26.26 0 0 0-.177.194l-.407 1.733a.3.3 0 0 1-.146.194.3.3 0 0 1-.239.021l-1.477-.494a11.72 11.72 0 0 1-2.39-1.113l-.848-.517a.855.855 0 0 0-.738-.078.887.887 0 0 0-.531.541c-.286.814-.245 1.577.109 2.284.614 1.207 2.17 2.19 4.606 2.91.785.23 1.446.109 1.968-.354.99-.898 1.322-2.91 1.067-4.333a1.472 1.472 0 0 0-.524-.92.284.284 0 0 0-.168-.062c-.03 0-.062 0-.093.016l-.015-.016.003-.006Z"
    />
    <path
      fill="#fff"
      d="M15.186 73.718a9.468 9.468 0 0 1-2.067 1.608c-2.567 1.47-8.713 5.036-10.234 6.35-1.946 1.67-2.877 2.3-1.316 6.597 2.545 7.017 14.433-10.992 14.433-10.992s3.492.635 4.274-2.443c0 0 1.823.34 2.577.016.583-.254.552-1.245-.224-1.355l-5.022-.945c-.692-.122-1.4.1-1.89.61l-.537.55h.006v.004Z"
    />
    <path
      fill="#081906"
      d="M3.196 90.172c-.87-.1-1.515-.71-1.906-1.802-1.583-4.364-.646-5.174 1.229-6.776l.168-.147c1.545-1.33 7.704-4.905 10.28-6.381a9.183 9.183 0 0 0 2.008-1.555l.537-.55a2.42 2.42 0 0 1 2.154-.695l5.007.935a.986.986 0 0 1 .869.851c.062.457-.177.889-.568 1.067-.661.288-1.922.14-2.483.056-.838 2.6-3.582 2.496-4.346 2.422C15.13 79.114 7.578 90.19 3.456 90.19c-.083 0-.17 0-.254-.016h-.006v-.003ZM16.052 76.99c.13.022 3.231.556 3.936-2.228a.286.286 0 0 1 .338-.216c.484.094 1.853.273 2.406.032.183-.079.245-.288.223-.441-.015-.116-.084-.31-.378-.348l-5.023-.941a1.798 1.798 0 0 0-1.63.525l-.536.551a9.58 9.58 0 0 1-2.139 1.655c-2.56 1.47-8.681 5.02-10.19 6.319l-.168.147C1.122 83.562.393 84.188 1.84 88.17c.317.876.785 1.339 1.422 1.414.068.006.13.015.198.015 3.315 0 9.958-8.956 12.289-12.478a.286.286 0 0 1 .245-.131h.059Z"
    />
    <path
      fill="#fff"
      d="m26.82 56.731.376 5.262a1.68 1.68 0 0 0 1.238 1.423l1.838.482-1.192.914a7.344 7.344 0 0 1-8.942.006l-1.885-1.44 2.285-.509a1.666 1.666 0 0 0 1.276-1.339l1.005-6.963 4.008 2.164h-.007Z"
    />
    <path
      fill="#081906"
      d="m19.95 65.056-1.883-1.44a.3.3 0 0 1-.109-.303.29.29 0 0 1 .224-.225l2.284-.51a1.38 1.38 0 0 0 1.052-1.098l1.009-6.963a.324.324 0 0 1 .161-.225.303.303 0 0 1 .27 0l4.008 2.164a.296.296 0 0 1 .155.241l.379 5.246a1.36 1.36 0 0 0 1.015 1.167l1.837.479a.306.306 0 0 1 .217.225.314.314 0 0 1-.108.294l-1.192.913a7.64 7.64 0 0 1-4.66 1.577 7.623 7.623 0 0 1-4.643-1.564l-.006.016-.01.006Zm8.406-1.355a1.96 1.96 0 0 1-1.452-1.686l-.364-5.099-3.498-1.886-.946 6.55a1.966 1.966 0 0 1-1.509 1.577l-1.66.372 1.384 1.051a7.084 7.084 0 0 0 8.588-.006l.708-.541-1.254-.325V63.7h.003Z"
    />
    <path
      fill="#081906"
      d="M32.764 49.412c-1.254-.666-.245 1.414-4.377-2.184-1.645-1.43-1.707 1.245-2.129.876-1.583-1.37-1.524-1.323-2.523-1.501-.662-.116-1.546.804-1.807 1.67-.345 1.145.407 1.833.155 4.47-.183 1.85-.76 2.763-.23 3.288.538.535 1.407-.116 2.04.42.813.694-.115 2.236.484 3.334.376.688 1.623 1.502 4.246 1.439a.888.888 0 0 0 .854-.75c.093-.542.198-1.23.245-1.896.23-3.166-.447-4.217.223-4.759 1.025-.828 2.754.714 3.492-.015.77-.757 1.053-3.482-.67-4.401v.006l-.003.003Z"
    />
    <path
      fill="#fff"
      d="m26.513 51.186.052.866c.022.41.022.813-.006 1.223-.062.767-.155 2.074-.146 2.816 0 1.082 3.337 1.3 3.337 1.3l.422-.471.391.015a.424.424 0 0 0 .447-.494c-.161-.96-.431-3.072-.13-4.717 0 0-1.853-1.517-4.367-.526v-.012Z"
    />
    <path
      fill="#081906"
      d="M29.725 57.673c-.599-.038-3.607-.294-3.616-1.592 0-.757.093-2.106.146-2.84.031-.395.031-.789.006-1.183l-.052-.867a.299.299 0 0 1 .183-.294c2.644-1.035 4.575.51 4.659.573a.288.288 0 0 1 .099.288c-.301 1.623-.006 3.766.13 4.617a.713.713 0 0 1-.748.835h-.251l-.332.357a.29.29 0 0 1-.215.1h-.015v.006h.006Zm.453-1.057.391.015s.053 0 .069-.006a.121.121 0 0 0 .068-.131c-.17-1.051-.416-3.01-.161-4.633a4.277 4.277 0 0 0-3.738-.457l.038.641c.021.416.021.845-.006 1.261-.053.726-.146 2.058-.146 2.793 0 .51 1.654.892 2.92.992l.33-.363c.055-.062.14-.1.217-.1h.015v-.006l.003-.006Z"
    />
    <path
      fill="#fff"
      d="M25.92 53.844s-.124-2.08-1.046-1.817c-.925.262-.73 2.546 1.046 1.817Zm6.527-21.503c2.62-7.292-1.13-15.34-8.377-17.977-7.248-2.638-15.25 1.135-17.873 8.43-2.62 7.292 1.13 15.34 8.378 17.978 7.247 2.637 15.25-1.136 17.869-8.428l.003-.003Z"
    />
    <path
      fill="#081906"
      d="M19.29 41.907a14.119 14.119 0 0 1-9.15-3.366 14.284 14.284 0 0 1-5.029-9.738c-.677-7.874 5.143-14.837 12.971-15.519a14.11 14.11 0 0 1 10.405 3.31 14.284 14.284 0 0 1 5.028 9.738 14.3 14.3 0 0 1-3.29 10.467A14.151 14.151 0 0 1 19.29 41.91v-.006.003Zm.056-28.088c-.4 0-.801.016-1.198.053-7.506.65-13.09 7.326-12.44 14.878.316 3.66 2.023 6.979 4.82 9.338A13.499 13.499 0 0 0 20.5 41.26a13.516 13.516 0 0 0 9.281-4.852c2.353-2.81 3.467-6.376 3.154-10.032-.317-3.66-2.024-6.98-4.82-9.338a13.49 13.49 0 0 0-8.775-3.225l.006.006Z"
    />
    <path
      fill="#D6EF89"
      d="M24.247 39.248c6.412-2.734 9.405-10.182 6.686-16.632-2.72-6.45-10.122-9.463-16.535-6.726-6.413 2.734-9.405 10.182-6.686 16.633 2.72 6.45 10.122 9.462 16.535 6.728v-.003Z"
    />
    <path
      fill="#fff"
      d="M30.12 30.392c1.552-6-2.027-12.128-7.99-13.69-5.966-1.56-12.055 2.037-13.608 8.037-1.551 6 2.027 12.128 7.99 13.69 5.963 1.56 12.056-2.04 13.608-8.037Z"
    />
    <path
      fill="#081906"
      d="M19.315 39.442c-6.053 0-11.22-4.673-11.752-10.855-.562-6.522 4.262-12.29 10.743-12.856a11.746 11.746 0 0 1 10.196 4.37.3.3 0 0 1-.046.419.295.295 0 0 1-.416-.047 11.2 11.2 0 0 0-9.69-4.154c-6.159.535-10.743 6.012-10.213 12.215.506 5.872 5.414 10.31 11.165 10.31.323 0 .646-.015.978-.037a11.148 11.148 0 0 0 7.62-3.976 11.254 11.254 0 0 0 2.592-8.23.293.293 0 0 1 .27-.319.292.292 0 0 1 .317.272 11.85 11.85 0 0 1-2.729 8.665 11.7 11.7 0 0 1-8.02 4.186 11.49 11.49 0 0 1-1.025.047l.007-.007.003-.003Z"
    />
    <path
      fill="#7EC41F"
      d="m19.029 34.306-.13-1.555c-1.093.047-2.176-.154-2.822-.463l.323-1.949c.714.32 1.698.572 2.753.488.922-.078 1.53-.494 1.468-1.145-.052-.62-.608-.967-1.847-1.27-1.784-.432-3.045-1.154-3.175-2.737-.124-1.44.785-2.653 2.493-3.147l-.13-1.555 1.591-.14.125 1.438c1.092-.047 1.843.116 2.4.332l-.318 1.89c-.437-.141-1.213-.457-2.383-.357-1.053.094-1.354.579-1.316 1.038.046.541.645.836 2.067 1.24 1.99.518 2.846 1.344 2.977 2.814.124 1.455-.792 2.788-2.63 3.273l.146 1.664-1.592.137v.007-.003Z"
    />
    <path
      fill="#fff"
      d="M46.533 23.31c6.192-1.774 9.783-8.258 8.023-14.49-1.76-6.228-8.21-9.844-14.402-8.07-6.192 1.773-9.784 8.258-8.024 14.49 1.763 6.228 8.21 9.844 14.403 8.07Z"
    />
    <path
      fill="#081906"
      d="M43.351 24.048h-.369c-6.59-.2-11.78-5.756-11.58-12.384a12.01 12.01 0 0 1 3.752-8.393A11.837 11.837 0 0 1 43.705.005c3.191.1 6.152 1.439 8.344 3.776 2.191 2.336 3.336 5.393 3.243 8.602a12.01 12.01 0 0 1-3.753 8.393 11.847 11.847 0 0 1-8.181 3.266l-.01.006h.003ZM43.336.593c-2.905 0-5.652 1.098-7.782 3.11a11.396 11.396 0 0 0-3.566 7.976C31.795 17.977 36.74 23.26 43 23.454a11.243 11.243 0 0 0 8.135-3.103 11.412 11.412 0 0 0 3.57-7.977c.093-3.056-1-5.956-3.083-8.184A11.264 11.264 0 0 0 43.696.603h-.354V.596l-.006-.003Z"
    />
    <path
      fill="#D6EF89"
      d="M53.237 15.659c1.993-5.5-.822-11.584-6.288-13.586-5.466-2.005-11.513.829-13.505 6.328-1.993 5.5.822 11.58 6.288 13.59 5.466 2.004 11.513-.83 13.505-6.33v-.002Z"
    />
    <path
      fill="#fff"
      d="M43.345 21.402c5.147 0 9.318-4.198 9.318-9.376 0-5.177-4.171-9.375-9.318-9.375-5.146 0-9.32 4.198-9.32 9.376 0 5.177 4.171 9.375 9.32 9.375Z"
    />
    <path
      fill="#081906"
      d="M43.351 21.996h-.307c-5.46-.169-9.765-4.773-9.604-10.266a9.895 9.895 0 0 1 3.114-6.954 9.822 9.822 0 0 1 7.089-2.71 9.905 9.905 0 0 1 8.083 4.643.3.3 0 0 1-.094.41.295.295 0 0 1-.406-.094 9.326 9.326 0 0 0-7.599-4.37A9.208 9.208 0 0 0 36.96 5.2a9.337 9.337 0 0 0-2.93 6.544c-.155 5.168 3.899 9.5 9.036 9.654 5.127.156 9.442-3.923 9.597-9.09 0-.163.14-.295.301-.288a.29.29 0 0 1 .286.3c-.162 5.393-4.576 9.663-9.896 9.663v.006l-.003.006Z"
    />
    <path
      fill="#7EC41F"
      d="m42.46 17.589.041-1.298c-.91-.07-1.785-.341-2.29-.657l.453-1.586c.561.331 1.353.64 2.237.672.776.022 1.316-.263 1.332-.813.016-.52-.407-.857-1.406-1.239-1.437-.535-2.415-1.254-2.375-2.574.038-1.208.907-2.121 2.375-2.368l.037-1.299 1.332.038-.038 1.207c.907.07 1.515.279 1.962.51l-.437 1.533c-.345-.163-.969-.494-1.947-.525-.884-.022-1.182.347-1.191.725-.016.448.453.751 1.598 1.23 1.599.625 2.229 1.401 2.191 2.63-.04 1.214-.925 2.228-2.498 2.46l-.04 1.391-1.332-.037h-.003Z"
    />
    <path
      fill="#D6EF89"
      d="M146.979 19.102a.276.276 0 0 0 .198-.084.295.295 0 0 0 .006-.42l-.723-.756a.29.29 0 0 0-.416-.006.295.295 0 0 0-.006.419l.723.757c.056.062.14.094.218.094v-.004Zm2.52 2.648a.276.276 0 0 0 .199-.085.298.298 0 0 0 .009-.42l-.723-.757a.29.29 0 0 0-.416-.006.291.291 0 0 0-.006.416l.723.757a.29.29 0 0 0 .214.094Zm-.707-2.6a.316.316 0 0 0 .208-.085l.729-.726a.298.298 0 0 0 0-.419.293.293 0 0 0-.416 0l-.729.726a.298.298 0 0 0 0 .42c.052.062.13.084.208.084Zm-2.558 2.53a.316.316 0 0 0 .208-.084l.729-.729a.294.294 0 1 0-.416-.416l-.729.73a.294.294 0 0 0 0 .415c.053.063.13.085.208.085ZM36.538 41.25a.291.291 0 0 0 .292-.287l.022-1.045c0-.163-.124-.304-.286-.304a.291.291 0 0 0-.292.288l-.021 1.045c0 .163.124.303.285.303Zm-.078 3.66a.291.291 0 0 0 .292-.288l.022-1.045c0-.162-.124-.3-.286-.3a.29.29 0 0 0-.291.285l-.022 1.044c0 .163.124.304.286.304Zm2.362-2.336a.293.293 0 0 0 .292-.294.302.302 0 0 0-.291-.3h-1.031v-.007a.293.293 0 0 0-.292.294c0 .163.13.294.292.303h1.03v.007-.003Zm-3.59-.023a.293.293 0 0 0 .291-.294.302.302 0 0 0-.291-.3h-1.03v-.006a.293.293 0 0 0-.293.294c0 .162.13.294.292.303h1.03v.006-.003Z"
    />
    <path
      fill="#081906"
      d="M131.338 77.934a.276.276 0 0 0 .199-.084.295.295 0 0 0 .006-.42l-.723-.756a.29.29 0 0 0-.413-.007.295.295 0 0 0-.006.42l.723.757c.056.062.14.094.217.094l-.003-.004Zm2.53 2.647a.288.288 0 0 0 .201-.084.294.294 0 0 0 .006-.416l-.723-.757a.293.293 0 0 0-.416-.007c-.115.116-.124.304-.006.417l.723.757a.29.29 0 0 0 .215.093v-.003Zm-.708-2.609a.314.314 0 0 0 .208-.084l.73-.73a.294.294 0 1 0-.416-.415l-.73.729a.294.294 0 0 0 0 .416.263.263 0 0 0 .208.084Zm-2.56 2.531a.316.316 0 0 0 .208-.085l.729-.725a.294.294 0 1 0-.416-.416l-.729.725a.294.294 0 0 0 0 .416c.056.063.13.085.208.085Z"
    />
    <path
      fill="#D6EF89"
      d="M139.281 12.605a.306.306 0 0 0 .245-.13.295.295 0 0 0-.078-.41c-13.21-9.082-26.128-8.697-32.659-7.668-.161.022-.267.178-.245.341a.293.293 0 0 0 .338.247c6.444-1.02 19.195-1.401 32.234 7.567a.276.276 0 0 0 .168.053h-.006.003Z"
    />
    <path
      fill="#D6EF89"
      d="M126.515 10.168a.287.287 0 0 0 .276-.2.298.298 0 0 0-.177-.378c-14.064-4.968-25.759-1.067-25.874-1.03a.296.296 0 0 0-.183.38c.053.152.223.24.379.184.114-.038 11.627-3.876 25.489 1.02.031.006.062.015.099.015l-.006.006-.003.004Z"
    />
  </svg>
);
