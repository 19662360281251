import * as React from 'react';
import { toast } from 'react-toastify';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ClearIcon from '@mui/icons-material/Clear';

import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  IconButton,
} from '@mui/material';
import { uploadFile } from '../../../api/request';

import AttachmentButton from '../../../components/AttachmentButton';
import {
  Div18,
  Div19,
  Div20,
  Div25,
  Div28,
  Div37,
  Div38,
  StyledContentButtons,
  StyledContentCheckbox,
  StyledContentFiles,
  StyledContentFilesItem,
  StyledLink,
  StyledSpanCheckBox,
} from './styles';

import useAuthenticate from '../../../recoil/hooks/authenticate';

interface AttachmentFile {
  id: string;
  name: string;
}

interface Props {
  accountFiles: AttachmentFile[];
  loading: boolean;
  setAccountsFiles: (accountFiles: AttachmentFile[]) => void;
  handleSubmit: (accountFiles: AttachmentFile[]) => void;
  handleModalError: (message?: string, cause?: never[]) => void;
}

const StepOneRequest: React.FC<Props> = ({
  accountFiles,
  loading,
  setAccountsFiles,
  handleSubmit,
  handleModalError,
}): React.ReactElement => {
  const [acceptTerms, setAcceptTerms] = React.useState(false);

  const { authenticate } = useAuthenticate();

  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    if (accountFiles.length) {
      setAcceptTerms(true);
    }
    // Tem deixar desabiltado essa linha abaixo mesmo, pois o comportamento esperado depende dela estar desabilitada
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmitAccounts = async (): Promise<void> => {
    if (!accountFiles.length) {
      toast('Por favor, anexe os arquivos com as contas.', {
        type: 'error',
      });
      return;
    }

    if (!acceptTerms) {
      toast('Por favor, aceite os termos.', {
        type: 'error',
      });
      return;
    }

    handleSubmit(accountFiles);
  };

  const removeAccountItem = (id: string): void => {
    setAccountsFiles(accountFiles.filter((item) => item.id !== id));
  };

  const handleUploadAccountFile = async (files: FileList): Promise<void> => {
    try {
      setIsLoading(true);
      const result = await uploadFile(files, 'account', authenticate.token);

      setAccountsFiles([...accountFiles, ...result.data]);
    } catch (error: any) {
      handleModalError(
        error?.response?.data?.message,
        error?.response?.data?.cause
      );
    } finally {
      setIsLoading(false);
    }
  };

  const makeUrlConfirm = React.useMemo((): React.ReactNode => {
    if (authenticate.user?.codeUrl && authenticate.user?.codeName) {
      return (
        <StyledSpanCheckBox>
          Confirmo que me inscrevi na promoção{' '}
          <b>{authenticate.user.codeName}</b> e estou ciente das{' '}
          <StyledLink
            href={authenticate.user.codeUrl}
            target="_blank"
            rel="noreferrer"
          >
            regras disponíveis
          </StyledLink>
        </StyledSpanCheckBox>
      );
    }

    if (authenticate.user?.codeName) {
      return (
        <StyledSpanCheckBox>
          Confirmo que me inscrevi na promoção{' '}
          <b>{authenticate.user.codeName}</b>
        </StyledSpanCheckBox>
      );
    }

    return (
      <span>
        Confirmo que estou ciente da solicitação e da veracidade das informações
        enviadas!
      </span>
    );
  }, [authenticate]);

  return (
    <React.Fragment>
      <Div18>
        <Div19>Anexar arquivo com as contas para crédito de milhas</Div19>
        <Div20>
          Anexe um ou mais arquivos xlsx com as contas do fidelidade para
          crédito das milhas
        </Div20>
      </Div18>
      <Div25>
        <AttachmentButton
          label="Anexar arquivo xlsx*"
          disabled={isLoading || loading}
          handleChange={handleUploadAccountFile}
          acceptTypes=".xlsx"
        />
        {accountFiles?.length ? (
          <Div28>
            {accountFiles?.length > 1
              ? `${accountFiles.length} arquivos adicionados`
              : '1 arquivo adicionado'}
          </Div28>
        ) : (
          <Div28>Nenhum arquivo adicionado</Div28>
        )}
        <StyledContentFiles>
          {accountFiles.map((file) => (
            <StyledContentFilesItem key={file.id}>
              <div>{file.name}</div>
              <IconButton onClick={() => removeAccountItem(file.id)}>
                <ClearIcon fontSize="small" color="error" />
              </IconButton>
            </StyledContentFilesItem>
          ))}
        </StyledContentFiles>

        <StyledContentCheckbox>
          <FormGroup>
            <FormControlLabel
              required
              control={
                <Checkbox
                  value={acceptTerms}
                  checked={acceptTerms}
                  defaultChecked={acceptTerms}
                  onChange={(e) => setAcceptTerms(e.target.checked)}
                />
              }
              label={makeUrlConfirm}
            />
          </FormGroup>
        </StyledContentCheckbox>
      </Div25>
      <StyledContentButtons>
        <Div37 onClick={() => !isLoading && !loading && handleSubmitAccounts()}>
          <Div38>Próximo</Div38>

          {isLoading || loading ? (
            <CircularProgress color="inherit" size={20} />
          ) : (
            <ArrowForwardIcon />
          )}
        </Div37>
      </StyledContentButtons>
    </React.Fragment>
  );
};

export default StepOneRequest;
