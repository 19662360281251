import styled from 'styled-components';

export const StyledFooter = styled.footer`
  border-top: 1px solid var(--color-gray-extra-light);
`;

export const StyledFooterInner = styled.div`
  display: grid;
  gap: 40px;
  padding: 40px 24px;
  max-width: var(--container-max-width);
  margin: 0 auto;

  @media (min-width: 768px) {
    grid-template-columns: repeat(4, 1fr);
    padding: 40px 16px;
  }
`;

export const StyledCol = styled.div`
  width: 100%;

  &:last-child {
    @media (min-width: 768px) {
      max-width: 130px;
    }
  }
`;

export const StyledTitle = styled.h4`
  font-size: 24px;
  color: var(--color-primary-dark);
  margin-bottom: 16px;

  @media (min-width: 768px) {
    font-size: 20px;
  }
`;

export const StyledMenu = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
`;

export const StyledMenuItem = styled.a`
  font-size: 20px;
  color: var(--color-gray);
  text-decoration: none;

  &:hover {
    color: var(--color-gray-dark);
    text-decoration: underline;
  }

  @media (min-width: 768px) {
    font-size: 16px;
  }
`;

export const StyledLogos = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 32px;
  max-width: 360px;

  img {
    width: 100%;
    max-width: 150px;
    pointer-events: none;
  }

  @media (min-width: 768px) {
    gap: 12px;

    img {
      max-width: 115px;
    }

    img:last-child {
      display: none;
    }
  }
`;

export const StyledCopyRight = styled.p`
  display: block;
  padding: 16px 32px;
  text-align: center;
  font-size: 14px;
  line-height: 1.5;
  border-top: 1px solid var(--color-gray-extra-light);
`;
