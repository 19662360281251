import styled from 'styled-components';

export const StyledRoot = styled('picture')({
  width: '100%',
  maxWidth: '600px',
  margin: '16px auto 32px',
  cursor: 'pointer',

  '& img': {
    width: '100%',
    height: 'auto',
  },

  '@media (min-width: 900px)': {
    marginTop: 8,
    maxWidth: '100%',
  },
});
