import styled from 'styled-components';

export const StyledHeader = styled.header`
  padding: 16px 24px;
`;

export const StyledHeaderInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: var(--container-max-width);
  margin: 0 auto;
`;

export const StyledToggleMenuButton = styled.button`
  appearance: none;
  background: none;
  border: none;
  width: 24px;
  color: var(--color-primary-dark);
  cursor: pointer;
  line-height: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 24px;
  margin-top: 5px;

  svg {
    width: 100%;
  }
`;
