import { Box } from '@mui/material';
import styled from 'styled-components';

export const StyleBox = styled(Box)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 300,
  maxWidth: 720,
  boxShadow: '24px',
  outline: 'none',
  background: '#fff',
  borderRadius: 8,
  padding: 20,
  boxSizing: 'border-box',
  color: '#777',
  maxHeight: '80vh',
  display: 'flex',
  flexDirection: 'column',
});

export const FormLoginStyled = styled.form`
  width: 100%;
  height: 100%;
`;

export const Div7 = styled.div`
  font-family: Rubik, sans-serif;
  margin-top: 32px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;
