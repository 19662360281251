import React, { ReactElement } from 'react';
import { CircularProgress } from '@mui/material';
import { Div37, Div38, StyledButton, StyledSubmitButton } from './styles';

interface SubmitButtonProps {
  children: ReactElement | string;
  onClick?: (event: any) => void;
  disabled?: boolean;
  style?: any;
}

export const SubmitButton = ({
  children,
  onClick,
  disabled,
  style,
}: SubmitButtonProps): any => {
  return (
    <StyledSubmitButton
      onClick={onClick}
      type="submit"
      disabled={disabled}
      style={style}
    >
      {children}
    </StyledSubmitButton>
  );
};

export const Button = ({
  children,
  onClick,
  disabled,
  style,
}: SubmitButtonProps): any => {
  return (
    <StyledButton onClick={onClick} disabled={disabled} style={style}>
      {children}
    </StyledButton>
  );
};

interface ButtonWithIconProps {
  label: string;
  isLoading?: boolean;
  onClick?: () => void;
  icon?: ReactElement;
}

export const ButtonWithIcon = ({
  isLoading,
  label,
  icon,
  onClick,
}: ButtonWithIconProps): any => {
  return (
    <Div37 onClick={() => !isLoading && onClick && onClick()}>
      <Div38>{label}</Div38>
      {isLoading && <CircularProgress color="inherit" size={20} />}
      {!isLoading && icon && <React.Fragment>{icon}</React.Fragment>}
    </Div37>
  );
};

export default SubmitButton;
