import React from 'react';
import { toast } from 'react-toastify';
import { submitNewsletter } from '../../../../api/newsletter';
import { ModalForm } from './ModalForm';
import {
  StyledActionButton,
  StyledCampaignSoon,
  StyledPicture,
} from './styles';

export const CampaignSoon = (): React.ReactElement => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const handleToggleModal = () => setIsModalOpen((prev) => !prev);

  const handleSubmitNesletter = async (data: {
    name: string;
    whatsapp: string;
    email: string;
  }) => {
    try {
      await submitNewsletter(data);

      toast('Inscrição realizada com sucesso!', {
        type: 'success',
      });

      handleToggleModal();
    } catch (error) {
      toast('Erro ao realizar inscrição. Tente novamente mais tarde.', {
        type: 'error',
      });
    }
  };

  return (
    <StyledCampaignSoon>
      <StyledPicture onClick={handleToggleModal}>
        <source
          srcSet="/images/landing-page/banner-soon.desktop.png"
          media="(min-width: 700px)"
        />
        <img
          src="/images/landing-page/banner-soon.mobile.png"
          alt="Milhas na Conta"
        />
      </StyledPicture>

      <StyledActionButton onClick={handleToggleModal}>
        Inscreva-se
      </StyledActionButton>

      <ModalForm
        open={isModalOpen}
        onSubmit={handleSubmitNesletter}
        onClose={handleToggleModal}
      />
    </StyledCampaignSoon>
  );
};
