import styled from 'styled-components';

export const StyledButton = styled.a<{ noShadow?: boolean }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  max-width: 100%;
  min-height: 54px;
  padding: 16px 32px;
  background: var(--color-primary-dark);
  color: var(--color-white);
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
  border-radius: 30px;
  line-height: 1;
  cursor: pointer;

  ${({ noShadow }) =>
    !noShadow &&
    `
    box-shadow: 0 16px 60px rgba(126, 196, 31, 0.5);
  `}

  @media (min-width: 600px) {
    display: none;
  }
`;
