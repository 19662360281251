import React from 'react';
import { RecoilRoot } from 'recoil';
import RecoilNexus from 'recoil-nexus';

import ReactDOM from 'react-dom';
import { pdfjs } from 'react-pdf';

import App from './App';
import Connect from './Connect';

import * as serviceWorker from './serviceWorkerRegistration';
import Footer from './components/Footer';

const url = `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

pdfjs.GlobalWorkerOptions.workerSrc = url;

// eslint-disable-next-line react/no-deprecated
ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <RecoilNexus />
      <Connect>
        <App />
      </Connect>
    </RecoilRoot>
    <Footer />
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.unregister();
