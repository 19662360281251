import styled from 'styled-components';

export const StyledButton = styled('button')(({ disabled }) => {
  return {
    fontFamily: 'Rubik, sans-serif',
    display: 'flex',
    borderRadius: '4px',
    backgroundColor: '#fff',
    marginTop: '47px',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#79cc72',
    whiteSpace: 'nowrap',
    textAlign: 'center',
    padding: '12px 60px',
    width: '100%',
    height: '40px',
    pointerEvents: disabled ? 'none' : 'auto',
    cursor: 'pointer',
    border: '1px solid #79cc72aa',

    '@media (max-width: 991px) ': {
      maxWidth: '100%',
      marginTop: '40px',
      whiteSpace: 'initial',
      padding: '0 20px',
    },
    '&:hover': {
      backgroundColor: '#57aa5022',
      border: '1px solid #79cc72aa',
    },
  };
});

export const StyledSubmitButton = styled('button')(({ disabled }) => {
  return {
    fontFamily: 'Poppins, sans-serif',
    display: 'flex',
    borderRadius: '4px',
    backgroundColor: 'rgba(121, 204, 114, 1)',
    marginTop: '47px',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
    whiteSpace: 'nowrap',
    textAlign: 'center',
    transition: '0.4s',
    padding: '12px 60px',
    width: '100%',
    height: '40px',
    pointerEvents: disabled ? 'none' : 'auto',
    cursor: 'pointer',
    border: '1px solid #79cc72aa',

    fontWeight: 'bold',
    fontSize: 14,

    '@media (max-width: 991px) ': {
      maxWidth: '100%',
      marginTop: '40px',
      whiteSpace: 'initial',
      padding: '0 20px',
    },
    '&:hover': {
      backgroundColor: '#57aa50',
      border: '1px solid #79cc72aa',
    },
  };
});

export const Div37 = styled.div`
  border-radius: 4px;
  background-color: #79cc72;
  display: flex;
  padding-left: 13px;
  padding-right: 10px;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  font-size: 13px;
  color: #fff;
  font-weight: 400;
  margin: auto 0;
  height: 40px;
  cursor: pointer;
  &:hover {
    cursor: pointer;
    background-color: #69bc62;
  }
`;

export const Div38 = styled.div`
  font-family: Poppins, sans-serif;
  margin: auto 0;
  font-weight: bold;
`;
