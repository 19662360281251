import React, { ReactElement, useMemo } from 'react';

import { StyledFooter } from './styles';
import config from '../../config/config';

const Footer: React.FC = (): ReactElement => {
  const urlPrivacy = useMemo(() => {
    return `${config.urlServer}/privacy`;
  }, []);

  const urlTerms = useMemo(() => {
    return `${config.urlServer}/terms`;
  }, []);

  return (
    <StyledFooter>
      <div style={{ flex: 1, textAlign: 'left' }}>
        RP Travel & Tech • CNPJ: 54.264.159/0001-42
      </div>
      <div
        style={{
          flex: 1,
          textAlign: 'right',
        }}
      >
        <a
          href={urlPrivacy}
          target="_blank"
          rel="noreferrer"
          style={{
            cursor: 'pointer',
            padding: 10,
            boxSizing: 'border-box',
            textDecoration: 'none',
          }}
        >
          Política de Privacidade
        </a>
        <span>•</span>
        <a
          href={urlTerms}
          target="_blank"
          rel="noreferrer"
          style={{
            cursor: 'pointer',
            padding: 10,
            boxSizing: 'border-box',
            textDecoration: 'none',
          }}
        >
          Termos e condições
        </a>
      </div>
    </StyledFooter>
  );
};

export default Footer;
