import React from 'react';
import { handleGoToTop } from '../../utils';
import {
  StyledBanner,
  StyledButtonAction,
  StyledPlane,
  StyledSection,
} from './styles';

export const BannerAction = (): React.ReactElement => (
  <StyledSection>
    <StyledPlane src="/images/landing-page/paper-plane.png" alt="" />
    <StyledBanner src="/images/landing-page/banner-action.png" alt="" />
    <StyledButtonAction onClick={handleGoToTop}>
      Comprar Agora
    </StyledButtonAction>
  </StyledSection>
);
