import { Modal } from '@mui/material';
import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import Plane from '../../../../images/Plane';
import {
  formatPhoneNumber,
  removePhoneNumberMask,
} from '../../../../utils/mask';
import {
  StyledButton,
  StyledButtonClose,
  StyledInput,
  StyledModal,
  StyledModalDescription,
  StyledModalForm,
  StyledModalHeader,
  StyledModalTitle,
} from './styles';

const INITIAL_VALUES = {
  name: '',
  whatsapp: '',
  email: '',
};

interface ModalFormProps {
  open: boolean;
  onSubmit: (data: { name: string; whatsapp: string; email: string }) => void;
  onClose: () => void;
}

export const ModalForm = ({
  open,
  onSubmit,
  onClose,
}: ModalFormProps): React.ReactElement => {
  const [data, setData] = React.useState(INITIAL_VALUES);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    let valueFormatted = value;

    if (name === 'whatsapp') {
      valueFormatted = formatPhoneNumber(value);
    }

    setData((prevData) => ({
      ...prevData,
      [name]: valueFormatted,
    }));
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!data.name.trim() || !data.whatsapp.trim() || !data.email) {
      toast('Por favor, preencha os campos antes de submeter o formulário.', {
        type: 'error',
      });
      return;
    }

    const whatsapp = removePhoneNumberMask(data.whatsapp);

    onSubmit({
      ...data,
      whatsapp,
    });
  };

  useEffect(() => {
    if (open) {
      setData(INITIAL_VALUES);
    }
  }, [open]);

  return (
    <Modal open={open} onClose={onClose}>
      <StyledModal>
        <StyledModalHeader>
          <Plane />
          <StyledButtonClose onClick={onClose}>
            <svg
              width="25"
              height="24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.905 6.41 18.491 5l-5.604 5.59L7.283 5 5.869 6.41 11.474 12l-5.605 5.59L7.283 19l5.604-5.59L18.491 19l1.414-1.41L14.301 12l5.604-5.59Z"
                fill="#B9BABE"
              />
            </svg>
          </StyledButtonClose>
        </StyledModalHeader>

        <StyledModalTitle>Fique por dentro</StyledModalTitle>
        <StyledModalDescription>
          Reserve seu assento conforto para saber das promoções e garanta os
          melhores preços do mercado!
        </StyledModalDescription>

        <StyledModalForm onSubmit={handleSubmit}>
          <StyledInput
            size="small"
            label="Nome"
            helperText="Ex.: João"
            name="name"
            value={data.name}
            onChange={handleChange}
          />
          <StyledInput
            size="small"
            label="WhatsApp"
            helperText="Ex.: 11 9 12345-6789"
            type="tel"
            name="whatsapp"
            value={data.whatsapp}
            onChange={handleChange}
          />
          <StyledInput
            size="small"
            label="E-mail"
            helperText="Ex.: seu_email@dominio.com"
            type="email"
            name="email"
            value={data.email}
            onChange={handleChange}
          />

          <StyledButton type="submit">Cadastrar</StyledButton>
        </StyledModalForm>
      </StyledModal>
    </Modal>
  );
};
