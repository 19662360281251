import styled from 'styled-components';

import { Box } from '@mui/material';

export const StyledBox = styled(Box)({
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  maxWidth: '90%',
  borderRadius: 8,
  color: '#212529',
  background: '#fff',
  boxSizing: 'border-box',
  p: 4,
});

export const FormLoginStyled = styled.form`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Div7 = styled.div`
  font-family: Rubik, sans-serif;
  margin-top: 32px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

export const Div9 = styled.div`
  font-family: Rubik, sans-serif;
  margin-top: 30px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

export const StyledContentButtons = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const StyledImageContainer = styled('div')({
  '> svg': {
    position: 'absolute',
    width: '92px',
    top: '10px',
    left: '10px',
  },

  '@media (max-width: 550px)': {
    display: 'none',
  },
});

export const StyledTitle = styled('p')({
  fontWeight: 'bold',
  fontSize: 28,
  fontFamily: 'Poppins,sans-serif',
  color: '#79CC72',
  textAlign: 'center',
});

export const StyledSubTitle = styled('p')({
  fontWeight: 'bold',
  fontSize: 16,
  fontFamily: 'Montserrat,sans-serif',
  textAlign: 'center',
  paddingBottom: 32,
});

export const StyledDescription = styled('p')({
  fontWeight: 400,
  fontSize: 14,
  fontFamily: 'Montserrat,sans-serif',
  textAlign: 'center',
});

export const StyledModalHeader = styled('div')({
  padding: 32,
  paddingBottom: 0,
  position: 'relative',
});

export const StyledContentModal = styled('div')({
  padding: 32,
});

export const FormLoginStyledContent = styled('div')({
  maxWidth: 350,
  width: '100%',
});

export const StyledContentFooter = styled('div')({
  maxWidth: 450,
  width: '100%',
});
